/*
 *
 * Activity constants
 *
 */

// ACTIONS_LIST CONSTANTS

export const CREATE_ACTIVITY = 'app/activity/CREATE_ACTIVITY';
export const CLEAR_DATA_ON_UNMOUNT = 'app/activity/CLEAR_DATA_ON_UNMOUNT';
export const GET_ACTIVITY = 'app/activity/GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'app/activity/GET_ACTIVITY_SUCCESS';
export const HIGHLIGHT_ACTIVITY = 'app/activity/HIGHLIGHT_ACTIVITY';


export const ADD_BUNDLE_LINE = 'app/activity/ADD_BUNDLE_LINE';
export const UPDATE_BUNDLE_LINE_FIELD = 'app/activity/UPDATE_BUNDLE_LINE_FIELD';
export const UPDATE_BUNDLE = 'app/activity/UPDATE_BUNDLE';
export const DELETE_BUNDLE_LINE = 'app/activity/DELETE_BUNDLE_LINE';

export const UPDATE_GLOBAL_CONDITIONS = 'app/activity/UPDATE_GLOBAL_CONDITIONS';

export const CHECK_ACTIVITY_NAME_AVAILABILITY = 'app/activity/CHECK_ACTIVITY_NAME_AVAILABILITY';
export const CHECK_ACTIVITY_NAME_AVAILABILITY_SUCCESS = 'app/activity/CHECK_ACTIVITY_NAME_AVAILABILITY_SUCCESS';
export const CHECK_ACTIVITY_NAME_AVAILABILITY_DONE = 'app/activity/CHECK_ACTIVITY_NAME_AVAILABILITY_DONE';
export const UPDATE_METADATA = 'app/activity/UPDATE_METADATA';
export const FORCE_UPDATE_NAME = 'app/activity/FORCE_UPDATE_NAME';
export const FORCE_UPDATE_NAME_AFTER_VALIDATION = 'app/activity/FORCE_UPDATE_NAME_AFTER_VALIDATION';
export const UPDATE_TRIGGER = 'app/activity/UPDATE_TRIGGER';
export const UPDATE_TRIGGER_PRE_VALIDATION = 'app/activity/UPDATE_TRIGGER_PRE_VALIDATION';
export const CONFIRM_UPDATE_TRIGGER = 'app/activity/CONFIRM_UPDATE_TRIGGER';
export const CLEAR_TEMP_TRIGGER = 'app/activity/CLEAR_TEMP_TRIGGER';
export const ADD_CONDITION = 'app/activity/ADD_CONDITION';
export const UPDATE_CONDITION_FIELD = 'app/activity/UPDATE_CONDITION_FIELD';
export const RESET_CONDITION = 'app/activity/RESET_CONDITION';
export const DELETE_CONDITION = 'app/activity/DELETE_CONDITION';
export const UPDATE_ACTION_FIELD = 'app/activity/UPDATE_ACTION_FIELD';
export const UPDATE_ACTION_TYPE = 'app/activity/UPDATE_ACTION_TYPE';
export const UPDATE_GIFT_SIMPLE_MODE = 'app/activity/UPDATE_GIFT_SIMPLE_MODE';
export const DELETE_ACTION = 'app/activity/DELETE_ACTION';
export const CHANGE_ACTION_MODE = 'app/activity/RESET_ACTIONS_WRAPPER';
export const RESET_ACTIVITY_DATA = 'app/loyalty/RESET_ACTIVITY_DATA';
export const SET_FILTERED_POPULATION_DATA = 'app/loyalty/SET_FILTERED_POPULATION_DATA';
export const SET_FILTERED_POPULATION_ERROR = 'app/loyalty/SET_FILTERED_POPULATION_ERROR';
export const DELETE_ACTIONS_WRAPPER = 'app/loyalty/DELETE_ACTIONS_WRAPPER';
export const UPDATE_FILTERED_POPULATION = 'app/loyalty/UPDATE_FILTERED_POPULATION';
export const SET_COMILLIA_HINT_OPEN_IN_ACTION = 'app/loyalty/SET_COMILLIA_HINT_OPEN_IN_ACTION';
export const GET_LOOKER_QUERY = 'app/loyalty/GET_LOOKER_QUERY';
export const GET_LOOKER_QUERY_SUCCESS = 'app/loyalty/GET_LOOKER_QUERY_SUCCESS';
export const GET_LOOKER_QUERY_DONE = 'app/loyalty/GET_LOOKER_QUERY_DONE';

export const SET_ACTIVITY_ERRORS = 'app/activity/SET_ACTIVITY_ERRORS';
export const ADD_TEMP_FILTERS_ERRORS = 'app/activity/ADD_TEMP_FILTERS_ERRORS';

export const SPLIT_TO_CASES = 'app/activity/SPLIT_TO_CASES';
export const CANCEL_SPLIT = 'app/activity/CANCEL_SPLIT';
export const UPDATE_CASE_DESCRIPTION = 'app/activity/UPDATE_CASE_DESCRIPTION';
export const SET_CASES_EXPAND_COLLAPSE_MODE = 'app/activity/SET_CASES_EXPAND_COLLAPSE_MODE';
export const ADD_CASE = 'app/activity/ADD_CASE';
export const DELETE_CASE = 'app/activity/DELETE_CASE';
export const CLEAR_CASE_DATA = 'app/activity/CLEAR_CASE_DATA';
export const UPDATE_CASE_ORDER = 'app/activity/UPDATE_CASE_ORDER';

export const SAVE_ACTIVITY = 'app/activity/SAVE_ACTIVITY';
export const SAVE_ACTIVITY_SUCCESS = 'app/activity/SAVE_ACTIVITY_SUCCESS';
export const SET_PUBLISH_OR_SAVE_IN_PROGRESS = 'app/activity/SET_PUBLISH_OR_SAVE_IN_PROGRESS';
export const SET_FILTERS_IN_PROGRESS = 'app/activity/SET_FILTERS_IN_PROGRESS';
export const RESET_DRAFT_ONE_TIME_ACTION_WITH_FILTERED_POPULATION_FLAG = 'app/activity/RESET_DRAFT_ONE_TIME_ACTION_WITH_FILTERED_POPULATION_FLAG';
export const ADD_ACTIVITY_TAGS = 'app/activity/ADD_ACTIVITY_TAGS';

export const UPDATE_DATE_RANGE_DATE = 'globalConditions/dateTime/UPDATE_DATE_RANGE_DATE';
export const UPDATE_DATE_RANGE_TIME = 'globalConditions/dateTime/UPDATE_DATE_RANGE_TIME';
export const UPDATE_DAYS_TIMES_CONDITION = 'globalConditions/daysTimes/UPDATE_DAYS_TIMES_CONDITION';
export const ADD_DAYS_TIMES_CONDITION = 'globalConditions/daysTimes/ADD_DAYS_TIMES_CONDITION';
export const DELETE_DAYS_TIMES_CONDITION = 'globalConditions/daysTimes/DELETE_DAYS_TIMES_CONDITION';
export const RESET_GLOBAL_DATE_TIME_CONDITIONS = 'globalConditions/RESET_GLOBAL_DATE_TIME_CONDITIONS';
export const SAVE_GLOBAL_DATE_TIME_CHANGES = 'globalConditions/SAVE_GLOBAL_DATE_TIME_CHANGES';
export const CREATE_TEMP_GLOBAL_DATE_TIME_CONDITIONS = 'globalConditions/CREATE_TEMP_GLOBAL_DATE_TIME_CONDITIONS';
export const UPDATE_GLOBAL_DATETIME_CONDITION_TYPE = 'globalConditions/UPDATE_GLOBAL_DATETIME_CONDITION_TYPE';
export const UPDATE_FILTER_USER_ACTION_TYPE = 'globalConditions/UPDATE_FILTER_USER_ACTION_TYPE';
export const ADD_USER_ACTION_CRITERIA_TO_FILTER = 'globalConditions/ADD_USER_ACTION_CRITERIA_TO_FILTER';
export const UPDATE_USER_ACTION_AGGREGATION_CRITERIA = 'globalConditions/UPDATE_USER_ACTION_AGGREGATION_CRITERIA';
export const UPDATE_SCHEDULING_TYPE = 'globalConditions/UPDATE_SCHEDULING_TYPE';
export const UPDATE_SCHEDULING_DATE = 'globalConditions/UPDATE_SCHEDULING_DATE';
export const UPDATE_SCHEDULING_TIME = 'globalConditions/UPDATE_SCHEDULING_TIME';
export const SAVE_SCHEDULING_CHANGES = 'globalConditions/SAVE_SCHEDULING_CHANGES';
export const CREATE_TEMP_SCHEDULING_DATA = 'globalConditions/CREATE_TEMP_SCHEDULING_DATA';
export const RESET_SCHEDULING_DATA = 'globalConditions/RESET_SCHEDULING_DATA';
export const CLEAR_TEMP_GLOBAL_DATETIME = 'globalConditions/CLEAR_TEMP_GLOBAL_DATETIME';
export const OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL = 'globalConditions/OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL';
export const OPEN_FILTER_MODAL_AND_APPLY_FILTER = 'globalConditions/OPEN_FILTER_MODAL_AND_APPLY_FILTER';
export const OPEN_FILTER_MODAL_AND_APPLY_COMMUNICATION_ACTION_FILTER = 'globalConditions/OPEN_FILTER_MODAL_AND_APPLY_COMMUNICATION_ACTION_FILTER';
export const EXPORT_FILTERED_POPULATION = 'globalConditions/EXPORT_FILTERED_POPULATION';
export const CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL = 'globalConditions/CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL';
export const FILTER_MEMBERS = 'globalConditions/FILTER_MEMBERS';
export const FILTER_MEMBERS_SUCCESS = 'globalConditions/FILTER_MEMBERS_SUCCESS';
export const FILTER_MEMBER_FAIL = 'globalConditions/FILTER_MEMBER_FAIL';
export const UPDATE_GLOBAL_MEMBERSHIP_CONDITION_TYPE = 'globalConditions/UPDATE_GLOBAL_MEMBERSHIP_CONDITION_TYPE';
export const UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD = 'globalConditions/UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD';
export const UPDATE_GLOBAL_MEMBERSHIP_STATUS_CONDITION = 'globalConditions/UPDATE_GLOBAL_MEMBERSHIP_STATUS_CONDITION';
export const UPDATE_GLOBAL_IMPORT_MEMBERSHIP_CONDITION = 'globalConditions/UPDATE_GLOBAL_IMPORT_MEMBERSHIP_CONDITION';
export const CLEAR_GLOBAL_IMPORT_MEMBERSHIP_COPY_PASTE_CONDITION = 'globalConditions/CLEAR_GLOBAL_IMPORT_MEMBERSHIP_COPY_PASTE_CONDITION';
export const GOING_TO_PASTE_IMPORT_MEMBERS_DATA = 'globalConditions/GOING_TO_PASTE_IMPORT_MEMBERS_DATA';
export const ADD_GLOBAL_MEMBERSHIP_CONDITION = 'globalConditions/ADD_GLOBAL_MEMBERSHIP_CONDITION';
export const UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ATTRIBUTES_OPERATOR = 'globalConditions/UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ATTRIBUTES_OPERATOR';
export const UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ACTIONS_OPERATOR = 'globalConditions/UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ACTIONS_OPERATOR';
export const DELETE_GLOBAL_MEMBERSHIP_CONDITION = 'globalConditions/DELETE_GLOBAL_MEMBERSHIP_CONDITION';
export const COPY_MEMBERSHIP_STATUS_CONDITION_TO_TEMP_DATA = 'globalConditions/COPY_MEMBERSHIP_STATUS_CONDITION_TO_TEMP_DATA';
export const DELETE_TEMP_MEMBERSHIP_STATUS_CONDITION = 'globalConditions/DELETE_TEMP_MEMBERSHIP_STATUS_CONDITION';
export const CLEAR_FILTERED_POPULATION = 'globalConditions/CLEAR_FILTERED_POPULATION';
export const CREATE_REFERRAL_CODE_TEMP_MODEL = 'globalConditions/CREATE_REFERRAL_CODE_TEMP_MODEL';
export const DELETE_REFERRAL_CODE_TEMP_MODEL = 'globalConditions/DELETE_REFERRAL_CODE_TEMP_MODEL';
export const SAVE_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS = 'globalConditions/SAVE_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS';
export const DELETE_USER_ACTION_FROM_FILTER = 'globalConditions/DELETE_USER_ACTION_FROM_FILTER';

export const SAVE_MEMBERSHIP_STATUS_CONDITION = 'globalConditions/SAVE_MEMBERSHIP_STATUS_CONDITION';
export const APPLY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_FROM_TEMP_MODEL = 'app/activity/APPLY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_FROM_TEMP_MODEL';
export const COPY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TO_TEMP_MODEL = 'globalConditions/COPY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TO_TEMP_MODEL';
export const REMOVE_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TEMP_MODEL = 'globalConditions/REMOVE_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TEMP_MODEL';

export const CREATE_TEMP_APPLY_ON_MODEL = 'actions/CREATE_TEMP_APPLY_ON_MODEL';
export const SAVE_APPLY_ON_DATA = 'actions/SAVE_APPLY_ON_DATA';
export const RESET_APPLY_ON_DATA = 'actions/RESET_APPLY_ON_DATA';
export const UPDATE_TEMP_APPLY_ON_MODEL_FIELD = 'actions/UPDATE_TEMP_APPLY_ON_MODEL_FIELD';
export const CHANGE_BUNDLE_ITEMS_MODE_IN_TEMP_MODEL = 'actions/CHANGE_BUNDLE_ITEMS_MODE_IN_TEMP_MODEL';

export const UPDATE_DISPLAY_FIELD = 'app/activity/UPDATE_DISPLAY_FIELD';
export const SELECT_ACTIVITY_TAB = 'app/activity/SELECT_ACTIVITY_TAB';
export const CREATE_GIFT_FROM_ACTIVITY = 'app/activity/CREATE_GIFT_FROM_ACTIVITY';
export const CANCEL_CREATE_GIFT_FROM_ACTIVITY = 'app/activity/CANCEL_CREATE_GIFT_FROM_ACTIVITY';
export const CREATE_EMAIL_FROM_ACTIVITY = 'app/activity/CREATE_EMAIL_FROM_ACTIVITY';
export const CANCEL_CREATE_EMAIL_FROM_ACTIVITY = 'app/activity/CANCEL_CREATE_EMAIL_FROM_ACTIVITY';
export const GO_BACK_TO_ACTIVITY_FROM_CREATE_GIFT = 'app/activity/GO_BACK_TO_ACTIVITY_FROM_CREATE_GIFT';
export const GO_BACK_TO_ACTIVITY_FROM_CREATE_EMAIL = 'app/activity/GO_BACK_TO_ACTIVITY_FROM_CREATE_EMAIL';
export const CLEAR_NEWLY_SELECTED_GIFT_ID = 'app/activity/CLEAR_NEWLY_SELECTED_GIFT_ID';
export const MARK_ACTIVITY_DIRTY = 'app/activity/MARK_ACTIVITY_DIRTY';
export const MARK_ACTIVITY_CLEAN = 'app/activity/MARK_ACTIVITY_CLEAN';
export const UPDATE_REQUIRED_PUNCHES = 'app/activity/UPDATE_REQUIRED_PUNCHES';
export const HANDLE_SEND_ON_EXPIRATION_CHECKBOX_CHANGE = 'app/activity/HANDLE_SEND_ON_EXPIRATION_CHECKBOX_CHANGE';
export const UPDATE_PUNCH_CARD_SHOPPING_CART_ITEMS = 'app/activity/UPDATE_PUNCH_CARD_SHOPPING_CART_ITEMS';
export const UPDATE_PUNCH_CARD_PUNCH_RULE_LIMIT = 'app/activity/UPDATE_PUNCH_CARD_PUNCH_RULE_LIMIT';
export const ADD_COMMUNICATION_RULE = 'app/activity/ADD_COMMUNICATION_RULE';
export const DELETE_COMMUNICATION_RULE = 'app/activity/DELETE_COMMUNICATION_RULE';
export const UPDATE_COMMUNICATION_RULE_TRIGGER = 'app/activity/UPDATE_COMMUNICATION_RULE_TRIGGER';
export const UPDATE_COMMUNICATION_RULE_ACTION = 'app/activity/UPDATE_COMMUNICATION_RULE_ACTION';
export const UPDATE_COMMUNICATION_RULE_DISPLAY = 'app/activity/UPDATE_COMMUNICATION_RULE_DISPLAY';
export const SET_PUNCH_CARD_DEFAULT_DISCOUNT_ON_ACTION_TYPE = 'app/activity/SET_PUNCH_CARD_DEFAULT_DISCOUNT_ON_ACTION_TYPE';
export const HANDLE_AUTO_RENEW_CHECKBOX_CHANGE = 'app/activity/HANDLE_AUTO_RENEW_CHECKBOX_CHANGE';
export const HANDLE_RENEW_OPTION_CHANGE = 'app/activity/HANDLE_RENEW_OPTION_CHANGE';
export const HANDLE_PUNCH_CARD_REWARD_MODE_CHANGE = 'app/activity/HANDLE_PUNCH_CARD_REWARD_MODE_CHANGE';
export const EXPAND_COLLAPSE_ALL_COMMUNICATION_RULES = 'app/activity/EXPAND_COLLAPSE_ALL_COMMUNICATION_RULES';

export const GET_MIGRATED_ACTIVITY_DATA_SUCCESS = 'app/activity/GET_MIGRATED_ACTIVITY_DATA_SUCCESS';
export const GET_MIGRATED_ACTIVITY_DATA = 'app/activity/GET_MIGRATED_ACTIVITY_DATA';

export const SET_BIRTHDAY_OR_ANNIVERSARY_EVENT_SCOPE = 'app/activity/SET_BIRTHDAY_OR_ANNIVERSARY_EVENT_SCOPE';
export const SET_CLUB_MEMBER_ANNIVERSARY_EVENT_SCOPE = 'app/activity/SET_CLUB_MEMBER_ANNIVERSARY_EVENT_SCOPE';
export const UPDATE_TRIGGER_SCHEDULE_VALUE = 'app/activity/UPDATE_TRIGGER_SCHEDULE_VALUE';
export const UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT = 'app/activity/UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT';
export const UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_VALUE = 'app/activity/UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_VALUE';
export const UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_UNIT = 'app/activity/UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_UNIT';
export const IMPORT_MEMBERS_FILE_UPLOAD = 'app/activity/IMPORT_MEMBERS_FILE_UPLOAD';
export const IMPORT_MEMBERS_FILE_UPLOAD_SUCCESS = 'app/activity/IMPORT_MEMBERS_FILE_UPLOAD_SUCCESS';
export const IMPORT_MEMBERS_FILE_UPLOAD_ERROR = 'app/activity/IMPORT_MEMBERS_FILE_UPLOAD_ERROR';
export const SET_EXPORT_IN_PROGRESS = 'app/activity/SET_EXPORT_IN_PROGRESS';
export const REMOVE_BACK_TO_ACTIVITY_FLAG = 'app/activity/REMOVE_BACK_TO_ACTIVITY_FLAG';
export const IMPORT_CODES_BULK_UPLOAD = 'app/activity/IMPORT_CODES_BULK_UPLOAD';
export const IMPORT_CODES_BULK_UPLOAD_SUCCESS = 'app/activity/IMPORT_CODES_BULK_UPLOAD_SUCCESS';
export const IMPORT_CODES_BULK_UPLOAD_ERROR = 'app/activity/IMPORT_CODES_BULK_UPLOAD_ERROR';
export const UPDATE_PROMO_CODES_MODE = 'app/activity/UPDATE_PROMO_CODES_MODE';
export const SET_INITIAL_SINGLE_PROMO_CODE = 'app/activity/SET_INITIAL_SINGLE_PROMO_CODE';

export const START_AB_TESTING = 'app/activity/START_AB_TESTING';
export const CANCEL_AB_TESTING = 'app/activity/CANCEL_AB_TESTING';
export const AB_TESTING_CASE_A = 'case_a';
export const AB_TESTING_CASE_B = 'case_b';
export const UPDATE_AB_TESTING_POPULATION_SIZE = 'app/activity/UPDATE_AB_TESTING_POPULATION_SIZE';

export const UPDATE_MEMBERSHIP_FIELD_VALUE_UPDATE = 'app/activity/UPDATE_MEMBERSHIP_FIELD_VALUE_UPDATE';
export const UPDATE_MEMBERSHIP_FIELD_LIST_UPDATE = 'app/activity/UPDATE_MEMBERSHIP_FIELD_LIST_UPDATE';
export const ADD_MEMBERSHIP_FIELD = 'app/activity/ADD_MEMBERSHIP_FIELD';
export const REMOVE_MEMBERSHIP_FIELD = 'app/activity/REMOVE_MEMBERSHIP_FIELD';
export const INITIALIZE_UPDATE_MEMBERSHIP_FIELDS = 'app/activity/INITIALIZE_UPDATE_MEMBERSHIP_FIELDS';

// STORE CONSTANTS
export const ACTIVITY = 'activity';
export const GOAL_ID = 'goalId';
export const DB_ID = 'dbId';
export const DEAL_SEGMENT_NAME = 'Deal';
export const RULE_SEGMENT_NAME = 'Rule';
export const ONE_TIME_SEGMENT_NAME = 'One time';
export const MICRO_CAMPAIGN_SEGMENT_NAME = 'AI Campaign';
export const RULE_PREFIX = 'rule';
export const DEAL_PREFIX = 'deal';
export const PUNCH_CARD_PREFIX = 'punchCard';
export const ONE_TIME_PREFIX = 'oneTime';
export const GIFT_PREFIX = 'gift';
export const PROMO_CODE_PREFIX = 'promoCode';
export const EMAIL_PREFIX = 'email';
export const PATH_RULES = 'rules';
export const PATH_BUSINESS_CENTER = 'businessCenter';
export const PATH_DEALS = 'deals';
export const PATH_ONE_TIME = 'onetime';
export const PATH_PUNCH_CARDS = 'punchCards';
export const PATH_MICRO_CAMPAIGNS = 'microCampaigns';
export const PATH_GIFTS = 'gifts';
export const PATH_EMAILS = 'emails';
export const PATH_PROMO_CODES = 'promoCodes';
export const PATH_POINTSHOP = 'pointShop';
export const API_PATH_RULES = 'Rules';
export const API_PATH_DEALS = 'Deals';
export const API_PATH_PROMO_CODES = 'PromoCodes';
export const API_PATH_ONE_TIME = 'OneTime';
export const API_PATH_PUNCH_CARDS = 'PunchCards';
export const API_PATH_GIFTS = 'Gifts';
export const DATA = 'data';
export const AB_TEST_MODE = 'abTestMode';
export const ORIGINAL_DATA = 'originalData';
export const NAME = 'name';
export const PRICE = 'price';
export const VALUES = 'values';
export const DESCRIPTION = 'description';
export const TRIGGER = 'trigger';
export const FILTER_MEMBERS_TRIGGER = 'filterMembers';
export const PENDING_TRIGGER = 'pendingTrigger';
export const DELAY = 'delay';
export const CONDITION = 'condition';
export const CONDITIONS = 'conditions';
export const CONDITIONS_LIST = 'conditionsList';
export const CONDITION_KEY = 'conditionKey';
export const CONDITION_ID = 'conditionId';
export const OPERATOR_KEY = 'operatorKey';
export const CREATE_DATE = 'createDate';
export const LAST_UPDATE = 'lastUpdate';
export const PUBLISH_DATE = 'publishDate';
export const CONDITION_VALUE = 'conditionValue';
export const CONDITION_VALUE_DATA_TYPE = 'dataValueType';
export const ITEMS_POPULATION = 'itemsPopulation';
export const EXCLUDED_MEANS_OF_PAYMENT = 'excludedMeansOfPayment';
export const EXCLUDED_MEANS_OF_PAYMENT_STATUS = 'excludedMeansOfPaymentStatus';
export const DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT_STATUS = 'deductPercentFromMeansOfPaymentStatus';
export const DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT = 'deductPercentFromMeansOfPayment';
export const EXCLUDE_ITEMS_POPULATION = 'excludeItemsPopulation';
export const EXCLUDE_ITEMS_POPULATION_STATUS = 'excludeItemsPopulationStatus';
export const CONDITION_LIST_OPERATOR = 'conditionsOperator';
export const CONDITION_AND = 'and';
export const CONDITION_OR = 'or';
export const CONDITION_OR_TEXT = 'any';
export const CONDITION_AND_TEXT = 'all';
export const CONDITION_RELATIONS = 'conditionRelations';
export const ACTIONS = 'actions';
export const ACTION = 'action';
export const TAG = 'tag';
export const FILTER_BY_ACTIVITY = 'filterByActivity';
export const TEMP_ACTION_DATA = 'tempActionList';
export const DELETE_MEMBERSHIP_ACTION = 'deleteMembership';
export const ACTIONS_LIST = 'actionsList';
export const ACTIONS_MODE = 'mode';
export const CAMPAIGN_KEY = 'campaignKey';
export const ACTIVITY_FROM_FILTER_PREFIX = 'OneTime-';
export const DEFAULT_CAMPAIGN_NAME_FROM_FILTER = 'business-center.campaign.toolbar.create.campaign.filter.source';
export const TEMPLATE_KEY = 'templateKey';
export const GOAL_KEY = 'goalKey';
export const TEMPLATE = 'template';
export const CASES = 'cases';
export const FILTERS_USER_ACTION = 'filtersUserAction';
export const IS_COLLAPSED = 'isCollapsed';
export const ACTIVITY_ACTIONS = 'actions';
export const ONE_TIME_ACTION_SCHEDULING = 'scheduling';
export const DRAFT_OLD_FILTERED_POPULATION_FLAG = 'draftOldFilteredPopulationFlag';
export const ONE_TIME_ACTION_FILTERED_POPULATION = 'filteredPopulation';
export const AB_TESTING_FILTERED_POPULATION = 'abTestingFilteredPopulation';
export const EXPORT_IN_PROGRESS = 'exportInProgress';
export const FILTERED_NUM_OF_MEMBERS = 'count';
export const FILTER_POPULATION_NAME = 'filter';
export const FILTERED_POPULATION_FILE_NAME = 'fileName';
export const FILTERED_POPULATION_BUCKET_NAME = 'bucketName';
export const FILTERED_POPULATION_SOURCE = 'source';
export const FILTER_POPULATION_QUERY = 'query';
export const ACTIVITY_SOURCE = 'source';
export const CAMPAIGN_CENTER_SOURCE = 'campaignCenter';
export const FILTERED_POPULATION_CONDITIONS = 'filterConditions';
export const FILTERED_POPULATION_USER_ACTIONS_CONDITIONS = 'userActionsFilters';
export const USER_ACTIONS_CONDITIONS = 'userActionsConditions';
export const USER_ACTIONS_CONDITIONS_LIST = 'userActionsConditionsList';
export const USER_ACTION_KEY = 'userActionKey';
export const USER_ACTION_DATE_RANGE_CONDITION = 'dateRangeCondition';
export const USER_ACTION_AGGREGATION_CONDITIONS = 'aggregationConditions';
export const IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE = 'isActive';
export const FILTERED_POPULATION_COUNT = 'count';
export const MINIMUM_FILTERED_POPULATION_COUNT_FOR__TESTING = 500;
export const ACTIVITY_DATA_LOADED = 'activityDataLoaded';
export const FILTER_ACTIVITY_NAME = 'name';
export const POPULATION_FILTER_DELIMITER = '||__$__||';
export const ACTION_IN_PROGRESS_NAME = 'actionInProgressName';
export const ACTION_IN_PROGRESS_VALUE = 'actionInProgressValue';
export const PUBLISH_OR_SAVE_IN_PROGRESS = 'publishOrSaveInProgress';
export const SAVE_AS_DRAFT_IN_PROGRESS = 'saveAsDraftInProgress';
export const FILTERS_IN_PROGRESS = 'filtersInProgress';
export const CONDITION_VALUE_WIDTH = 'conditionValueWidth';
export const CONDITION_KEY_WIDTH = 'conditionKeyWidth';
export const CONDITION_OPERATOR_WIDTH = 'conditionOperatorWidth';
export const CONDITION_ITEMS_POPULATION_WITH = 'conditionItemsPopulationWidth';
export const DEPRECATED = 'deprecated';
export const ACTIVITY_TAGS = 'activityTags';

export const BIRTHDAY_OR_ANNIVERSARY_EVENT_TYPE_CONDITION = 'birthdayOrAnniversaryEventTypeCondition';

export const INSTANCE_EXPIRATION_GLOBAL_CONDITION = 'instanceExpirationGlobalCondition';
export const INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL = 'instanceExpirationGlobalConditionTemp';
export const ACTIVATION_CONDITION = 'activationCondition';
export const DEACTIVATION_CONDITION = 'deactivationCondition';
export const ACTIVATION_MODE_DEFAULT = 'default';
export const ACTIVATION_MODE_ON_SPECIFIC_TIME = 'specific';
export const ACTIVATION_SPECIFIC_TIME_UNIT = 'unit';
export const ACTIVATION_SPECIFIC_TIME_VALUE = 'value';
export const ACTIVATION_MODE = 'mode';
export const FILE = 'file';
export const PREVIEW_IMAGE_ID = 'imageId';
export const STAMP_IMAGE_ID = 'stampImageId';
export const BACKGROUND_IMAGE_ID = 'backgroundImageId';
export const ACTIVE_TAB = 'activeTab';
export const IMAGE_TYPE_PREVIEW = 'preview';
export const IMAGE_TYPE_STAMP = 'stamp';
export const IMAGE_TYPE_BACKGROUND = 'background';

export const PRISTINE_FLAG = 'pristine';

export const SHOPPING_CART = 'shoppingCart';
export const BUNDLE = 'bundle';
export const BUNDLE_ITEMS_MODE = 'bundleItemsMode';
export const BUNDLE_ITEMS_MODE_ENTIRE = 'entireBundle';
export const BUNDLE_ITEMS_MODE_SPECIFIC = 'specificItems';
export const ACTION_TYPE = 'actionType';
export const ACTION_TAGS = 'actionTags';
export const INDEX = 'index';
export const ADD_POINTS_TYPE = 'addPointsType';
export const VALIDATION_METADATA = 'validationMetadata';
export const REGISTRATION_FORM_FIELDS = 'registrationFormFields';
export const VALIDATION_ERRORS = 'validationErrors';
export const ACTIVITY_VALIDATION_MODE = 'validationMode';
export const ACTIVITY_VALIDATION_MODE_DRAFT = 'draft';
export const ACTIVITY_VALIDATION_MODE_PUBLISH = 'publish';
export const ACTIVITY_VALIDATION_MODE_DONT_VALIDATE = 'dontValidate';

export const TEMP_VALIDATION_ERRORS = 'tempValidationErrors';
export const TEMP_FILTERS_ERRORS = 'tempFiltersErrors';
export const NO_MEMBERS_FILTERED_ERROR = 'noMembersFilteredError';
export const FILTER_EMPTY_STATE = 'filterEmptyState';
export const IMPORT_MEMBERS_LIMIT_EXCEEDED_ERROR = 'importMembersLimitExceededError';
export const VALIDATION_NAME_TAKEN_FLAG = 'validationNameTakenFlag';
export const VALIDATION_PROMO_CODE_TAKEN_FLAG = 'promoCodeTakenFlag';
export const FREE_PROMO_CODES_NUMBER = 'freePromoCodesNumber';
export const VALIDATION_PROMO_CODE_ERROR = 'promoCodeServerError';
export const VALIDATION_ERRORS_NO_ACTION = 'noAction';
export const ERRORS_FROM_SERVER = 'ErrorsFromServer';
export const ACTIVITY_HEADER = 'activityHeader';

export const ACTIONS_SIMPLE_MODE = 'simple';
export const ACTIONS_OCCURRENCES_MODE = 'occurrence';
export const BUNDLE_ADVANCED_MODE = 'advanced';
export const QUANTITY = 'quantity';
export const SPEND = 'spend';
export const CONDITION_SELECTED = 'checked';
export const SIMPLE_ENTIRE_TICKET_DISCOUNT = 'simpleEntireTicketDiscount';
export const SIMPLE_SPECIFIC_ITEMS_DISCOUNT = 'simpleSpecificItemsDiscount';
export const ADVANCED_DISCOUNT = 'advancedDiscount';

export const EQUALS_OPERATOR = '==';
export const VALUE = 'value';
export const UNIT = 'unit';
export const DELAY_UNITS = 'units';
export const DELAY_NUMBER = 'number';
export const APPLY_ON = 'applyOn';
export const DISCOUNT_VALUE = 'discountValue';
export const DISCOUNT_ON = 'discountOn';
export const SIMPLE_DESCRIPTION = 'simpleDescription';
export const APPLY_ON_LINE = 'applyOnLine';

export const CONDITION_OPERATOR_MEMBER_FIELD_CHANGE_FROM_TO = 'memberFieldChangeFromTo';
export const IS_ONE_OF_CONDITION_OPERATOR = 'containsAtLeastOneValue';

export const REFERRAL_CODE_TYPE_SPECIFIC = 'specific';
export const REFERRAL_CODE_TYPE_NO = 'no';
export const REFERRAL_CODE_TYPE_ANY = 'any';

export const TEMP_ORIGIN_ACTIVITY = 'tempOriginActivity';
export const UPDATE_PATH = 'updatePath';
export const RETURN_URL = 'returnUrl';
export const RETURN_URL_PATHNAME = 'pathname';
export const RETURN_URL_SEARCH = 'search';
export const NEWLY_CREATED_BENEFIT = 'newlyCreatedBenefit';
export const NEWLY_CREATED_EMAIL = 'newlyCreatedEmail';
export const BACK_TO_ACTIVITY_IN_STATE = 'backToActivityInState';

// Landing Page

export const LANDING_PAGE_ACTIVATION_STATUS = 'landingPageActive';
export const LANDING_PAGE_CHILD_ACTIVITIES = 'landingPageChildActivities';
export const LANDING_PAGE_DATA = 'landingPageData';
export const LANDING_PAGE_DATA_HASH_ID = 'landingPageHashedId';
export const LANDING_PAGE_EXTERNAL_EVENT_CONDITION_TYPE = 'giftLandingPage';
export const LANDING_PAGE_SMART_VALUES_REPLACED_ON_SERVER = 'REPLACED_ON_SERVER';
export const LANDING_PAGE_SUBMIT_EVENT_RULE = 'landingPageSubmitEventRule';
export const LANDING_PAGE_ENTER_PHONE_TEXT = 'landingPageEnterPhoneText';
export const LANDING_PAGE_LEGAL_TEXT = 'landingPageLegalText';
export const LANDING_PAGE_SHOW_SOCIAL_SECTION = 'landingPageShowSocialSection';
export const LANDING_PAGE_SHOW_LEGAL_SECTION = 'landingPageShowLegalSection';
export const LANDING_PAGE_SUCCESS_HEADER_TEXT = 'landingPageSuccessHeaderText';
export const LANDING_PAGE_SUCCESS_MESSAGE_TEXT = 'landingPageSuccessMessageText';
export const LANDING_PAGE_EXPIRED_HEADER_TEXT = 'landingPageExpiredHeaderText';
export const LANDING_PAGE_EXPIRED_MESSAGE_TEXT = 'landingPageExpiredMessageText';
export const LANDING_PAGE_EXISTING_MEMBER_HEADER_TEXT = 'landingPageExistingMemberHeaderText';
export const LANDING_PAGE_EXISTING_MEMBER_MESSAGE_TEXT = 'landingPageExistingMemberMessageText';
export const LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX = 'landingPageOnlyNewMembers';
export const LANDING_PAGE_VIEW_DIRECTION = 'landingPageViewDirection';
export const LANDING_PAGE_MODAL_DATA = 'landingPageModalData';
export const LANDING_PAGE_MODAL_IS_OPEN = 'landingPageModalIsOpen';
export const LANDING_PAGE_FACEBOOK_PIXEL = 'landingPageFacebookPixel';
export const LANDING_PAGE_GOOGLE_PIXEL = 'landingPageGooglePixel';
export const LANDING_PAGE_REDIRECT_URL_SUCCESS = 'landingPageRedirectUrlSuccess';
export const LANDING_PAGE_REDIRECT_URL_FAILURE = 'landingPageRedirectUrlFailure';
export const LANDING_PAGE_SHORTENED_URL = 'landingPageShortenedUrl';

// flags
export const CHECKING_ACTIVITY_NAME_AVAILABILITY = 'checkingActivityNameAvailability';

export const PUNCH_CARD_KEY = 'punchCardKey';

// actions
export const SEND_CODES_TO_POS = 'sendCodesToPos';
export const POS_CODE_TYPE = 'posCodeType';
export const CODE = 'code';
export const CODES = 'codes';
export const DEAL_CODE = 'dealCode';
export const ITEM_CODE = 'itemCode';
export const NO_POS_GIFT = 'noPosGift';

// cases constants
export const CASES_CONDITION_GROUP_GENERAL = 'general';
export const CASES_CONDITION_GROUP_DATES_AND_TIMES = 'datesAndTimes';
export const CASES_CONDITION_GROUP_MEMBERSHIP = 'membership';

// items population datesConstants
export const IS_ONE_OF = 'containsAtLeastOneValue';
export const IS_NOT_ONE_OF = 'doesNotContainAnyOfTheValues';

export const LOCATION_ID = 'locationId';
export const HUB_ID = 'hubId';
export const SERVER_ID = 'serverId';
export const TYPE = 'type';
export const FILTER_ALL = 'all';
export const FILTER_TYPE_ALL = 'allTypes';
export const STATUS = 'status';
export const FILTER_SEARCH = 'search';
export const ACCESS_GROUP_IDS = 'accessGroupIds';

export const ACTIVITY_STATUS_ACTIVE = 'active';
export const ACTIVITY_STATUS_DELETED = 'deleted';
export const ACTIVITY_STATUS_FUTURE = 'future';
export const ACTIVITY_STATUS_COMPLETED = 'completed';
export const ACTIVITY_STATUS_STOPPED = 'stopped';
export const ACTIVITY_STATUS_INACTIVE = 'inactive';
export const ACTIVITY_STATUS_DRAFT = 'draft';
export const ACTIVITY_STATUS_PAUSED = 'paused';
export const ACTIVITY_STATUS_INVALID = 'invalid';
export const ACTIVITY_STATUS_AUTO_ARCHIVED = 'isAutoArchived';
export const ACTIVITY_STATUS_RESTART = 'restart';

export const ACTIVITY_CREATE_DATE = 'createDate';
export const ACTIVITY_HISTORY_LOG_CREATED = 'created';
export const ACTIVITY_HISTORY_LOG_DATE = 'date';

export const ALLOW_OCCURRENCES = 'allowOccurrences';
export const TEXT = 'text';
export const OPTION_KEY = 'key';
export const VALUE_TYPE = 'valueType';
export const HISTORY_LOG = 'historyLog';
export const PLACEHOLDER = 'placeholder';
export const AUTOMATION_NAME = 'activity';

// BENEFITS
export const BENEFIT_GIFT = 'gift';
export const BENEFIT_PUNCH_CARD = 'punchCard';

// Actions constants
export const SMS_MESSAGE = 'message';
export const PUSH_MESSAGE = 'message';
export const PUSH_TITLE = 'title';
export const POPUP_MESSAGE = 'message';
export const MESSAGE_SPECIAL_CHARS = 'messageSpecialChars';
export const POPUP_SUBJECT = 'subject';
export const NUMBER_OF_MESSAGES = 'numberOfMessages';
export const MESSAGE_CONTENT_LENGTH = 'messageContentLength';

export const CONSENT_TYPE = 'consentType';
export const UNSUBSCRIBE_TYPE = 'unsubscribeType';
export const SEND_MEMBER_SMS_ACTION = 'sendMemberSms';
export const SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION = 'sendTcConsentSmsOrEmail';
export const SEND_CONSENT_BY_SMS = 'sendConsentBySms';
export const SEND_CONSENT_BY_EMAIL = 'sendConsentByEmail';
export const UNSUBSCRIBE_SMS = 'unsubscribeSMS';
export const UNSUBSCRIBE_EMAIL = 'unsubscribeEmail';
export const EMAIL_MESSAGE = 'emailMessage';
export const EMAIL_SUBJECT = 'emailSubject';
export const CONSENT_LANDING_PAGE_LINK_MENTION = '@Consent.LandingPageLink';

export const POINTS = 'points';
export const POINTS_TYPE = 'pointsType';
export const TAGS_TYPE = 'tagsType';
export const DYNAMIC_TAGS_VALUE = 'dynamicTagsValue';
export const DYNAMIC_POINTS_VALUE = 'dynamicPointsValue';
export const BUDGET_TYPE = 'budgetType';
export const POINTS_PER_RATE = 'pointsPerRate';
export const ACCUMULATE_POINTS_TYPE_PERCENTAGE = 'percentage';
export const ACCUMULATE_POINTS_TYPE_RATE = 'rate';
export const ACCUMULATE_POINTS_TYPE_FIXED = 'fixed';
export const ACCUMULATE_POINTS_TYPE_DYNAMIC = 'dynamic';
export const TAGS_TYPE_FIXED = 'fixed';
export const TAGS_TYPE_DYNAMIC = 'dynamic';
export const ACCUMULATE_POINTS_TYPE_TOP_UP = 'topUp';
export const POINTS_TOP_UP_PERCENTS = 'topUpPercents';

export const ADD_POINTS_WALLET_POINTS = 'Points';
export const GIFT_SIMPLE_MODE = 'simpleMode';
export const GIFT_SIMPLE_MODE_FEATURE = 'Simple';
export const ADD_POINTS_WALLET_BUDGET = 'Budget';
export const ADD_POINTS_WALLET_BOTH = 'Both';

export const ADD_POINTS_TYPE_CHANGE = 'app/actions/addPoints/ADD_POINTS_TYPE_CHANGE';
export const SET_ACTIVITY_TYPE_DATA = 'app/actions/SET_ACTIVITY_TYPE_DATA';
export const SET_ACTIVITY_SOURCE = 'app/actions/SET_ACTIVITY_SOURCE';
// Features constants
export const FEATURES = 'features';
export const GET_FEATURES = 'app/activity/GET_FEATURES';
export const GET_FEATURES_SUCCESS = 'app/activity/GET_FEATURES_SUCCESS';
export const CHECK_PAGE_PERMISSIONS = 'app/activity/CHECK_PAGE_PERMISSIONS';
export const FEATURE_NAME = 'name';
export const FEATURE_LEVEL_PATH = 'level'; // TODO: Replace with FEATURE_LEVEL
export const FEATURE_IS_ADD_ON = 'isAddon';
export const RESTRICTING_POS_FEATURES = 'restrictingPosFeatures';
export const RESTRICTED_BY_POS_FEATURES = 'restrictedByPosFeatures';
export const RESTRICTING_ADD_ON_FEATURES = 'restrictingAddOnFeatures';
export const RESTRICTED_BY_ADD_ON_FEATURES = 'restrictedByAddOnFeature';
export const HIDE_BY_BUSINESS_PLAN = 'hideByBusinessPlan';

export const FEATURE_LEVEL = { // TODO: Replace with FEATURE_LEVELS
  NONE: 'None',
  READ: 'Read',
  CREATE: 'Create',
  FULL: 'Full'
};

export const MESSAGE_TRIGGER_BY = 'messageTriggetBy';
export const MESSAGE_TRIGGER_BY_OPENING_THE_APP = 'messageTriggerByOpeningTheApp';
export const MESSAGE_TRIGGER_BY_NAVIGATION_TO_HOME_SCREEN = 'messageTriggerByNavigationToHomeScreen';
export const MESSAGE_TRIGGER_BY_AFTER_REDEEM = 'messageTriggerByAfterRedeem';
export const MESSAGE_TRIGGER_BY_AFTER_PUNCH = 'messageTriggerByAfterPunch';
export const MESSAGE_TRIGGER_BY_AFTER_COUPON_CODE_ACTION = 'messageTriggerByAfterCouponCodeAction';
export const SAVE_MESSAGE_TO_INBOX = 'saveMessageToInbox';
export const SEND_PUSH_WITH_IMAGE = 'sendPushWithImage';
export const SEND_SMS_WITH_IMAGE = 'sendSmsWithImage';
export const MMS_IMAGE = 'mmsImageId';
export const MMS_IMAGE_URL = 'mmsImageUrl';
export const PUSH_NOTIFICATION_IMAGE = 'pushNotificationImageId';
export const PUSH_NOTIFICATION_IMAGE_URL = 'pushNotificationImageUrl';
export const MESSAGE_MAX_AGE_IN_DAYS = 'messageMaxAgeInDays';
export const GENERIC_ACTION_ARGS = 'genericActionArgs';
export const EXTRA_DATA = 'extraData';

// Schema constants
export const SCHEMA = 'schema';
export const TRIGGERS = 'triggers';
export const TRIGGER_GROUPS = 'triggers';
export const CONDITIONS_PER_TRIGGERS = 'conditionsPerTriggers';
export const USER_ACTION_FILTERS = 'userActionFilters';
export const CONDITIONS_PER_USER_ACTION = 'conditionsPerUserAction';
export const ACTIONS_PER_TRIGGERS = 'actionsPerTriggers';
export const TEMPLATE_ONE_TIME_ACTION_CATEGORY = 'oneTimeAction';
export const SCHEMA_CONDITION_OPERATORS = 'operators';
export const MENTIONS_PER_TRIGGERS = 'mentionsPerTriggers';
export const EMAIL_ACTION_MENTIONS = 'emailActionMentions';
export const UPDATE_MEMBERSHIP_CONDITIONS = 'updateMembershipConditions';
export const DATA_VALUE_TYPE_DEFAULTS = 'dataValueTypeDefaults';
export const CONDITION_USED_ONCE_PER_USER_ACTION = 'usedOnceInFilterUserAction';
export const PUNCH_COMMUNICATION_TRIGGERS = 'punchCommunicationTriggers';
export const PUNCH_COMMUNICATION_ACTIONS = 'punchCommunicationActions';

export const SCHEMA_CONDITION_KEY = 'key';
export const SCHEMA_CONDITION_VALUE_DATA_TYPE = 'dataValueType';
export const SCHEMA_ACTION_GROUP_KEY = 'key';
export const SCHEMA_CONDITION_GROUP = 'group';
export const BOOLEAN_VALUE_FALSE = 'false';
export const BOOLEAN_VALUE_TRUE = 'true';


export const FIELD_LEVEL_KEY = 'level';
export const ACTION_FIELDS = 'actionFields';
export const BASIC_ACTION = 'basic';
export const KEY = 'key';
export const TRANSLATION_ARGS = 'translationArgs';
export const HINT_OPTION = 'hint';
export const NONE = 'none';

export const BUNDLE_CONDITION_ID = 'id';
export const APPLY_ON_ID = 'id';
export const BUNDLE_APPLY_ON_LIMIT = 'limitTo';

export const UPLOADED_FILE_NAME = 'fileName';
export const UPLOADED_BUCKET_NAME = 'bucketName';
export const MEDIA_LINK = 'mediaLink';

// temp data constants
export const TEMP_DATETIME_GLOBAL_CONDITIONS = 'tempDateTimeGlobalConditions';
export const MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL = 'tempModelMembershipGlobalConditions';
export const TEMP_MEMBERSHIP_STATUS_CONDITION = 'tempMembershipStatusCondition';
export const TEMP_SCHEDULING_DATA = 'tempSchedulingDate';
export const TEMP_APPLY_ON_BUNDLE_MODEL = 'tempApplyOnModel';
export const TEMP_REFERRAL_CODE_MODEL = 'tempReferralCode';
export const IMPORT_MEMBERS_LIMIT_EXCEEDED = 'importMembersLimitExceeded';
export const PASTING_IMPORT_MEMBERS = 'pastingImportMembers';
export const UPLOAD_MEMBERS_FILE_IN_PROGRESS = 'uploadMembersFileInProgress';
export const IMPORT_MEMBERS_MODE = 'importMembersMode';
export const IMPORT_MEMBERS_COPY_PASTE_MODE = 'copyPasteMode';
export const IMPORT_MEMBERS_UPLOAD_FILE_MODE = 'uploadFileMode';
export const CONDITION_KEY_TO_CONDITION_TYPE = 'conditionKeyToConditionType';
export const CONDITION_TYPE_TO_COPY_PASTE_VALUE = 'conditionTypeToCopyPasteValue';
export const CONDITION_TYPE_TO_IMPORT_FILE_VALUE = 'conditionTypeToImportFileValue';

export const REFERRAL_CODE_CONDITION_TYPE = 'type';
export const CONDITION_VALUE_IS_REFERRED = 'valueExists';
export const CONDITION_VALUE_REFERRAL_CODE = 'listOfValues';
export const CONDITION_VALUE_REFERRAL_DATA_TYPE = 'type';

// Delay / Occurrences limits  per time period
export const DEFAULT_DELAY_NUMBER_FOR_PUNCH = 1;
export const ROLLING_PERIOD = 'rollingPeriod';

// Limit
export const LIMIT = 'limit';
export const LIMIT_ACTIVE = 'active';
export const LIMIT_VALUE = 'value';
export const LIMIT_NONE_REPETITIVE_ACTION = { [LIMIT_ACTIVE]: true, [LIMIT_VALUE]: 1 };
export const AMOUNT_LIMIT = 'amountLimit';
export const LIMIT_SHOW_TIMES = 'times';
export const LIMIT_SHOW_AMOUNT = 'amount';
export const DISCOUNT_AMOUNT = 'amount';
export const DISCOUNT_PERCENT = 'percent';

// Activity Type
export const SERVER_TYPE_RULE = 'HubRule';
export const SERVER_TYPE_DEAL = 'HubDeal';
export const SERVER_TYPE_ONE_TIME_ACTION = 'HubOneTimeAction';
export const SERVER_TYPE_GIFT = 'HubGift';
export const SERVER_TYPE_PUNCH_CARD = 'HubPunchCard';
export const SERVER_TYPE_PROMO_CODE = 'HubPromoCode';
export const SERVER_TYPE_POINTSHOP = 'HubPointShop';
export const SERVER_TYPE_EMAIL = 'HubEmail';
export const ACTIVITY_TYPE_RULE = 'rule';
export const ACTIVITY_TYPE_DEAL = 'deal';
export const ACTIVITY_TYPE_ONE_TIME = 'onetime';
export const ACTIVITY_TYPE_PUNCH_CARD = 'punchCard';
export const ACTIVITY_TYPE_GIFT = 'gift';
export const ACTIVITY_TYPE_POINT_SHOP = 'pointShop';
export const ACTIVITY_DESCRIPTION_LENGTH_LIMIT = 1400;
export const ACTIVITY_TYPE_PROMO_CODE = 'promoCode';
export const ACTIVITY_TYPE_EMAIL = 'email';
export const ACTIVITY_TYPE_MICRO_CAMPAIGN = 'microCampaign';
export const ACTIVITY_TYPE_POINT_SHOP_ITEM = 'pointShopItem';
export const POINT_SHOP_STATS_COUNT_APP_SOURCE = 'appSourceCount';
export const POINT_SHOP_STATS_COUNT_POS_SOURCE = 'posSourceCount';
export const POINT_SHOP_STATS_COUNT_TOTAL_SOURCE = 'totalCount';

export const ACTIVITY_TYPE_GIFT_CAPITAL = 'Gift';
export const ACTIVITY_TYPE_EMAIL_CAPITAL = 'Email';

export const ACTIVITY_NAME = 'activityName';

// Server side errors:
export const HUB_DUPLICATE_ERROR = 'HubDuplicateError';
export const HUB_VALIDATION_ERROR = 'HubValidationError';

// global conditions
export const MEMBERS_REGISTERED = 'registeredOnly';
export const MEMBERS_SPECIFIC = 'custom';
export const ALL_COSTUMERS = 'allCostumers';
export const MEMBERS_IMPORT = 'import';
export const MEMBERS_PHONENUMBER_IMPORT = 'importPhoneNumbers';
export const DATETIME_ANY = '';
export const DATETIME_SPECIFIC = 'custom';
export const MEMBERSHIP_GLOBAL_CONDITIONS = 'membershipGlobalConditions';
export const MEMBERSHIP_GLOBAL_CONDITIONS_MODAL_IS_OPEN = 'membershipGlobalConditionsModalIsOpen';
export const DATETIME_GLOBAL_CONDITIONS = 'dateTimeGlobalConditions';
export const LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS = 'limitOccurrencesPerMemberGlobalConditions';
export const LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL = 'tempLimitOccurrencesPerMemberGlobalConditions';
export const SCHEDULE_IMMEDIATE = 'now';
export const SCHEDULE_SCHEDULED = 'date';
export const SCHEDULE_DATE = 'date';
export const LIMITATION_TAG = 'limitationTag';
export const NON_MEMBERS = 'nonMembers';

export const TOTAL_OCCURRENCES_CONDITION = 'totalOccurrencesCondition';
export const CONFIRM_DELETE_ACTION_DIALOG_OPEN = 'ConfirmDeleteActionDialogOpen';
export const BLOCK_SMS_ACTION_DIALOG_OPEN = 'BlockSmsActionDialogOpen';
export const OPEN_CONFIRM_DELETE_ACTION_DIALOG = 'OpenConfirmDeleteActionDialog';
export const OPEN_BLOCK_SMS_ACTION_DIALOG = 'OpenBlockSmsActionDialog';
export const OPEN_RE_FILTER_DIALOG = 'OpenReFilterDialog';
export const GET_BUSINESS_MONTHLY_SMS_MESSAGES_COUNT = 'GetBusinessMonthlySmsMessagesCount';
export const GET_BUSINESS_MONTHLY_EMAIL_MESSAGES_COUNT = 'GetBusinessMonthlyEmailMessagesCount';
export const TOTAL_OCCURRENCES_CONDITION_ENABLED = 'totalOccurrencesConditionEnabled';
export const BY_PERIOD_CONDITION_ENABLED = 'byPeriodConditionEnabled';
export const BY_PERIOD_CONDITION = 'byPeriodCondition';
export const BY_PERIOD_CONDITION_PERIOD = 'period';
export const BY_PERIOD_CONDITION_COUNT = 'count';
export const BY_PERIOD_PERIOD_MULTIPLIER = 'periodMultiplier';
export const IS_ROLLING_PERIOD = 'isRollingPeriod';
export const INFINITE = 'infinite';
export const LIMITED_PER_MEMBER = 'limitedPerMember';
export const LIMITED_PER_MEMBER_NONE_SELECTED_ERROR = 'limitedPerMemberNoneSelectedError';
export const LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE = 'type';
export const TAG_OCCURRENCES_BY_PERIOD_CALC = 'context.TagOccurrencesByPeriod';
export const DEAL_OCCURRENCES_BY_PERIOD_CALC = 'context.DealOccurrencesByPeriod';
export const TOTAL_TAG_OCCURRENCES_CALC = 'context.TotalTagOccurrences';
export const DEAL_OCCURRENCES_CALC = 'context.TotalDealOccurrences';
export const CONTEXT_MEMBERSHIP_TAG = 'context.Membership.Tag';
export const RE_FILTER_DIALOG_OPEN = 'ReFilterDialogOpen';

export const GLOBAL_CONDITIONS = 'globalConditions';
export const ONE_TIME_CONDITIONS = 'oneTimeConditions';
export const CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY = 'membershipByRegForm';
export const CONDITIONS_ALL_MEMBERSHIP_BY_REG_FORM_CATEGORY = 'allMembershipConditions';
export const CONDITIONS_MEMBERSHIP_ALWAYS_SHOW = 'membershipAlwaysShow';
export const GLOBAL_CONDITION_KEY = 'key';
export const MEMBERSHIP_GLOBAL_CONDITION_PREFIX = 'context.Membership.';
export const MEMBERSHIP_STATUS_CONDITION = 'statusCondition';
export const MEMBERSHIP_IMPORT_CONDITION = 'importCondition';
export const MEMBERSHIP_IMPORT_FROM_FILE_CONDITION = 'importFromFileCondition';
export const MEMBERSHIP_IMPORT_CONDITION_WRAPPER = 'importConditionWrapper';
export const IS_AUTO_TAG = 'isAutoTag';

export const DATES_RANGE_CONDITION = 'datesRangeCondition';
export const DAYS_TIMES_CONDITION = 'timeRangeInDaysOfWeekCondition';

export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TYPE = 'updateLimitPerMemberGlobalConditionType';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_LIMIT_ENABLE = 'updateLimitPerMemberGlobalConditionTotalLimitEnable';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_LIMIT_ENABLE = 'updateLimitPerMemberGlobalConditionByPeriodLimitEnable';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_UNIT_CHANGE = 'updateLimitPerMemberGlobalConditionByPeriodUnitChange';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_MULTIPLIER = 'updateLimitPerMemberGlobalConditionByPeriodPeriodMultiplier';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_COUNT = 'updateLimitPerMemberGlobalConditionByPeriodPeriodCount';
export const UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_OCCURRENCES_COUNT = 'updateLimitPerMemberGlobalConditionTotalOccurrencesCount';

export const PRIMARY_TRIGGER = 'primary';
export const PRIMARY_TRIGGER_ORDER = 'primaryOrder';

// Filters
export const FILTERS_ACTIVITY_ACTIVE = 'active';
export const FILTERS_ACTIVITY_DRAFT = 'draft';
export const FILTERS_ACTIVITY_PINNED = 'pinned';
export const FILTERS_ACTIVITY_STOPPED = 'stopped';
export const FILTERS_ACTIVITY_AUTO_ARCHIVED = 'isAutoArchived';
export const FILTERS_ACTIVITY_SEARCH_BY_TEXT = 'searchByText';
export const FILTERS_ACTIVITY_PAUSED = 'paused';
export const FILTERS_ACTIVITY_RULE = 'rule';
export const FILTERS_ACTIVITY_DEAL = 'deal';
export const FILTERS_ACTIVITY_ONE_TIME = 'onetime';
export const FILTERS_ACTIVITY_PUNCH_CARD = 'punchCard';
export const FILTERS_ACTIVITY_COMPLETED = 'completed';
export const FILTERS_ACTIVITY_FUTURE = 'future';
export const FILTER_SEARCH_BY_ACTIVITY_TEXT = 'searchByText';
export const RESET_ALL_FILTERS = 'resetAllFilters';

export const HIGHLIGHTED_ACTIVITY_ID = 'highlightedActivity';

export const RESET_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL = 'resetGiftInstanceExpirationGlobalConditionTempModel';
export const UPDATE_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL = 'updateGiftInstanceExpirationGlobalConditionTempModel';
export const APPLY_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL = 'applyGiftInstanceExpirationGlobalConditionTempModel';

// tabbedContent (gifts, punch cards...)
export const TABBED_CONTENT = 'tabbedContent';
export const DISPLAY_TAB_NAME = 'displayName';
export const DISPLAY_TAB_DESCRIPTION = 'displayDescription';
export const DISPLAY_TAB = 'giftDisplay';
export const SHOW_REDEEM_CODE_IN_APP = 'showRedeemCode';
export const DISPLAY_TAB_FIELD_NAME = 'fieldName';
export const DISPLAY_TAB_FIELD_VALUE = 'fieldValue';
export const GIFT_INTERACTIVE_TYPE = 'interactiveType';
export const GIFT_INTERACTIVE_TYPE_NONE = 'none';
export const GIFT_INTERACTIVE_TYPE_SCRATCH = 'scratch';
export const SCRATCH_ERROR = 'scratchError';
export const SCRATCH_CARD_HUB1_TAG = 'ScratchGift';

// StatsModal
export const ACTIVITY_STATS = 'stats';
export const ACTIVITY_AB_TEST_STATS = 'abTestStats';
export const ACTIVITY_STATS_LOADED = 'activityStatsLoaded';
export const GET_ACTIVITY_STATS = 'app/activity/GET_ACTIVITY_STATS';
export const GET_AB_TEST_STATS = 'app/activity/GET_AB_TEST_STATS';
export const GET_ACTIVITY_STATS_SUCCESS = 'app/activity/GET_ACTIVITY_STATS_SUCCESS';
export const GET_AB_TEST_STATS_SUCCESS = 'app/activity/GET_AB_TEST_STATS_SUCCESS';

// dirty
export const IS_ACTIVITY_DIRTY = 'isDirty';

// Birthday/anniversary condition event type values
export const BIRTHDAY_OR_ANNIVERSARY_PARAMETERS = 'birthdayOrAnniversaryParameters';
export const CLUB_MEMBER_ANNIVERSARY_PARAMETERS = 'clubMemberAnniversaryParameters';
export const EVENT_SCOPE = 'eventScope';
export const SCHEDULE = 'schedule';
export const EVENT_SCOPE_DAY = 'day';
export const EVENT_SCOPE_MONTH = 'month';
export const EVENT_SCOPE_YEAR = 'year';
export const EVENT_SCOPE_PERIOD = 'period';
export const EVENT_SCOPE_ONE_TIME = 'oneTime';

export const TRIGGER_SCHEDULE_VALUE = 'triggerSchedule';

// Tabbed Content
export const ACTIVITY_TAB_DISCOUNT = 'discounts';
export const ACTIVITY_TAB_LANDING_PAGE = 'landingPage';
export const ACTIVITY_TAB_DISPLAY = 'display';
export const ACTIVITY_TAB_TERMS = 'terms';
export const ACTIVITY_TAB_DEFINITION = 'definition';
export const ACTIVITY_TAB_COMMUNICATION = 'communication';
export const ACTIVITY_TAB_CODES = 'codes';

// Punch Cards
export const REQUIRED_PUNCHES = 'requiredPunches';
export const PUNCH_CARD_CHILD_ACTIVITIES = 'childActivities';
export const PUNCH_CARD_DELETED_RULES = 'punchCardDeletedRules';
export const PUNCH_CARD_ID_PLACEHOLDER = 'punchCardIdPlaceholder';
export const PUNCH_CARD_RULE_PUNCH = 'punchCardRulePunch';
export const PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH = 'punchCardRuleSendOnLastPunch';
export const PUNCH_CARD_RULE_SEND_ON_REDEEM = 'punchCardRuleSendOnRedeem';
export const PUNCH_CARD_RULE_SEND_ON_EXPIRATION = 'punchCardRuleSendOnExpiration';
export const PUNCH_CARD_RULE_PUNCH_OVERFLOW = 'punchCardRulePunchOverflow';
export const PUNCH_CARD_COMMUNICATION_RULES = 'punchCardCommunicationRules';
export const PUNCH_CARD_AUTO_RENEW = 'autoRenew';
export const PUNCH_CARD_AUTO_RENEW_TRIGGER = 'autoRenewTrigger';
export const PUNCH_CARD_RENEW_ON_REDEEM = 'renewOnRedeem';
export const PUNCH_CARD_RENEW_ON_LAST_PUNCH = 'renewOnLastPunch';
export const PUNCH_CARD_RENEW_ON_LAST_PUNCH_OR_EXPIRATION = 'renewOnLastPunchOrExpiration';
export const PUNCH_CARD_RENEW_ON_REDEEM_OR_EXPIRATION = 'renewOnRedeemOrExpiration';
export const PUNCH_CARD_REWARD_MODE = 'punchCardRewardMode';
export const PUNCH_CARD_REWARD_MODE_SIMPLE = 'simple';
export const PUNCH_CARD_REWARD_MODE_CUSTOM = 'custom';
export const TEMP_ACTIONS = 'tempActions';
export const TEMP_CASES = 'tempCases';

// migrations
export const MIGRATION_ID = 'migrationId';
export const ACTIVITY_MIGRATED = 'migrated';

// Promo Codes
export const PROMO_CODE_BULKS = 'codeBulks';
export const PROMO_CODE_TEST_BULK_MIN_SIZE = 'testBulkMinSize';
export const PROMO_CODE_BULKS_TYPE = 'bulkType';
export const PROMO_CODE_MODE = 'codeBulksMode';
export const PROMO_CODE_TEST_BULK = 'isTest';
export const PROMO_CODE_MODE_SINGLE_CODE = 'singleCode';
export const PROMO_CODE_MODE_FILE_UPLOADED = 'fileUploaded';
export const PROMO_CODE_MODE_GENERATED = 'generated';
export const PROMO_CODE_BULK_TYPE = 'type';
export const PROMO_CODE_BULK_IS_NEW = 'new';
export const PROMO_CODE_BULK_TYPE_SINGLE_CODE = 'singleCode';
export const PROMO_CODE_BULK_TYPE_FILE_UPLOADED = 'fileUploaded';
export const PROMO_CODE_SINGLE_CODE_BULK_VALUE = 'code';
export const PROMO_CODE_SINGLE_CODE_BULK_IS_AVAILABLE = 'isAvailable';
export const PROMO_CODE_FILE_UPLOADED_BULK_FILENAME = 'fileName';
export const PROMO_CODE_FILE_UPLOADED_BULK_BUCKETNAME = 'bucketName';
export const PROMO_CODE_FILE_UPLOADED_BULK_MEDIALINK = 'mediaLink';
export const UPDATE_PROMO_CODE_SINGLE_CODE_VALUE = 'app/activity/UPDATE_PROMO_CODE_SINGLE_CODE_VALUE';
export const UPDATE_PROMO_CODE_BULKS_VALUE = 'app/activity/UPDATE_PROMO_CODE_BULKS_VALUE';
export const UPDATE_PROMO_CODE_BULK_TYPE = 'app/activity/UPDATE_PROMO_CODE_BULK_TYPE';
export const CHECK_PROMO_CODE_BULK_IS_AVAILABLE = 'app/activity/CHECK_PROMO_CODE_BULK_IS_AVAILABLE';
export const UPDATE_PROMO_CODE_BULK_IS_AVAILABILITY = 'app/activity/UPDATE_PROMO_CODE_BULK_IS_AVAILABILITY';
export const GET_PROMO_CODE_FREE_CODES_NUMBER = 'app/activity/GET_PROMO_CODE_FREE_CODES_NUMBER';
export const GET_FREE_PROMO_CODES_NUMBER_SUCCESS = 'app/activity/GET_FREE_PROMO_CODES_NUMBER_SUCCESS';

export const PROMO_CODE_BULK_VALUE_TAKEN = 'app/activity/PROMO_CODE_BULK_VALUE_TAKEN';
export const PROMO_CODE_BULK_ERROR = 'app/activity/PROMO_CODE_BULK_ERROR';
export const PROMO_CODE_FILE_UPLOAD_IN_PROGRESS = 'promoCodeFileUploadInProgress';

export const PROMO_CODE_TAB_DISCOUNT = 'discount';
export const PROMO_CODE_TAB_TERMS = 'terms';
export const PROMO_CODE_TAB_CODES = 'codes';

// Context menu options
export const CONTEXT_MENU_OPTION_OPEN_IN_NEW_TAB = 'openInNewTab';
export const CONTEXT_MENU_OPTION_DETAILS = 'details';
export const CONTEXT_MENU_OPTION_ACTIVATE = 'activate';
export const CONTEXT_MENU_OPTION_PAUSE = 'pause';
export const CONTEXT_MENU_OPTION_DEACTIVATE = 'deactivate';
export const CONTEXT_MENU_OPTION_MOVE_TO = 'move-to';
export const CONTEXT_MENU_OPTION_DELETE = 'delete';
export const CONTEXT_MENU_OPTION_STATS = 'stats';
export const CONTEXT_MENU_OPTION_USAGES = 'usages';
export const CONTEXT_MENU_GO_TO_PAGE = 'goToPage';
export const CONTEXT_MENU_OPTION_EXTEND = 'extend';
export const CONTEXT_MENU_OPTION_RESTART = 'restart';

export const PROMO_CODES_SERVER_ERROR_LENGTH = 'bulk with variable code lengths';
export const PROMO_CODES_SERVER_ERROR_EMPTY = 'empty bulk';
export const PROMO_CODES_SERVER_ERROR_NUMBERS = 'code must be a number';
export const PROMO_CODES_SERVER_ERROR_SHORT = 'code too short';

// micro campaigns
export const MICRO_CAMPAIGN_PREFIX = 'microCampaign';
export const ACTION_META = 'actionMetaData';
export const TITLE = 'title';

// Gift Landing Page
export const UPDATE_LANDING_PAGE_ACTIVATION_STATUS = 'app/activity/updateLandingPageActivationStatus';
export const UPDATE_LANDING_PAGE_INPUT_TEXT = 'app/activity/updateLandingPageInputText';
export const UPDATE_LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX = 'app/activity/updateLandingPageOnlyNewMembers';
export const UPDATE_LANDING_PAGE_SHOW_LEGAL_SECTION_TOGGLE = 'app/activity/updateLandingShowLegalSection';
export const UPDATE_LANDING_PAGE_SHOW_SOCIAL_SECTION_TOGGLE = 'app/activity/updateLandingShowSocialSection';
export const OPEN_LANDING_PAGE_MODAL = 'app/activity/openLandingPageModal';
export const CLEAR_LANDING_PAGE_MODAL_DATA = 'app/activity/clearLandingPageModalData';
export const REQUEST_TO_ENABLE_GIFT_LANDING_PAGE_FEATURE = 'app/activity/requestToEnableGiftLandingPageFeature';
export const REQUEST_TO_ENABLE_PUSH_NOTIFICATION_WITH_IMAGE_FEATURE = 'app/activity/requestToPushNotificationWIthImageFeature';

// Test to myself
export const SUBMIT_ACTION_TO_CUSTOMER = 'app/activity/SUBMIT_ACTION_TO_CUSTOMER';
export const DECLARE_TEST_COMPLETED = 'app/activity/DECLARE_TEST_COMPLETED';
export const CLEAR_TEST_TO_MYSELF_SUCCEEDED = 'app/activity/CLEAR_TEST_TO_MYSELF_SUCCEEDED';
export const DECLARE_TEST_SUCCEEDED = 'app/activity/DECLARE_TEST_SUCCEEDED';
export const UPDATE_PROGRESS_STATUS = 'app/activity/UPDATE_PROGRESS_STATUS';
export const TEST_TO_MYSELF = 'testToMyself';
export const TEST_TO_MYSELF_COMPLETED = 'completed';
export const TEST_TO_MYSELF_SUCCEEDED = 'succeeded';
export const TEST_TO_MYSELF_IN_PROGRESS = 'inProgress';
export const TEST_TO_MYSELF_IN_PROGRESS_WRAPPER_INDEX = 'inProgressWrapperIndex';
export const TEST_TO_MYSELF_IN_PROGRESS_INDEX = 'inProgressIndex';
export const ACTION_PATH = 'actionPath';
export const ACTIONS_WRAPPER_INDEX = 'actionsWrapperIndex';
export const ACTION_INDEX = 'actionIndex';

// comillia hint adjustments on activity
export const FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT = 'comilliaAdjusted';
export const ADJUST_BY_MEMBER_PROPERTIES = 'adjustByMemberProperties';
export const COMILLIA_HINT_OPEN_IN_ACTION = 'comilliaHintOpenInAction';
export const CASE = 'case';

export const LOOKER_QUERY_FETCHED = 'lookerQueryFetched';

export const LOCATIONS_GROUP_NAME = 'name';
export const LOCATIONS_GROUP_Id = 'serverId';
