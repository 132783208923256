import moment from 'moment';
import { fromJS, List } from 'immutable';
import * as imageConstants from '../Images/images.constants';
import * as constants from './activity.constants';
import * as schemaConstants from './activitySchema/activitySchema.constants';
import * as datesConstants from '../../common/utils/dateTime.constants';
import * as reducerConstants from '../../constants/reducer.constants';

const activityExpireDays = {};
activityExpireDays[constants.SERVER_TYPE_RULE] = 31;
activityExpireDays[constants.SERVER_TYPE_DEAL] = 31;
activityExpireDays[constants.SERVER_TYPE_PUNCH_CARD] = 31;
activityExpireDays[constants.SERVER_TYPE_ONE_TIME_ACTION] = 15;

export function getSelectedGiftImageImpl(imageId, images) {
  const thumbnails = images.get(imageConstants.IMAGE_SIZE_THUMBNAIL);
  if (!thumbnails || thumbnails.size === 0) {
    return null;
  }
  return thumbnails.find((image) => image.get(imageConstants.ID) === imageId);
}

export function getConditionsForSelectedTriggerImpl(schema, trigger, transformedUpdateMembershipConditions) {
  if (trigger === schemaConstants.TRIGGER_UPDATE_MEMBERSHIP) {
    return transformedUpdateMembershipConditions;
  }
  return schema.getIn([constants.CONDITIONS_PER_TRIGGERS, trigger]);
}

export function getDateTimeGlobalConditionTypeImpl(tempDateTimeGlobalConditionsType, initialConditionType) {
  return (tempDateTimeGlobalConditionsType !== undefined && tempDateTimeGlobalConditionsType !== null)
    ? tempDateTimeGlobalConditionsType
    : initialConditionType;
}

export function getDateTimeGlobalConditionInitialConditionTypeImpl(globalCondition) {
  if (globalCondition && (globalCondition.get(constants.DATES_RANGE_CONDITION) || globalCondition.get(constants.DAYS_TIMES_CONDITION))) {
    return constants.DATETIME_SPECIFIC;
  }
  return constants.DATETIME_ANY;
}

export function getDateTimeGlobalConditionsImpl(selectedTrigger, schema) {
  const excludedTriggers = [
    schemaConstants.TRIGGER_ONE_TIME_ACTION,
    schemaConstants.TRIGGER_FILTER_MEMBERS,
    schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY,
    schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY,
    schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY,
  ];
  if (excludedTriggers.includes(selectedTrigger)) {
    return List();
  }
  return schema.getIn([constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS]);
}

export const getDisplayTabValidationErrorsImpl = /* istanbul ignore next */
  (validationErrors) => {
    if (validationErrors) {
      return validationErrors.get(constants.DISPLAY_TAB);
    }
    return null;
  };

export const filterNonActivityValidationErrors = /* istanbul ignore next */
  (validationErrors) => {
    if (validationErrors) {
      return validationErrors.delete(constants.DISPLAY_TAB);
    }
    return null;
  };

export const getMembershipGlobalConditionTypeImpl = /* istanbul ignore next */
  (state, isMemberFilter) => isMemberFilter
    ? state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS, constants.TYPE])
    : state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.TYPE]);

export const getMembershipGlobalConditionsImpl = /* istanbul ignore next */
  (state, isMemberFilter) => isMemberFilter
    ? state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS])
    : state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);

export const isActivityDisabledImpl = /* istanbul ignore next */
  (activityType, activityViewStatus) => (
    activityType === constants.ACTIVITY_TYPE_ONE_TIME &&
    (activityViewStatus === constants.ACTIVITY_STATUS_COMPLETED || activityViewStatus === constants.ACTIVITY_STATUS_ACTIVE)
  ) || (
    activityType === constants.ACTIVITY_TYPE_PROMO_CODE &&
    (activityViewStatus === constants.ACTIVITY_STATUS_COMPLETED || activityViewStatus === constants.ACTIVITY_STATUS_STOPPED)
  );

/**
 *
 * @param activityData
 * @returns {boolean}
 * search criteria not allowed for already activated one time activities
 */
export const isOneTimeSaveSearchCriteriaAllowedImpl =
    (activityData) => activityData.get(constants.TYPE) === constants.ACTIVITY_TYPE_ONE_TIME && !activityData.get(constants.SERVER_ID);

const calculateActivityViewStatusByDateTimeGlobalCondition = (activity) => {
  const now = moment.utc();
  const datetimeGlobalCondition = activity.get(constants.DATETIME_GLOBAL_CONDITIONS);
  const datesRangeCondition = datetimeGlobalCondition && datetimeGlobalCondition.get(constants.DATES_RANGE_CONDITION);
  if (!datesRangeCondition) {
    return constants.ACTIVITY_STATUS_ACTIVE;
  }
  const operatorKey = datesRangeCondition.get(constants.OPERATOR_KEY);
  const conditionValue = datesRangeCondition.get(constants.CONDITION_VALUE);
  switch (operatorKey) {
    case (schemaConstants.OPERATOR_KEY_DATE_TIME_IS_AFTER):
      return now < moment(conditionValue)
        ? constants.ACTIVITY_STATUS_FUTURE
        : constants.ACTIVITY_STATUS_ACTIVE;
    case schemaConstants.OPERATOR_KEY_DATE_TIME_IS_BEFORE:
      return now > moment(conditionValue)
        ? constants.ACTIVITY_STATUS_COMPLETED
        : constants.ACTIVITY_STATUS_ACTIVE;
    case schemaConstants.OPERATOR_KEY_DATE_TIME_IS_BETWEEN: {
      if (now < moment(conditionValue.get(datesConstants.DATE_FROM))) {
        return constants.ACTIVITY_STATUS_FUTURE;
      }
      if (now > moment(conditionValue.get(datesConstants.DATE_TO))) {
        return constants.ACTIVITY_STATUS_COMPLETED;
      }
      return constants.ACTIVITY_STATUS_ACTIVE;
    }
    default:
      return constants.ACTIVITY_STATUS_ACTIVE;
  }
};

export const calculateActivityViewStatus = (activityInput) => {
  if (!activityInput) {
    return null;
  }
  const activity = activityInput.toJS ? activityInput : fromJS(activityInput);
  const status = activity.get(constants.STATUS);
  const activityType = activity.get(constants.TYPE);
  switch (status) {
    case constants.ACTIVITY_STATUS_ACTIVE: {
      const now = moment.utc();
      if (activityType === constants.ACTIVITY_TYPE_ONE_TIME || activityType === constants.SERVER_TYPE_ONE_TIME_ACTION) {
        // For 1-time action, if the action was created with immediate scheduling or with a date
        // less than a minute ago, it will be shown as active
        const scheduling = activity.get(constants.ONE_TIME_ACTION_SCHEDULING);
        const schedulingType = scheduling.get(constants.TYPE);
        const schedulingDate = scheduling && moment(scheduling.get(constants.SCHEDULE_DATE));
        const minuteFromScheduleDate = schedulingDate && moment(schedulingDate).add(1, 'minutes');
        if (schedulingDate && (now < schedulingDate) && schedulingType != constants.SCHEDULE_IMMEDIATE) {
          return constants.ACTIVITY_STATUS_FUTURE;
        } else if ((minuteFromScheduleDate) && now < minuteFromScheduleDate) {
          return constants.ACTIVITY_STATUS_ACTIVE;
        }
        return constants.ACTIVITY_STATUS_COMPLETED;
      }
      return calculateActivityViewStatusByDateTimeGlobalCondition(activity);
    }
    case constants.ACTIVITY_STATUS_INACTIVE:
      return constants.ACTIVITY_STATUS_STOPPED;
    case constants.ACTIVITY_STATUS_PAUSED: {
      const viewStatusByDatetimeGlobalCondition = calculateActivityViewStatusByDateTimeGlobalCondition(activity);
      if (viewStatusByDatetimeGlobalCondition === constants.ACTIVITY_STATUS_COMPLETED) {
        return constants.ACTIVITY_STATUS_COMPLETED;
      }
      return constants.ACTIVITY_STATUS_PAUSED;
    }
    default:
      return status;
  }
};

export const isAutoArchived = (activity) => {
  const type = activity.get(constants.TYPE);

  const daysExpiration = activityExpireDays[type];
  if (daysExpiration) {
    const now = moment.utc();
    const lastUpdated = activity.get('lastUpdate');
    const status = calculateActivityViewStatus(activity);
    return (status === constants.ACTIVITY_STATUS_COMPLETED || status === constants.ACTIVITY_STATUS_STOPPED) && moment(lastUpdated).add(daysExpiration, 'days') < now;
  }

  return false;
};

/**
 * filter out itemsGroup condition from SHOPPING_CARD trigger for advancedRule
 * @param schemaConditions
 * @returns {*}
 */
export const filterConditionsForAdvancedRule = (schemaConditions) => schemaConditions.filter((condition) => condition.get(constants.SCHEMA_CONDITION_KEY) !== schemaConstants.ITEMS_GROUP);

export const getActivityTabsErrorsImpl =  /* istanbul ignore next */
  (activityValidationErrors, displayTabValidationErrors) => fromJS({
    [constants.ACTIVITY_TAB_DEFINITION]: Boolean(activityValidationErrors && (activityValidationErrors.get(constants.ACTIVITY_ACTIONS) || activityValidationErrors.getIn([constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH]))),
    [constants.ACTIVITY_TAB_DISCOUNT]: Boolean(activityValidationErrors && activityValidationErrors.get(constants.ACTIVITY_ACTIONS)),
    [constants.ACTIVITY_TAB_TERMS]: Boolean(activityValidationErrors && (activityValidationErrors.get(constants.CONDITIONS) || activityValidationErrors.get(constants.GLOBAL_CONDITIONS))),
    [constants.ACTIVITY_TAB_DISPLAY]: Boolean(displayTabValidationErrors),
    [constants.ACTIVITY_TAB_COMMUNICATION]: Boolean(activityValidationErrors && activityValidationErrors.getIn([constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES])),
    [constants.ACTIVITY_TAB_CODES]: Boolean(activityValidationErrors && activityValidationErrors.get(constants.PROMO_CODE_BULKS))
  });

export const getActivityTabsImpl = /* istanbul ignore next */
  (activityType) => {
    switch (activityType) {
      case constants.ACTIVITY_TYPE_PUNCH_CARD:
        return [
          constants.ACTIVITY_TAB_DEFINITION,
          constants.ACTIVITY_TAB_TERMS,
          constants.ACTIVITY_TAB_COMMUNICATION,
          constants.ACTIVITY_TAB_DISPLAY
        ];
      case constants.ACTIVITY_TYPE_GIFT:
        return [
          constants.ACTIVITY_TAB_DISCOUNT,
          constants.ACTIVITY_TAB_TERMS,
          constants.ACTIVITY_TAB_DISPLAY,
          constants.ACTIVITY_TAB_LANDING_PAGE,
        ];
      case constants.ACTIVITY_TYPE_PROMO_CODE:
        return [
          constants.ACTIVITY_TAB_DISCOUNT,
          constants.ACTIVITY_TAB_TERMS,
          constants.PROMO_CODE_TAB_CODES
        ];
      default:
        return [];
    }
  };

/**
 * returns a flattened list of actions from the given activity for summary purposes
 * @param activity
 * @returns {*}
 */
export function reduceActionsForSummary(activity) {
  const activityActions = activity.get(constants.ACTIVITY_ACTIONS);
  const actions = activityActions && activityActions.size ? activityActions
    : activity.getIn([constants.CASES, '0', constants.ACTIVITY_ACTIONS]);
  if (actions) {
    return actions.reduce((accumulator, actionsWrapper) =>
      actionsWrapper.get(constants.ACTIONS_LIST).reduce((accumulator2, action) =>
        accumulator2.push(action), accumulator), List());
  }
  return List();
}

export function getActivityExportMembersIsReadyImpl(state) {
  const type = state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING, constants.TYPE]);
  const date = state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING, constants.SCHEDULE_DATE]);
  if (!type || (type === constants.SCHEDULE_SCHEDULED && !date)) {
    return false;
  }
  return moment(date).add(15, 'minutes') < moment();
}
