import React from 'react';
import { fromJS } from 'immutable';
import { Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Condition from '../Activity/views/conditions/condition';
import DataAndBiContainer from '../DataAndBI/dataAndBi.container';
import BasicContainer from '../Basic/Basic.container';
import FilterUserAttributes from '../Activity/views/conditions/globalConditions/filters/FilterUserAttribute';
import HeaderNotification from '../Activity/views/activityHeader/HeaderNotification';
import tracker from '../../common/utils/tracking/tracker';
import FilterMembersResults from './FilterMembers.results';

import * as selectors from './filterMembers.selectors';
import * as dataAndBiSelectors from '../DataAndBI/dataAndBi.selectors';

import * as actions from './filterMembers.actions';
import * as formsActions from '../Forms/forms.actions';
import * as appActions from '../App/app.actions';
import FilterUserActions from '../Activity/views/conditions/globalConditions/filters/FilterUserActions';
import * as globalConditionsActions from '../Activity/views/conditions/globalConditions/globalConditions.actions';

import filterMembersSaga from './filterMembers.saga';
import formsSaga from '../Forms/forms.saga';
import appSaga from '../App/app.saga';
import activitySaga from '../Activity/activity.saga';
import membersSaga from '../Members/members.saga';

import injectSaga from '../../utils/injectSaga';
import withAuthorization from '../../common/helpers/authorization';

import * as activityConstants from '../Activity/activity.constants';
import * as schemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';

import glasses from '../../images/icons/memberImages/glasses.svg';

import './filterMembers.styles.less';
import * as appConstants from '../App/app.constants';
import * as appSelectors from '../App/selectors'

export class FilterMembers extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    intl: PropTypes.object,
    membershipGlobalConditions: PropTypes.object,
    membershipGlobalOperator: PropTypes.string,
    schemaMembershipConditions: PropTypes.object,
    actions: PropTypes.object,
    filterTempGlobalCondition: PropTypes.object,
    filtersInProgress: PropTypes.bool,
    modalValidationErrors: PropTypes.object,
    filteredPopulation: PropTypes.object,
    tempCondition: PropTypes.object,
    filteredPopulationSize: PropTypes.number,
    filteredPopulationSamples: PropTypes.array,
    membersImages: PropTypes.array,
    hasFullPermission: PropTypes.func,
    getPermissionLevel: PropTypes.func.isRequired,
    tempReferralCondition: PropTypes.object,
    referralTempError: PropTypes.string,
    noMembers: PropTypes.bool,
    isFranchise: PropTypes.bool,
    activitiesFetched: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    userAccessGroups: PropTypes.object,
  };

  componentWillMount() {
    if (this.props.schemaMembershipConditions === null) {
      this.props.actions.getRegistrationFormFields();
    }
    // this.props.actions.openGlobalMembershipConditionsModal(true);
    if (this.props.filteredPopulationSamples && this.props.filteredPopulationSamples.length > 0) {
      this.props.filteredPopulationSamples.forEach((f) => {
        f.image = this.getRandomMemberImage();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filteredPopulationSamples !== this.props.filteredPopulationSamples) {
      if (nextProps.filteredPopulationSamples && nextProps.filteredPopulationSamples.length > 0) {
        nextProps.filteredPopulationSamples.forEach((f) => {
          f.image = this.getRandomMemberImage();
        });
      }
    }
  }

  accessGroupIsEnabledAndUserHasAccessGroup() {
    return this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS) && this.props.userAccessGroups && this.props.userAccessGroups.size > 0;
  }

  componentDidMount() {
    if (!this.props.activitiesFetched) {
      this.props.actions.getActivitiesList();
    }

    if(this.accessGroupIsEnabledAndUserHasAccessGroup()) {
      this.props.actions.updateDefaultFilterWithAccessGroup(this.props.userAccessGroups);
    }

  }

  /**
   * filters out the specific membership status condition (it has special treatment in a separate from condition component)
   */
  filteredMembershipConditionsSchema() {
    if (this.props.schemaMembershipConditions) {
      return this.props.schemaMembershipConditions.filter((conditionInList) =>
        conditionInList.get(activityConstants.GLOBAL_CONDITION_KEY) !== schemaConstants.CONTEXT_MEMBERSHIP_STATUS);
    }
    return fromJS({});
  }

  getRandomMemberImage() {
    if (this.props.membersImages && this.props.membersImages.length > 0) {
      const i = Math.floor(Math.random() * (this.props.membersImages.length - 1));
      return this.props.membersImages[i];
    }
    return glasses;

  }



  render() {
    const ContainerComponent = this.props.getBusinessBundlePlan() === 'trial' ? BasicContainer : DataAndBiContainer;
    const isFranchise = this.props.isFranchise;
    const isAccessGroupFeatureEnabledAndHasAccessGroup = this.accessGroupIsEnabledAndUserHasAccessGroup();
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const filteredMembershipConditionsFromSchema = this.filteredMembershipConditionsSchema();
    const showAddConditionButton = !this.props.membershipGlobalConditions || this.props.membershipGlobalConditions.size === 0 ||
      this.props.membershipGlobalConditions.last().get(activityConstants.CONDITION_KEY) !== '';

    if (this.props.schemaMembershipConditions == null) {
      return null;
    }

    let filtersErrorMessage = null;
    if (this.props.modalValidationErrors) {
      filtersErrorMessage = formatMessage({ id: 'activity.validation.error.filter.general' });
    }
    return (
      <ContainerComponent
        className={'filterMembers'}
        headClassName={'filter-members'}
        translationKey={'filter_members_title'}
        isFilterMembers
      >
        <div className="filter-members-wrapper">
          <div className="stick-to-header-wrapper">
            {filtersErrorMessage && (
              <div className="modal-toolbar-error-panel">
                <span className="toolbar-error-text">{filtersErrorMessage}</span>
              </div>
            )}
          </div>
          <div className="inner-filter-members-wrapper">
            <div className={'inner-filter-wrapper'}>
              <div className="inner-filter-top-bar">
                <div className="inner-filter-members-title">{formatMessage({ id: 'filter_members_by' })}</div>
                <div className={'top-actions stick-to-right'}>
                  <div className={'display-inline single-action'}>
                    <Button
                      onClick={tracker.wrapButtonAction(this.props.actions.clearFilterFields, trackerConstants.BUTTON_CLEAR_FILTER_POPULATION)}
                      className={'link filter-icon delete-filter unset-padding !tw-flex'}
                      basic
                    >
                      <Icon className="como-ic-delete delete-filter unset-padding filter-top-actions"/>
                      {formatMessage({ id: 'general.button.clear' })}
                    </Button>
                  </div>
                </div>
                <hr className={'title-bottom-border'}/>
              </div>
              <div>
                {isFranchise && (
                  <HeaderNotification
                    messageKey="filter.franchises.notification"
                    automationIdPrefix={'filter.franchises'}
                  />
                )}
              </div>
              <FilterUserAttributes
                membersFilter
                membershipGlobalConditions={this.props.membershipGlobalConditions}
                prop5={(condition, index) => (
                  <Condition
                    updateConditionField={this.props.actions.updateFilterMembershipConditionField}
                    condition={condition}
                    conditionsList={filteredMembershipConditionsFromSchema}
                    index={index}
                    key={`membershipGlobalCondition-${index.toString()}`}
                    isInsideModal
                    automationIdPrefix={`globalConditions.members.condition.${index}`}
                    mainConditions={false}
                    conditionKeyWidth={5}
                    conditionOperatorWidth={4}
                    conditionValueWidth={5}
                    deleteCondition={this.props.actions.deleteFilterAttributeCondition}
                    getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    validationErrors={this.props.modalValidationErrors ? this.props.modalValidationErrors.getIn([activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS, activityConstants.CONDITIONS, (index.toString())]) : null}
                  />
                )}
                showAddConditionButton={showAddConditionButton}
                onClick={this.props.actions.addFilterMembershipConditionField}
                onUpdateConditionRelation={this.props.actions.updateConditionsOperator}
                conditionsRelation={this.props.membershipGlobalOperator}
                userHasAccessGroup={isAccessGroupFeatureEnabledAndHasAccessGroup}
              />
              <FilterUserActions
                conditionsModel={this.props.filterTempGlobalCondition}
                updateFilterUserActionType={this.props.actions.updateFilterMembersUserActionType}
                addUserActionCriteriaToFilter={this.props.actions.addUserActionCriteriaToFilterMembers}
                updateFilterUserActionAttribute={this.props.actions.updateFilterMembersUserActionAttribute}
                tempCondition={this.props.tempCondition}
                deleteUserActionFromFilter={(userActionIndex) => this.props.actions.deleteFilterUserActionFromFilter(userActionIndex)}
                deleteUserActionCriteriaFromFilter={(userActionIndex, conditionIndex) => this.props.actions.deleteFilterUserActionCriteriaFromFilter(userActionIndex, conditionIndex)}
                updateUserActionFilterDateRange={(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone) => this.props.actions.updateFilterUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone)}
                updateFilterUserActionAggregationAttribute={(name, value, isActive, index, userActionIndex) => this.props.actions.updateFilterUserActionFilterAggregationAttribute(name, value, isActive, index, userActionIndex)}
                tempReferralCondition={this.props.tempReferralCondition}
                referralTempError={this.props.referralTempError}
                createReferralCodeTempModel={this.props.actions.createFilterReferralCodeTempModel}
                deleteReferralCodeTempModel={this.props.actions.deleteFilterReferralCodeTempModel}
                saveReferralCodeTempModelToGlobalConditions={this.props.actions.saveFilterReferralCodeTempModelToGlobalConditions}
                validationErrors={this.props.modalValidationErrors}
                onUpdateConditionRelation={this.props.actions.updateConditionsActionOperator}
                activities
                activitiesFetched
              />
              <div className={'filter-members-btn-wrapper stick-to-right'}>
                {!isAccessGroupFeatureEnabledAndHasAccessGroup && (<Button
                  key="filterAllMembersBtn"
                  onClick={tracker.wrapButtonAction(this.props.actions.filterAllMembers, trackerConstants.BUTTON_FILTER_ALL_MEMBERS)}
                  basic
                  id={'filter-all-members-btn'}
                  className="link modal-button filter-all-members-btn"
                  data-automation-id="filter.allMembers.btnSaveFilterCriteria"
                  disabled={false}
                >
                  {formatMessage({ id: 'filter_members.button.filter_all' })}
                </Button>
                )}
                <Button
                  key="filterMembersBtn"
                  onClick={tracker.wrapButtonAction(this.props.actions.filterMembersByAttributesAndActions, trackerConstants.BUTTON_TYPE_FILTER_POPULATION)}
                  className="modal-button filter-members-btn"
                  data-automation-id="filter-members.btn"
                  disabled={this.props.filtersInProgress}
                  loading={this.props.filtersInProgress}
                >
                  {formatMessage({ id: 'filter_members.button.filter' })}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {((this.props.filteredPopulationSize && this.props.filteredPopulationSize > 0) || this.props.noMembers) &&
          <FilterMembersResults
            getPermissionLevel={this.props.getPermissionLevel}
            getCustomer={this.props.actions.getCustomer}
            filteredPopulationSize={this.props.filteredPopulationSize}
            filteredPopulationSamples={this.props.filteredPopulationSamples}
            membersImages={this.props.membersImages}
            noMembers={this.props.noMembers}
            filteredPopulation={this.props.filteredPopulation}
            createOneTimeAction={this.props.actions.createOneTimeAction}
            exportFilteredPopulation={this.props.actions.exportFilteredPopulation}
            filterTempGlobalCondition={this.props.filterTempGlobalCondition}
          />
        }
      </ContainerComponent>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const creators = {
    ...actions,
    ...formsActions,
    ...globalConditionsActions,
    ...appActions
  };

  return { actions: bindActionCreators(creators, dispatch) };
};

const mapStateToProps = (state) => ({
  filterTempGlobalCondition: selectors.getModel(state),
  schemaMembershipConditions: selectors.getFilterGlobalMembershipConditionsFromSchema(state),
  membershipGlobalConditions: selectors.getTempModel(state),
  membershipGlobalOperator: selectors.getTempListOperator(state),
  fetchedFormFields: selectors.getFetchedFormFields(state),
  filtersInProgress: selectors.getFiltersInProgressFlag(state),
  modalValidationErrors: selectors.getFiltersMembersConditionsValidationErrors(state),
  noMembers: selectors.getFilterMembersNumberOfMembersValidationErrors(state),
  filteredPopulation: selectors.getFilterMembersPopulation(state),
  filteredPopulationSize: selectors.getFilteredPopulationSize(state),
  filteredPopulationSamples: selectors.getFilteredPopulationSamples(state),
  membersImages: selectors.getMemberImages(),
  tempReferralCondition: selectors.getReferralCodeTempModelCondition(state),
  referralTempError: selectors.getReferralCodeTempError(state),
  isFranchise: dataAndBiSelectors.getIsUserFranchise(state),
  activities: selectors.getActivitiesList(state),
  activitiesFetched: selectors.getActivitiesListFetchedFlag(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.FILTER_MEMBERS, saga: filterMembersSaga });
const withFormsSaga = injectSaga({ key: reducerConstants.FORMS_BRANCH, saga: formsSaga });
const withAppSaga = injectSaga({ key: reducerConstants.APP_BRANCH, saga: appSaga });
const withActivitySaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga: activitySaga });
const withMembersSaga = injectSaga({ key: reducerConstants.CUSTOMER_BRANCH, saga: membersSaga });

export default compose(withConnect, withSaga, withFormsSaga, withAppSaga, withActivitySaga, withMembersSaga, withAuthorization, injectIntl)(FilterMembers);
