// Actions
export const SAVE_FRANCHISE_FIELD = 'app/franchise/SAVE_FRANCHISE_FIELD';
export const SAVING_FRANCHISE_FIELD_FAIL = 'app/franchise/SAVING_FRANCHISE_FIELD_FAIL';
export const GET_BUSINESS_ACCESS_GROUPS = 'app/franchise/GET_BUSINESS_ACCESS_GROUPS';
export const GETTING_ACCESS_GROUPS_SUCCESS = 'app/franchise/GETTING_ACCESS_GROUPS_SUCCESS';
export const GETTING_ACCESS_GROUPS_FAIL = 'app/franchise/GETTING_ACCESS_GROUPS_FAIL';
export const ADD_NEW_ACCESS_GROUP = 'app/franchise/ADD_NEW_ACCESS_GROUP';
export const ADDING_ACCESS_GROUP_SUCCESS = 'app/franchise/ADDING_ACCESS_GROUP_SUCCESS';
export const ADDING_ACCESS_GROUP_FAIL = 'app/franchise/ADDING_ACCESS_GROUP_FAIL';
export const ADDING_USER_TO_ACCESS_GROUP_FAIL = 'app/franchise/ADDING_USER_TO_ACCESS_GROUP_FAIL';
export const ADDING_USER_TO_ACCESS_GROUP_SUCCESS = 'app/franchise/ADDING_USER_TO_ACCESS_GROUP_SUCCESS';
export const ADD_USER_TO_ACCESS_GROUP = 'app/franchise/ADD_USER_TO_ACCESS_GROUP';
export const CHANGING_ACCESS_GROUP_NAME_FAIL = 'app/franchise/CHANGING_ACCESS_GROUP_NAME_FAIL';
export const CHANGE_ACCESS_GROUP_NAME_ON_SERVER = 'app/franchise/CHANGE_ACCESS_GROUP_NAME_ON_SERVER';
export const REMOVE_USER_FROM_ACCESS_GROUP = 'app/franchise/REMOVE_USER_FROM_ACCESS_GROUP';
export const REMOVING_USER_FROM_ACCESS_GROUP_FAIL = 'app/franchise/REMOVING_USER_FROM_ACCESS_GROUP_FAIL';
export const REMOVING_ACCESS_GROUP_FAIL = 'app/franchise/REMOVING_ACCESS_GROUP_FAIL';
export const REMOVING_USER_FROM_ACCESS_GROUP_SUCCESS = 'app/franchise/REMOVING_USER_FROM_ACCESS_GROUP_SUCCESS';
export const REMOVE_ACCESS_GROUP = 'app/franchise/REMOVE_ACCESS_GROUP';
export const REMOVING_ACCESS_GROUP_SUCCESS = 'app/franchise/REMOVING_ACCESS_GROUP_SUCCESS';
export const CHANGE_ACCESS_GROUP_NAME_SUCCESS = 'app/franchise/CHANGE_ACCESS_GROUP_NAME_SUCCESS';
export const RESET_ACCESS_GROUP_ERROR = 'app/franchise/RESET_ACCESS_GROUP_ERROR';
export const START_LOADING_ACCESS_GROUP = 'app/franchise/START_LOADING_ACCESS_GROUP';
export const END_LOADING_ACCESS_GROUP = 'app/franchise/END_LOADING_ACCESS_GROUP';
export const DISABLE_ACCESS_GROUP_ADD_BUTTON = 'app/franchise/DISABLE_ACCESS_GROUP_ADD_BUTTON';

//state fields
export const ACCESS_GROUPS = 'accessGroups';
export const HUB_USERS = 'hubUsers';
export const Franchise = 'franchise';
export const ACCESS_GROUP_FETCH_ERROR = 'accessGroupFetchError';
export const ACCESS_GROUP_ERROR = 'accessGroupError';
export const ACCESS_GROUP_ERROR_MESSAGE = 'accessGroupErrorMessage';
export const ACCESS_GROUP_ADD_BUTTON_DISABLED = 'accessGroupAddButtonDisabled';

// access group fields
export const ACCESS_GROUP_ID = 'id';
export const USER_ID = 'id';
export const NAME = 'name';
export const ACCESS_GROUP_HUB_USERS = 'hubUsers';
export const LOADING = 'loading';


// access group users fields
export const HUB_USER_ID = 'userId';
export const USER_Email = 'email';
