import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import { Toast } from '@como/web-hub-components';
import * as selectors from './businessCenter.selectors';
import ActivityMoveTo from './views/ActivityMoveTo/ActivityMoveTo';
import injectSaga from '../../utils/injectSaga';
import saga from './businessCenter.saga';
import * as constants from './businessCenter.constants';
import * as businessCenterActions from './businessCenter.actions';
import { createActivity } from '../Activity/activity.actions';
import { setLoadingState } from '../App/app.actions';
import BusinessCenterToolbar from './views/BusinessCenterToolbar';
import Campaign from './views/Campaign';
import tracker from '../../common/utils/tracking/tracker';
import './businessCenter.styles.less';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import * as appSelectors from '../App/selectors';
import SideNavBar from '../SideNavBar/SideNavBar';
import { calculateActivityViewStatus } from '../Activity/activity.selectors.utils';
import * as reducerConstants from '../../constants/reducer.constants';
import { getSideNavbarSettings } from './businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import * as appConstants from '../App/app.constants';
import * as routeConstants from '../../constants/route.contants';
import * as activityConstants from '../Activity/activity.constants';
import * as schemaSelectors from '../Activity/activitySchema/activitySchema.selectors';
import BasicPage from '../Basic/Basic.container';
import * as benefitsSelectors from '../Benefits/benefits.selector';
import * as itemGroupsSelectors from '../ItemGroups/itemGroups.selectors';
import * as formsSelectors from '../Forms/forms.selectors';
import * as benefitConstants from '../Benefits/benefits.constants';
import * as benefitsActions from '../Benefits/benefits.actions';
import * as itemGroupsActions from '../ItemGroups/itemGroups.actions';
import * as formsActions from '../Forms/forms.actions';
import * as itemGroupsConstants from '../ItemGroups/itemGroups.constants';
import * as formsConstants from '../Forms/forms.constants';
import itemGroupsSaga from '../ItemGroups/itemGroups.saga';
import benefitsSaga from '../Benefits/benefits.saga';
import formsSaga from '../Forms/forms.saga';

export class BusinessCenter extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    campaignIdToFocus: PropTypes.string,
    campaigns: PropTypes.object.isRequired,
    campaignsLoaded: PropTypes.bool.isRequired,
    filters: PropTypes.object,
    triggers: PropTypes.object,
    triggersShowList: PropTypes.object,
    actionFiltersList: PropTypes.object,
    actionFiltersShowList: PropTypes.object,
    highlightedActivityId: PropTypes.string,
    isFiltered: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired,
    sort: PropTypes.string,
    businessDisplayName: PropTypes.string,
    intl: PropTypes.object,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    hasFullPermission: PropTypes.func.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    deprecatedTriggers: PropTypes.object,
    fetchedActivitiesWithArchives: PropTypes.bool,
    showBasicPlan: PropTypes.bool,
    businessTimezone: PropTypes.string,
    scrollYPosition: PropTypes.number,
    filtersToDisable: PropTypes.object,
    isArchivedCampaignFiltered: PropTypes.bool,
    isAutoArchivedActivitiesFiltered: PropTypes.bool,
    isActionFilterEnabled: PropTypes.bool,
    isRuleFilterEnabled: PropTypes.bool,
    isActionFilterUpdated: PropTypes.bool,
    allOtherActivityTags: PropTypes.array,
    activityTagsFilter: PropTypes.array,
    hasBasicPlanPermissions: PropTypes.bool,
    businessBundlePlan: PropTypes.string,
    showToast: PropTypes.bool,
    toastMessage: PropTypes.string,
    benefitsFetched: PropTypes.bool,
    registrationFormFieldsFetched: PropTypes.bool,
    itemGroupsFetched: PropTypes.bool,
    userAccessGroups: PropTypes.object
  };

  constructor(props) {
    super(props);
    const { campaigns } = this.props;
    this.state = {
      options: this.genOptions(campaigns)
    };
  }

  handleScroll = event => {
    this.props.actions.updateScrollPosition(event.currentTarget.scrollY);
  }

  scrollWindowToYPosition(yPos) {
    const debouncedMoveToPosition = debounce(() => window.scrollTo({
      top: yPos,
      behavior: 'smooth'
    }), 50);
    debouncedMoveToPosition();
  }

  componentWillMount() {
    if (!this.props.itemGroupsFetched) {
      this.getItemGroups();
    }
    if (!this.props.benefitsFetched) {
      this.getBenefits();
    }
    if (!this.props.registrationFormFieldsFetched) {
      this.getRegistrationFormFields();
    }

    // If the archived activity filter is enabled on page load.
    // i.e. the archive activities filter is selected and the current page is shown after redirection from another page
    // fetch the archived activities
    if (this.props.isAutoArchivedActivitiesFiltered) {
      this.props.actions.fetchActivitiesWithArchives();
    }
    else {
      // get the unarchived activities
      this.getCampaigns();
    }
    // set campaignsLoaded flag to false to indicate that we are about to call the getCampaigns action
    this.props.actions.setCampaignsLoaded(false);
    // set the loading state to true until getCampaigns returns with data
    this.props.actions.setLoadingState(true);
    if (!this.props.hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && !this.props.hasFullPermission(appConstants.FEATURE_ACTIONS_ON_BULK_OF_MEMBER)) {
      window.location.href = `${routeConstants.FORBIDDEN_ROUTE + window.location.search}`;
    }
  }

  componentDidMount() {
    const { hasFullPermission } = this.props;
    document.body.classList.add('businessCenterPage');

    // show goal wizard in case CC Full permission
    if (!hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && hasFullPermission(appConstants.FEATURE_ACTIONS_ON_BULK_OF_MEMBER)) {
      document.body.classList.add('noWizard');
    }

    window.addEventListener('scroll', this.handleScroll);
    if (this.props.scrollYPosition !== undefined) {
      this.scrollWindowToYPosition(this.props.scrollYPosition);
    }
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_BUSINESS_CENTER);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.campaigns !== this.props.campaigns) {
      this.setState({ options: this.genOptions(nextProps.campaigns) });
    }
    let isPageLoadedFirstTime = false;
    if (nextProps.campaignsLoaded && nextProps.campaignsLoaded !== this.props.campaignsLoaded) {
      isPageLoadedFirstTime = true;
      this.props.actions.setLoadingState(false);
    }
    let hasActionFilterAlreadyUpdated = false;
    const shouldFiltersBeUpdated = ((nextProps.isArchivedCampaignFiltered !== this.props.isArchivedCampaignFiltered)
      || (nextProps.isAutoArchivedActivitiesFiltered !== this.props.isAutoArchivedActivitiesFiltered)
      || (nextProps.campaignsLoaded && nextProps.campaignsLoaded !== this.props.campaignsLoaded)
    );

    if (shouldFiltersBeUpdated) {
      this.props.actions.updateTriggerFilterList(nextProps.triggersShowList, isPageLoadedFirstTime);
      this.props.actions.updateActionsFilterList(nextProps.actionFiltersShowList, isPageLoadedFirstTime);
      hasActionFilterAlreadyUpdated = true;
    }

    if (nextProps.isActionFilterUpdated && !hasActionFilterAlreadyUpdated) {
      if (!this.props.isRuleFilterEnabled) {
        this.props.actions.updateTriggerFilterList(nextProps.triggersShowList, isPageLoadedFirstTime);
      }
      this.props.actions.updateActionFilterStatus(false);
    }
  }

  componentDidUpdate(prevProps) {
    // reload on business change
    if (prevProps.locationId !== this.props.locationId) {
      this.getCampaigns();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('businessCenterPage');
    document.body.classList.remove('noWizard');
    window.removeEventListener('scroll', this.handleScroll);
  }

  getCampaigns = () => {
    this.props.actions.getCampaigns(this.props.locationId);
  };

  getBenefits = () => {
    this.props.actions.getBenefits();
  }

  getItemGroups = () => {
    this.props.actions.getItemGroups();
  }

  getRegistrationFormFields = () => {
    this.props.actions.getRegistrationFormFields();
  }

  sortSets = (sort) => {
    const { actions, campaigns } = this.props;
    actions.onSortUpdate(sort, campaigns);
  };

  handleOnBlur = () => {
    this.props.actions.defocusCampaign();
  };

  handleActivityMoveTo = (activity) => {
    const moveToCampaign = this.genOptions(this.props.campaigns)
      .filter((campaign) => campaign[constants.ID] !== activity.campaignKey);

    if (moveToCampaign.size < 1) {
      return null;
    }

    return this.setState({
      activity,
      options: moveToCampaign
    });
  };

  handleOnConfirmActivityMoveTo = (campaignId) => {
    this.props.actions.activityMoveToCampaign(this.state.activity, campaignId);
    this.setState({ activity: null });
  };

  genOptions = (campaigns) => campaigns
    .map((campaign) => ({ [constants.CAMPAIGN_NAME]: campaign.get(constants.CAMPAIGN_NAME), [constants.ID]: campaign.get(constants.ID), [constants.CAMPAIGN_STATUS]: campaign.get(constants.CAMPAIGN_STATUS) }));

  handleOnFilterUpdate = (groupKey, key, status, isOnlyClick) => {
    if (key === activityConstants.FILTERS_ACTIVITY_RULE && !status) {
      //We want to reset the trigger filters if we are disabling the rules
      this.props.actions.setRuleFilterMode(false);
    }
    else if (key === activityConstants.FILTERS_ACTIVITY_ONE_TIME && !status) {
      //We want to reset the trigger filters if we are disabling the rules
      this.props.actions.setActionFilterMode(false);
    }
    if (groupKey === constants.FILTERS_GROUP_NAME_CAMPAIGN && key === constants.FILTERS_IS_AUTO_ARCHIVE && status === true && !this.props.fetchedActivitiesWithArchives){
      this.props.actions.fetchActivitiesWithArchives();
    }
    this.props.actions.onFilterUpdate(groupKey, key, status, this.props.fetchedActivitiesWithArchives);
    tracker.onEvent(trackerConstants.EVENT_TYPE_CAMPAIGNS_FILTERS_CHANGED, { groupKey, key, status });
  };

  handleOnFilterTriggerUpdate = (groupKey, key, status, isOnlyClick) => {
    if (key !== activityConstants.RESET_ALL_FILTERS) {

      let triggersMap = this.props.triggers.get(constants.FILTERS_GROUP_NAME_ALL_TRIGGER);
      triggersMap = triggersMap.delete(key);
      const triggersValList = [...triggersMap.values()];
      //If any of the trigger filters is disabled or the incoming toggle is false
      if (triggersValList.includes(false) || status == false || isOnlyClick) {
        //We only want to show rules !
        this.props.actions.setRuleFilterMode(true);
      }
      else {
        //We want to show everything.
        this.props.actions.setRuleFilterMode(false);
      }
      this.props.actions.onTriggerFilterUpdate(groupKey, key, status);
      tracker.onEvent(trackerConstants.EVENT_TYPE_TRIGGER_FILTERS_CHANGED, {groupKey, key, status});
    }
    else {
      //Reset all filters if the key is reset filter !
      this.props.actions.setRuleFilterMode(false);
      // Set all toggles to true !
      this.props.actions.updateTriggerFilterList(this.props.triggersShowList, false);
    }
  };

  handleOnFilterActionUpdate = (groupKey, key, status, isOnlyClick) => {
    if (key !== activityConstants.RESET_ALL_FILTERS) {

      let actionsMap = this.props.actionFiltersList.get(constants.FILTERS_GROUP_NAME_ALL_ACTION);
      actionsMap = actionsMap.delete(key);
      const actionsValList = [...actionsMap.values()];
      //If any of the action filters is disabled or the incoming "Only" action click is false
      if (actionsValList.includes(false) || status == false || isOnlyClick) {
        //We  want to show rules and actions both !
        this.props.actions.setActionFilterMode(true);
      }
      else {
        //We want to show everything.
        this.props.actions.setActionFilterMode(false);
      }

      this.props.actions.onActionFilterUpdate(groupKey, key, status);
      tracker.onEvent(trackerConstants.EVENT_TYPE_ACTION_FILTERS_CHANGED, {groupKey, key, status});
    }
    else {
      //Reset all action filters if the key is reset filter !
      this.props.actions.setActionFilterMode(false);
      this.props.actions.updateActionsFilterList(this.props.actionFiltersShowList, false);
    }
    const isRuleFilterVisible = this.props.filters.get(constants.FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE).get(activityConstants.ACTIVITY_TYPE_RULE);
    if (isRuleFilterVisible) {
      this.props.actions.updateActionFilterStatus(true);
    }
  }

  handleOnFilterTagUpdate = (groupKey, key, status, isOnlyClick) => {
    this.props.actions.onTagFilterUpdate(groupKey, key, status);
    tracker.onEvent(trackerConstants.EVENT_TYPE_ACTION_FILTERS_CHANGED, {groupKey, key, status});
  }

  GetCampaignWithDefaultSort = (campaigns) => {
    let pinnedCampaigns = campaigns.filter((campaign) => campaign.get(constants.CAMPAIGN_PINED_DATE) !== null);
    const unpinnedCampaigns = campaigns.filter((campaign) => campaign.get(constants.CAMPAIGN_PINED_DATE) === null);
    if (pinnedCampaigns && pinnedCampaigns.size > 0) {
      pinnedCampaigns = pinnedCampaigns.sort((a, b) => new Date(b.get(constants.CAMPAIGN_PINED_DATE)) - new Date(a.get(constants.CAMPAIGN_PINED_DATE)));
    }
    return pinnedCampaigns.concat(unpinnedCampaigns);
  }

  getFullCampaignCenter = () => {
    const { hasFullPermission, filters, businessTimezone, getPermissionLevel } = this.props;
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, hasFullPermission, getPermissionLevel);
    const campaignsList = this.GetCampaignWithDefaultSort(this.props.campaigns);
    return (
      <div>
        <Helmet>
          <title>Campaign Center{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
          <meta name="description" content="Description of businessCenter" />
        </Helmet>
        {this.state.options.size > 0 &&
        <ActivityMoveTo
          open={!!this.state.activity}
          onClose={() => this.setState({ activity: null })}
          onConfirm={tracker.wrapButtonAction(this.handleOnConfirmActivityMoveTo, trackerConstants.BUTTON_TYPE_MOVE_ACTIVITY)}
          options={this.state.options}
          activityStatus={this.state.activity && calculateActivityViewStatus(this.state.activity)}
        />
        }

        {hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) ?
          <div className="goal-wizard-button-container">
            <Link
              to={{ pathname: `${routeConstants.GOALS_ROUTE}`, search: location.search }}
              data-automation-id="business-center.goal-wizard.button"
              onClick={() => tracker.onButtonClick(trackerConstants.BUTTON_TYPE_OPEN_GOALS)}
            >
              <Icon className="como-ic-magic-wand" />
              <span>{ this.props.intl.formatMessage({ id: 'business-center.goals' }) }</span>
            </Link>
          </div> : null
          }

        <SideNavBar sideNavBarItems={sideNavBarItems} />

        <div className="businessCenter">
          <BusinessCenterToolbar
            sort={this.props.sort}
            filters={this.props.filters}
            triggers={this.props.triggers}
            actions={this.props.actionFiltersList}
            onSortUpdate={this.sortSets}
            createCampaign={this.props.actions.createCampaign}
            onFilterUpdate={this.handleOnFilterUpdate}
            onFilterTriggerUpdate={this.handleOnFilterTriggerUpdate}
            onFilterActionUpdate={this.handleOnFilterActionUpdate}
            onFilterTagUpdate={this.handleOnFilterTagUpdate}
            isFiltered={this.props.isFiltered}
            filtersToDisable={this.props.filtersToDisable}
            allOtherActivityTags={this.props.allOtherActivityTags}
            activityTagsFilter={this.props.activityTagsFilter}
            hasFullPermission={this.props.hasFullPermission}
          />
          { campaignsList.size > 0 && campaignsList.map((campaign, index) => (
            <Campaign
              localeDateFormat={this.props.localeDateFormat}
              localeTimeFormat={this.props.localeTimeFormat}
              activityMoveTo={this.handleActivityMoveTo}
              campaign={campaign}
              updateActivity={this.props.actions.updateActivity}
              updateActivityPin={this.props.actions.updateActivityPin}
              createActivity={this.props.actions.createActivity}
              deleteActivity={this.props.actions.deleteActivity}
              deleteCampaign={this.props.actions.deleteCampaign}
              updateCampaign={this.props.actions.updateCampaign}
              focus={campaign.get(constants.ID) === this.props.campaignIdToFocus}
              goToActivity={this.props.actions.goToActivity}
              handleOnBlur={this.handleOnBlur}
              highlightedActivityId={this.props.highlightedActivityId}
              index={index}
              key={campaign.get(constants.ID)}
              locationId={this.props.locationId}
              onUpdateCampaign={this.props.actions.updateCampaign}
              deprecatedTriggers={this.props.deprecatedTriggers}
              activityActivateError={this.props.actions.activityActivateError}
              businessTimezone={businessTimezone}
              openActivityInNewTab={this.props.actions.openActivityInNewTab}
              showBasicPlan={this.props.showBasicPlan}
              hasBasicPlanPermissions={this.props.hasBasicPlanPermissions}
              businessBundlePlan={this.props.businessBundlePlan}
              openToast={this.props.actions.openToast}
              userAccessGroups={this.props.userAccessGroups}
            />
            ))}
        </div>
      </div>
    );
  }
  getBasicCampaignCenter = () => {
    const { hasFullPermission, filters, businessTimezone, getPermissionLevel } = this.props;
    const campaignsList = this.GetCampaignWithDefaultSort(this.props.campaigns);
    return (
      <div>
        <Helmet>
          <title>Campaign Center{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
          <meta name="description" content="Description of businessCenter" />
        </Helmet>
        {hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) ?
          <div className="goal-wizard-button-container" style={{ marginTop: '35px' }}>
          </div> : null
          }
        <BasicPage isBusinessCenter>
          <div className="businessCenter">
            <BusinessCenterToolbar
              sort={this.props.sort}
              filters={this.props.filters}
              triggers={this.props.triggers}
              actions={this.props.actionFiltersList}
              onSortUpdate={this.sortSets}
              createCampaign={this.props.actions.createCampaign}
              onFilterUpdate={this.handleOnFilterUpdate}
              onFilterTriggerUpdate={this.handleOnFilterTriggerUpdate}
              onFilterActionUpdate={this.handleOnFilterActionUpdate}
              onFilterTagUpdate={this.handleOnFilterTagUpdate}
              isFiltered={this.props.isFiltered}
              filtersToDisable={this.props.filtersToDisable}
              allOtherActivityTags={this.props.allOtherActivityTags}
              activityTagsFilter={this.props.activityTagsFilter}
              hasFullPermission={this.props.hasFullPermission}
              showBasicPlan={this.props.showBasicPlan}
            />
          { campaignsList.size > 0 && campaignsList.map((campaign, index) => (
            <Campaign
              localeDateFormat={this.props.localeDateFormat}
              localeTimeFormat={this.props.localeTimeFormat}
              activityMoveTo={this.handleActivityMoveTo}
              campaign={campaign}
              updateActivity={this.props.actions.updateActivity}
              updateActivityPin={this.props.actions.updateActivityPin}
              createActivity={this.props.actions.createActivity}
              deleteActivity={this.props.actions.deleteActivity}
              deleteCampaign={this.props.actions.deleteCampaign}
              updateCampaign={this.props.actions.updateCampaign}
              focus={campaign.get(constants.ID) === this.props.campaignIdToFocus}
              goToActivity={this.props.actions.goToActivity}
              handleOnBlur={this.handleOnBlur}
              highlightedActivityId={this.props.highlightedActivityId}
              index={index}
              key={campaign.get(constants.ID)}
              locationId={this.props.locationId}
              onUpdateCampaign={this.props.actions.updateCampaign}
              deprecatedTriggers={this.props.deprecatedTriggers}
              activityActivateError={this.props.actions.activityActivateError}
              businessTimezone={businessTimezone}
              openActivityInNewTab={this.props.actions.openActivityInNewTab}
              showBasicPlan={this.props.showBasicPlan}
              hasBasicPlanPermissions={this.props.hasBasicPlanPermissions}
              businessBundlePlan={this.props.businessBundlePlan}
              openToast={this.props.actions.openToast}
              userAccessGroups={this.props.userAccessGroups}
            />
            ))}
        </div>
          </BasicPage>
      </div>
    );
  }
  render() {
    return (
      <>
        {this.props.showBasicPlan ? this.getBasicCampaignCenter() : this.getFullCampaignCenter()}
        <Toast
          // eslint-disable-next-line react/prop-types
          intl={this.props.intl}
          isOpen={this.props.showToast}
          messageKey={this.props.toastMessage}
          onClose={() => this.props.actions.closeToast()}
          secondsToShow={3}
        />
      </>

    );
  }
}

const mapStateToProps = (state) => ({
  campaignIdToFocus: selectors.getFocusedCampaignId(state),
  scrollYPosition: selectors.getScrollYPosition(state),
  campaigns: selectors.getFilteredAndSortedCampaigns(state),
  campaignsLoaded: selectors.getCampaignsLoadedFlag(state),
  filters: selectors.getFilters(state),
  triggers: selectors.getTriggers(state),
  triggersShowList: selectors.getActiveTriggers(state),
  actionFiltersList: selectors.getActions(state),
  actionFiltersShowList: selectors.getActiveActions(state),
  highlightedActivityId: selectors.getHighlightedActivityId(state),
  isFiltered: selectors.isCampaignsFiltered(state),
  isArchivedCampaignFiltered: selectors.isArchivedCampaignFilterEnabled(state),
  isAutoArchivedActivitiesFiltered: selectors.isAutoArchivedFilterEnabled(state),
  isActionFilterEnabled: selectors.isActionFilterEnabled(state),
  isRuleFilterEnabled: selectors.isRuleFilterEnabled(state),
  locationId: appSelectors.getLocationId(state),
  sort: selectors.getOrder(state),
  businessDisplayName: appSelectors.getBusinessDisplayName(state),
  businessBundlePlan: appSelectors.getBusinessBundlePlan(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  deprecatedTriggers: schemaSelectors.getDeprecatedTriggers(state),
  fetchedActivitiesWithArchives: selectors.getFetchedAutoArchiveActivities(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  filtersToDisable: selectors.getFiltersToDisable(state),
  isActionFilterUpdated: selectors.isActionFilterUpdated(state),
  allOtherActivityTags: selectors.getAllActivityTags(state),
  activityTagsFilter: selectors.getActivityTagsFilter(state),
  hasBasicPlanPermissions: appSelectors.hasBasicPlanPermissions(state),
  showToast: selectors.getToastState(state),
  toastMessage: selectors.getToastMessage(state),
  benefitsFetched: benefitsSelectors.getBenefitsListFetchedFlag(state),
  itemGroupsFetched: itemGroupsSelectors.getItemGroupsListFetchedFlag(state),
  registrationFormFieldsFetched: formsSelectors.getRegistrationFormFieldsFetchedFlag(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state)
});


function mapDispatchToProps(dispatch) {
  const creators = {
    ...benefitsActions,
    ...itemGroupsActions,
    ...formsActions
  };
  return {
    actions: bindActionCreators(Object.assign({}, businessCenterActions, creators, { createActivity }, { setLoadingState }), dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga });
const withBenefitsSaga = injectSaga({ key: benefitConstants.BENEFITS, saga: benefitsSaga });
const withItemGroupsSaga = injectSaga({ key: itemGroupsConstants.ITEM_GROUPS, saga: itemGroupsSaga });
const withFormsSaga = injectSaga({ key: formsConstants.FORMS, saga: formsSaga });

export default compose(withItemGroupsSaga, withFormsSaga, withBenefitsSaga, withConnect, withSaga, injectIntl, withAuthorization)(BusinessCenter);

