import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import injectSaga from '../../utils/injectSaga';

import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';

import { setLoadingState } from '../App/app.actions';
import SideNavBar from '../SideNavBar/SideNavBar';
import PromoCodesPageToolbar from './views/PromoCodesPageToolbar';
import * as selectors from './promoCodes.selectors';
import * as promoCodesActions from './promoCodes.actions';
import PromoCodesTable from './views/PromoCodesTable';
import promoCodesSaga from './promoCodes.saga';
import businessCenterSaga from '../BusinessCenter/businessCenter.saga';
import * as businessCenterAction from '../BusinessCenter/businessCenter.actions';
import * as reducerConstants from '../../constants/reducer.constants';
import './promoCodes.styles.less';
import { getBusinessDisplayName } from '../App/selectors';
import { getSideNavbarSettings } from '../BusinessCenter/businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import AddActivitySegment from './views/AddActivitySegment';
import * as promoCodeConstants from './promoCodes.constants';
import PopupWrapper from '../../common/components/PopupWrapper/PopupWrapper';
import BulkCodesListModal from './views/BulkCodesListModal';
import * as appConstants from '../App/app.constants';
import * as appSelectors from '../App/selectors';


class PromoCodesContainer extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    businessCenterAction: PropTypes.object.isRequired,
    promoCodes: PropTypes.object.isRequired,
    promoCodesLoaded: PropTypes.bool.isRequired,
    filters: PropTypes.object,
    highlightedPromoCodeId: PropTypes.string,
    isFiltered: PropTypes.bool.isRequired,
    sort: PropTypes.string,
    searchTerm: PropTypes.string,
    intl: PropTypes.object,
    businessDisplayName: PropTypes.string,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    userAccessGroups: PropTypes.object,
  };

  componentWillMount() {
    if (!this.props.hasFullPermission(appConstants.FEATURE_NAME_PROMO_CODES)){
      this.props.actions.goToUpgradePage("promoCodes");
    }else {
      // get the promoCodes
      this.props.actions.getPromoCodes();
      this.props.actions.setLoadingState(true);
    }
  }

  componentDidMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_PROMO_CODES);
    document.body.classList.add('promoCodePage');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.promoCodesLoaded && !this.props.promoCodesLoaded) {
      this.props.actions.setLoadingState(false);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('promoCodePage');
  }

  render() {
    const { formatMessage } = this.props.intl;
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);

    let addActivitySegments = [
      {
        activityType: promoCodeConstants.PROMO_CODE_TYPE,
        segmentColor: 'oneTimeColor',
        segmentContent: formatMessage({ id: 'promoCode.segment.description.fix' }),
        segmentCreateLink: 'singleCode',
      },
      {
        activityType: promoCodeConstants.PROMO_CODE_GENERATED,
        segmentColor: 'ruleColor',
        segmentContent: formatMessage({ id: 'promoCode.segment.description.generated.new' }),
        segmentCreateLink: 'generated'
      }
      // Remove because not implemented
      // {
      //   activityType: promoCodeConstants.PROMO_CODE_IMPORTED,
      //   segmentColor: 'punchCardColor',
      //   segmentContent: formatMessage({ id: 'promoCode.segment.description.imported' }),
      //   segmentCreateLink: 'imported'
      // }
    ];

    const AddActivityTrigger = (
      <Button
        circular
        className="add-activity"
        data-automation-id={'promoCodes-page.create-button'}
      >
        <Icon className="como-ic-plus" />
        <span>{formatMessage({ id: 'promoCodes-page.add_promoCode' })}</span>
      </Button>
    );

    const addActivityContent = (
      <div>
        <Segment.Group>
          {
            addActivitySegments.map((activitySegment) => (
              <AddActivitySegment
                key={activitySegment.activityType}
                locationId={this.props.locationId}
                activityType={activitySegment.activityType}
                segmentColor={activitySegment.segmentColor}
                segmentContent={activitySegment.segmentContent}
                segmentCreateLink={activitySegment.segmentCreateLink}
                createActivity={tracker.wrapButtonAction(
                  () => this.props.actions.createPromoCode(activitySegment.activityType),
                  trackerConstants.BUTTON_TYPE_PROMO_CODE_CREATE,
                  { activityType: activitySegment.activityType })
                }
              />
            ))
          }
        </Segment.Group>
      </div>
    );

    return (
      <div className="promoCodes-page">
        <Helmet>
          <title>Promo Codes{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        <div className="promoCodes-container">
          <PromoCodesPageToolbar
            filters={this.props.filters}
            sort={this.props.sort}
            isFiltered={this.props.isFiltered}
            onFilterUpdate={this.props.actions.onFilterUpdate}
            onSortUpdate={this.props.actions.onSortUpdate}
            onSearch={this.props.actions.search}
            searchTerm={this.props.searchTerm}
          />
          <div className="promoCodes-list">

            <div className="promoCodes-list-sub-toolbar">
              <PopupWrapper
                automationId={'promoCode.create.popup'}
                className="no-padding no-border"
                on="click"
                position="bottom right"
                trigger={AddActivityTrigger}
              >
                {addActivityContent}
              </PopupWrapper>
            </div>
            <div className="promoCodes-list-content">
              <PromoCodesTable
                highlightedPromoCodeId={this.props.highlightedPromoCodeId}
                changeActivityStatus={this.props.actions.changePromoCodeStatus}
                onDeletePromoCode={this.props.actions.deletePromoCode}
                promoCodes={this.props.promoCodes}
                goToPromoCode={this.props.actions.goToPromoCode}
                extendPromoCodeStatus={this.props.actions.extendPromoCodeStatus}
                openPromoCodeInNewTab={this.props.businessCenterAction.openActivityInNewTab}
                userAccessGroups={this.props.userAccessGroups}
                hasFullPermission={this.props.hasFullPermission}
              />
              <BulkCodesListModal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  promoCodes: selectors.getFilteredAndSortedPromoCodes(state),
  promoCodesLoaded: selectors.getPromoCodesLoadedFlag(state),
  filters: selectors.getFilters(state),
  highlightedPromoCodeId: selectors.getHighlightedPromoCodeId(state),
  isFiltered: selectors.isPromoCodesFiltered(state),
  sort: selectors.getOrder(state),
  searchTerm: selectors.getSearchTerm(state),
  businessDisplayName: getBusinessDisplayName(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...promoCodesActions, setLoadingState }, dispatch),
    businessCenterAction: bindActionCreators(businessCenterAction, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.PROMO_CODES_BRANCH, saga: promoCodesSaga });
const withBusinessCenterSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessCenterSaga });

export default compose(
  withConnect,
  withSaga,
  withBusinessCenterSaga,
  withAuthorization,
  injectIntl
)(PromoCodesContainer);
