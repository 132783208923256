import { Grid, Icon, Radio } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

class FilterUserAttributes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.drawConditions = this.drawConditions.bind(this);
  }

  drawConditions() {
    return this.props.membershipGlobalConditions.map(this.props.prop5);
  }

  handleUpdateConditionRelation(relation) {
    this.props.onUpdateConditionRelation(relation);
  }

  render() {
    const { formatMessage } = this.props.intl;

    const haveConditions = this.props.membersFilter && (this.props.showAddConditionButton || this.props.membershipGlobalConditions.count() > 1);

    return (
      <Grid className="member-fields-conditions gray-panel">
        {this.props.membersFilter &&
          <p className="member-conditions-subtitle">
            <i className="como-svg-icon-memberAttributes" />
            {formatMessage({ id: 'activity.global.conditions.members.attribute' })}
            <span className="hint-tip-label">{formatMessage({ id: 'global.conditions.specific.members.attributes.hint' })}</span>
          </p>
        }
        <Grid.Row>
          {!this.props.membersFilter &&
            <div className="left-sided-label-text">
              {formatMessage({ id: 'activity.globalConditions.memberWho' })}
            </div>
          }
          <div className={classNames('right-side-content global-conditions-list', !this.props.membersFilter && 'tw-mt-4')}>
            <div
              className={classNames(haveConditions && 'tw-relative tw-ring-1 tw-rounded-md -tw-mt-4 -tw-ml-4 tw-mr-8 tw-pt-8 tw-pb-6 tw-px-4')}
            >
              {haveConditions && (
                <div className={'gray-panel tw-absolute -tw-top-3 tw-left-5 tw-px-2'}>
                  <Radio
                    className={'!tw-p-0 tw-mb-0 tw-mr-3 tw-uppercase'}
                    label={formatMessage({ id: 'activity.conditionsRelation.and' })}
                    value={'and'}
                    disabled={this.props.filtersInProgress}
                    onChange={(e, target) => this.handleUpdateConditionRelation(target.value)}
                    checked={this.props.conditionsRelation === 'and'}
                  />
                  <Radio
                    className={'!tw-p-0 tw-mb-0 tw-uppercase'}
                    label={formatMessage({ id: 'activity.conditionsRelation.or' })}
                    value={'or'}
                    disabled={this.props.filtersInProgress || this.props.userHasAccessGroup}
                    onChange={(e, target) => this.handleUpdateConditionRelation(target.value)}
                    checked={this.props.conditionsRelation === 'or'}
                  />
                </div>
              )}
              {this.drawConditions()}
              {this.props.showAddConditionButton && (
                <a
                  role="button"
                  className="condition-link add-condition global-condition-link"
                  onClick={(e) => !this.props.filtersInProgress ? this.props.onClick(e) : null}
                  id={'add-condition'}
                  data-automation-id="globalConditions.members.add-global-condition"
                >
                  <Icon className="como-ic-plus-in-circle" />
                  {formatMessage({ id: 'activity.globalConditions.memberStatus.addMemberField' })}
                </a>
              )}
            </div>
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

FilterUserAttributes.propTypes = {
  membersFilter: PropTypes.bool,
  intl: PropTypes.object,
  membershipGlobalConditions: PropTypes.any,
  filtersInProgress: PropTypes.bool,
  prop5: PropTypes.func,
  showAddConditionButton: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdateConditionRelation: PropTypes.func,
  conditionsRelation: PropTypes.string,
  userHasAccessGroup: PropTypes.bool,
};

export default injectIntl(FilterUserAttributes);
