import { createSelector } from 'reselect';
import * as constants from './app.constants';
import * as mailTemplateConstants from '../MailTemplates/mailTemplates.constants';
import * as routeConstants from '../../../app/constants/route.contants';

const selectRoute = (state) => state.get('route');

function isRtlImpl(locale, languages) {
  if (locale && languages) {
    const selectedLanguage = languages.find((language) => language.get(constants.LANGUAGE_KEY) === locale);
    if (selectedLanguage) {
      return selectedLanguage.get(constants.LANGUAGE_DIRECTION) === constants.RTL_LANGUAGE_DIRECTION;
    }
  }
  return null;
}

function getLocaleNameImpl(locale, languages) {
  const localeLanguage = languages && languages.size ? languages.find((lang) => lang.get('value') === locale) : null;
  return localeLanguage ? localeLanguage.get('text') : '';
}

function getDefaultPageImpl(features, businessPlan, user) {
  if (features) {
    const allowedFeatures = features.filter((feature) => feature.get(constants.FEATURE_LEVEL) === constants.FEATURE_LEVEL_FULL);
    if (businessPlan === constants.TRIAL) {
      if (user.get(constants.LOGIN_COUNTER) <= constants.MAX_LOGIN_COUNT_FOR_TUTORIAL) {
        return routeConstants.WELCOME_ROUTE;
      }
      return routeConstants.BASIC_ROUTE;
    }
    if (allowedFeatures.find((feature) => feature.get(constants.FEATURE_NAME) === constants.FEATURE_NAME_VIEW_DATA)) {
      return routeConstants.DATA_BI_ROUTE;
    }
    if (allowedFeatures.find((feature) => feature.get(constants.FEATURE_NAME) === constants.FEATURE_NAME_DATA_REPORTS)) {
      return routeConstants.DATA_BI_LOOKER_ROUTE;
    }
    if (allowedFeatures.find((feature) => feature.get(constants.FEATURE_NAME) === constants.FEATURE_NAME_CAMPAIGN_CENTER)) {
      return routeConstants.BUSINESS_CENTER_ROUTE;
    }
    if (allowedFeatures.find((feature) => feature.get(constants.FEATURE_NAME) === constants.FEATURE_NAME_BUSINESS_SETTINGS)) {
      return routeConstants.BUSINESS_PROFILE_REGISTRATION;
    }
  }
  return routeConstants.WELCOME_ROUTE;
}

function getBusinessTagsNoRfm(businessTags) {
  if (businessTags) {
    const regexPattern = /^\*.+\|.+\|.+\*$/gi;
    return businessTags.filter((tag) => tag.match(regexPattern) === null);
  }
  return null;
}

export const getLocale = (state) => state.getIn([constants.APP, constants.USER_LANG]) ? constants.LANGUAGE_CODES[state.getIn([constants.APP, constants.USER_LANG])] : state.getIn([constants.APP, constants.LOCALE]);
export const getLanguages = (state) => state.getIn([constants.APP, constants.LANGUAGES]);
export const getDateFormats = (state) => state.getIn([constants.APP, constants.DATE_FORMATS]);
export const getLocationId = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.LOCATION_ID]);
export const getFranchisesField = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.FRANCHISES_FIELD]);
export const getUserAccessGroups = (state) => state.getIn([constants.APP, constants.USER ,constants.ACCESS_GROUPS]);
export const getBusinessDisplayName = (state) => getIsDemoUser(state) ? state.getIn([constants.APP, constants.BUSINESS, constants.LOCATION_ID]) : state.getIn([constants.APP, constants.BUSINESS, constants.BUSINESS_DISPLAY_NAME]);
export const getBusinessBundlePlan = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.BUNDLE_PLAN]);
export const getIsComoUser = (state) => state.getIn([constants.APP, constants.IS_COMO_USER]);
export const hasBasicPlanPermissions = createSelector([getBusinessBundlePlan, getIsComoUser], (bundlePlan, isComoUser) => bundlePlan === constants.TRIAL && !isComoUser);

export const getBrowserLocale = (state) => state.getIn([constants.APP, constants.BROWSER_LOCALE_SETTINGS, constants.BROWSER_LOCALE]);
export const getBusinessWallet = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.BUSINESS_WALLET]);
export const getDecimalPrecision = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.DECIMAL_AMOUNT]);
export const getBrowserLocaleSettings = (state) => state.getIn([constants.APP, constants.BROWSER_LOCALE_SETTINGS]);
export const getBrowserLocaleDateFormat = (state) => state.getIn([constants.APP, constants.BROWSER_LOCALE_SETTINGS, constants.DATE_FORMAT]);
export const getBrowserLocaleTimeFormat = (state) => state.getIn([constants.APP, constants.BROWSER_LOCALE_SETTINGS, constants.TIME_FORMAT]);
export const getFeatures = (state) => state.getIn([constants.APP, constants.FEATURES]);
export const getUser = (state) => state.getIn([constants.APP, constants.USER]);
export const getDefaultPage = createSelector([getFeatures, getBusinessBundlePlan, getUser], getDefaultPageImpl);
export const getTranslationSelector = (textKey) => (state) => state.getIn([constants.APP, constants.TRANSLATIONS])[textKey];
export const getSsrTranslationSelector = (state, textKey) => state.getIn([constants.APP, constants.SSR_TRANSLATIONS])[textKey];
export const getTranslations = (state) => state.getIn([constants.APP, constants.TRANSLATIONS]);
export const getSsrTranslations = (state) => state.getIn([constants.APP, constants.SSR_TRANSLATIONS]);
export const getLegalSettings = (state) => state.getIn([constants.APP, constants.LEGAL_SETTINGS]);
export const makeSelectLocation = () => createSelector(selectRoute, (routeState) => routeState.get('location').toJS());
export const getLiveHttpRequestCount = (state) => state.getIn([constants.APP, constants.LIVE_HTTP_REQUESTS_COUNT]);
export const getBusiness = (state) => state.getIn([constants.APP, constants.BUSINESS]);
export const getBusinessCsms = (state) => state.getIn([constants.APP, constants.BUSINESS_CSMs]);
export const getBusinessSupportEmails = (state) => state.getIn([constants.APP, constants.BUSINESS_SUPPORT_EMAILS]);
export const isFranchises = (state) => state.getIn([constants.APP, constants.FRANCHISES_DATA, constants.FRANCHISES, constants.USER_BUSINESS_LOCATIONS, constants.IS_FRANCHISES]);
export const monthlySmsMessagesLimitation = (state) => state.getIn([constants.APP, constants.MONTHLY_SMS_MESSAGES_LIMITATION]);
export const monthlyEmailMessagesLimitation = (state) => state.getIn([constants.APP, constants.MONTHLY_EMAIL_MESSAGES_LIMITATION]);
export const getRegistrationFormFields = (state) => state.getIn([constants.APP, constants.REGISTRATION_FIELDS]);
export const getNewMember = (state) => state.getIn([constants.APP, constants.NEW_MEMBER]);
export const getVerificationCode = (state) => state.getIn([constants.APP, constants.VERIFICATION_CODE]);
export const getChangePassword = (state) => state.getIn([constants.APP, constants.CHANGE_USER_PASSWORD]);
export const getDomains = (state) => state.getIn([constants.APP, constants.DOMAINS]);
export const getBusinessTimeZone = (state) => state.getIn([constants.APP, constants.BUSINESS, constants.TIME_ZONE]);
export const getBusinesses = (state) => state.getIn([constants.APP, constants.BUSINESSES]);
export const getHub1Path = (state) => state.getIn([constants.APP, constants.HUB_1_PATH]);
export const getInitialAppDataLoadedFlag = (state) => state.getIn([constants.APP, constants.INITIAL_APP_DATA_LOADED]);
export const getIsAppLoadingFlag = (state) => state.getIn([constants.APP, constants.IS_LOADING]);
export const getIsDemoUser = (state) => state.getIn([constants.APP, constants.USER, constants.IS_DEMO_USER]);
export const getUserEmail = (state) => state.getIn([constants.APP, constants.USER_EMAIL]);
export const getLocationIsoCurrencySymbol = (state) => state.getIn([constants.APP, constants.LOCATION_REGION_INFO, constants.ISO_CURRENCY_SYMBOL]);
export const getCountryCode = (state) => state.getIn([constants.APP, constants.LOCATION_REGION_INFO, constants.COUNTRY_CODE]);
export const getLocationCurrencySymbol = (state) => state.getIn([constants.APP, constants.LOCATION_REGION_INFO, constants.CURRENCY_SYMBOL]);
export const getDefaultSMSConsentActionContent = (state) => state.getIn([constants.APP, constants.DEFAULT_CONSENT_CONTENT, constants.ACTION_SMS_CONTENT]);
export const getDefaultEmailConsentActionContent = (state) => state.getIn([constants.APP, constants.DEFAULT_CONSENT_CONTENT, constants.ACTION_EMAIL_CONTENT]);
export const getDefaultEmailConsentActionSubject = (state) => state.getIn([constants.APP, constants.DEFAULT_CONSENT_CONTENT, constants.ACTION_EMAIL_SUBJECT]);
export const isRtl = createSelector([getLocale, getLanguages], isRtlImpl);
export const getLocaleName = createSelector([getLocale, getLanguages], getLocaleNameImpl);
export const getLocations = (state) => state.getIn([constants.APP, constants.LOCATIONS]);
export const getLocationsGroups = (state) => state.getIn([constants.APP, constants.LOCATIONS_GROUPS]);
const mailTemplatesSelector = (state) => state.get(mailTemplateConstants.MAIL_TEMPLATES);
export const getMailTemplatesMap = createSelector(mailTemplatesSelector,
  (templates) => templates && templates.get(mailTemplateConstants.MAIL_TEMPLATES_MAP));
export const getMailTemplatesLoadingFlag = createSelector(mailTemplatesSelector,
  (templates) => templates && templates.get(mailTemplateConstants.MAIL_TEMPLATES_LOADING));
export const getMailSenderNameLoadingFlag = createSelector(mailTemplatesSelector,
  (templates) => templates && templates.get(mailTemplateConstants.MAIL_SENDER_NAME_LOADING));
export const getMailSenderName = createSelector(mailTemplatesSelector,
  (templates) => templates && templates.get(mailTemplateConstants.MAIL_SENDER_NAME));
export const getCustomerMembershipKey = (state) => state.getIn([constants.APP, constants.CUSTOMER, constants.MEMBERSHIP_KEY]);
export const getCustomerDataLoading = (state) => state.getIn([constants.APP, constants.CUSTOMER, constants.CUSTOMER_DATA_LOCADING]);
export const getDashboardUrl = (state) => state.getIn([constants.APP, constants.DASHBOARD_URL]);
export const getDashboardToken = (state) => state.getIn([constants.APP, constants.DASHBOARD_TOKEN]);
export const getGoogleAnalyticsCheckCode = (state) => state.getIn([constants.APP, constants.GOOGLE_ANALYTICS_CHECK_CODE]);
export const getGoogleAnalyticsTokenDomain = (state) => state.getIn([constants.APP, constants.GOOGLE_ANALYTICS_TOKEN_DOMAIN]);
export const getWebpropertyId = (state) => state.getIn([constants.APP, constants.WEBPROPERTY_ID]);
export const getReportCode = (state) => state.getIn([constants.APP, constants.REPORT_CODE]);
export const getLookerGatewayDomain = (state) => state.getIn([constants.APP, constants.LOOKER_GATEWAY_DOMAIN]);
export const getCancel3rdPartyCodeStatus = (state) => state.getIn([constants.APP, constants.CANCEL_3RD_PARTY_CODE_STATUS]);
export const getCurrentSMSOperationalProvider = (state) => state.getIn([constants.APP, constants.CURRENT_SMS_OPERATIONAL_PROVIDER]);
export const getCurrentSMSMarketingProvider = (state) => state.getIn([constants.APP, constants.CURRENT_SMS_MARKETING_PROVIDER]);
export const getUnsubscribeMessage = (state) => state.getIn([constants.APP, constants.UNSUBSCRIBE_MESSAGE]);
export const getBusinessDateTime = (state) => state.getIn([constants.APP, constants.BUSINESS_DATE_TIME]);
export const getBusinessTags = (state) => state.getIn([constants.APP, constants.BUSINESS_TAGS, constants.TAGS]);
export const getBusinessTagsWithoutRfm = createSelector([getBusinessTags], getBusinessTagsNoRfm);
export const getLastTagsRetrievalTime = (state) => state.getIn([constants.APP, constants.BUSINESS_TAGS, constants.LAST_TAGS_RETRIEVAL_TIME]);
export const getUserTotpOtpPath = (state) => state.getIn([constants.APP, constants.USER_TOTP_OTP_PATH]);
export const getComoAcademyUrl = (state) => state.getIn([constants.APP, constants.EXTERNAL_SERVICES_URLS, constants.COMO_ACADEMY_URL]);
export const getApplicationDataErrorCode = (state) => state.getIn([constants.APP, constants.GET_APPLICATION_DATA_ERROR_CODE]);
