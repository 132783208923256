import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Table, Icon } from 'semantic-ui-react';
import { Ref } from '@stardust-ui/react-component-ref';
import { injectIntl } from 'react-intl';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import ActionView from '../../Activity/views/ActionView';
import PromoCodeContextMenu from './PromoCodeContextMenu';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as activityConstants from '../../Activity/activity.constants';
import * as activitySelectorsUtils from '../../Activity/activity.selectors.utils';
import PromoCodeHistoryLog from './PromoCodeHistoryLog';
import PromoCodeStats from './PromoCodeStats';
import PromoCodeType from './PromoCodeType';
import { typesMapping, calculateCodesTotal } from '../utils/promoCodes.utils';
import PromoCodeBulkListItem from './PromoCodeBulkListItem';
import * as appConstants from '../../App/app.constants';

class PromoCodeRow extends React.PureComponent {

  static propTypes = {
    changeActivityStatus: PropTypes.func.isRequired,
    onDeletePromoCode: PropTypes.func.isRequired,
    goToPromoCode: PropTypes.func.isRequired,
    highlightedPromoCodeId: PropTypes.string,
    promoCode: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    openPromoCodeInNewTab: PropTypes.func.isRequired,
    extendPromoCodeStatus: PropTypes.func.isRequired,
    hasFullPermission: PropTypes.func,
  };

  state = {
    isMouseEnter: false,
    isDetailsModalOpen: false,
    isAnalysisModalOpen: false,
    isDeactivateConfirmationModalOpen: false,
    isDetailsOpen: false,
    hover: false,
    detailsHovered: false
  };

  ref =  React.createRef();

  handleOnMouseEnter = () => {
    this.setState({
      isMouseEnter: true
    });
  };
  handleOnMouseLeave = () => {
    this.setState({
      isMouseEnter: false
    });
  };
  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };
  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };

  handleAnalysisModalOpen = () => {
    this.setState({
      isAnalysisModalOpen: true
    });
  };
  handleAnalysisModalClose = () => {
    this.setState({
      isAnalysisModalOpen: false
    });
  };

  handleRef = (node) => {
    const { highlightedPromoCodeId, promoCode } = this.props;
    if (highlightedPromoCodeId && highlightedPromoCodeId === promoCode.get(activityConstants.HUB_ID)) {
      window.scrollTo({
        top: node ? node.getBoundingClientRect().top : 0,
        behavior: 'smooth'
      });
    }
  };

  handleExpandClick = (e) => {
    e.stopPropagation();
    this.toggleDetails();
  };

  toggleDetails = () => {
    this.setState({ isDetailsOpen: !this.state.isDetailsOpen });
  };

  goToPromo = () => {
    return tracker.wrapButtonAction(() =>
        this.props.goToPromoCode(
          this.props.promoCode.get(activityConstants.HUB_ID),
          this.props.promoCode.get(activityConstants.PROMO_CODE_BULKS_TYPE)
        ),
      trackerConstants.BUTTON_TYPE_PROMO_CODE_LOADED,
      {
        [trackerConstants.ACTIVITY_HUB_ID]: this.props.promoCode.get(activityConstants.HUB_ID),
        [trackerConstants.ACTIVITY_SERVER_ID]: this.props.promoCode.get(activityConstants.SERVER_ID),
        [trackerConstants.ACTIVITY_STATUS]: this.props.promoCode.get(activityConstants.STATUS),
        [trackerConstants.ACTIVITY_NAME]: this.props.promoCode.get(activityConstants.NAME),
        [trackerConstants.ACTIVITY_TYPE]: this.props.promoCode.get(activityConstants.ACTIVITY_TYPE_PROMO_CODE),
      })
  }

  render() {
    const { promoCode, rowIndex, highlightedPromoCodeId, formatMessage, openPromoCodeInNewTab } = this.props;
    const { isMouseEnter, isDetailsOpen, isDetailsModalOpen, detailsHovered } = this.state;
    const promoCodeName = promoCode.get(activityConstants.NAME);
    const promoCodeType = promoCode.get(activityConstants.TYPE);
    const bulkType = promoCode.get(activityConstants.PROMO_CODE_BULKS_TYPE);
    const isGenerated = bulkType === 'generated';
    const viewStatus = activitySelectorsUtils.calculateActivityViewStatus(promoCode);
    const isAccessGroupEnabled = this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS);
    const rowClass = classNames('promoCode-row', {
      'disable-pointer-events': viewStatus === activityConstants.ACTIVITY_STATUS_INVALID,
      'clickable': viewStatus !== activityConstants.ACTIVITY_STATUS_INVALID,
      'highlighted': highlightedPromoCodeId && promoCode.get(activityConstants.HUB_ID) === highlightedPromoCodeId,
      'removeBottomBorder': isDetailsOpen,
      'detailsHovered': detailsHovered
    });

    const bulks = promoCode.get('codeBulks');
    const codesTotal = calculateCodesTotal(bulks);

    const statusIconOverrideFactory = (status) => ({
      'como-ic-deactivate': status === activityConstants.ACTIVITY_STATUS_PAUSED,
      'como-ic-expired-1': status === activityConstants.ACTIVITY_STATUS_STOPPED
    });
    const rowHistoryLog = promoCode.get(activityConstants.HISTORY_LOG);
    const detailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: promoCode.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: promoCode.get(activityConstants.SERVER_ID),
      [trackerConstants.ACTIVITY_STATUS]: promoCode.get(activityConstants.STATUS),
      [trackerConstants.ACTIVITY_NAME]: promoCode.get(activityConstants.NAME),
      [trackerConstants.ACTIVITY_TYPE]: promoCode.get(activityConstants.ACTIVITY_TYPE_PROMO_CODE),
    };
    return (
      <React.Fragment>
        <Ref innerRef={this.handleRef}>
          <Table.Row
            data-automation-id={`promoCodes-page.campaign.row.${rowIndex}`}
            className={rowClass}
            onMouseEnter={this.handleOnMouseEnter}
            onMouseLeave={this.handleOnMouseLeave}
          >
            <Table.Cell onClick={this.goToPromo()} className={typesMapping[bulkType] ? typesMapping[bulkType].name : 'fix'} title={promoCodeName}>
              <PromoCodeType bulkType={bulkType} />
            </Table.Cell>
            <PromoCodeHistoryLog
              name={promoCode.get(activityConstants.NAME)}
              open={isDetailsModalOpen}
              onConfirm={this.handleDetailsModalClose}
              historyLog={rowHistoryLog}
            />
            <PromoCodeStats
              promoCode={promoCode}
              onConfirm={this.handleAnalysisModalClose}
              open={this.state.isAnalysisModalOpen}
            />
            <Table.Cell onClick={this.goToPromo()} className="listcontent" title={promoCodeName}>{promoCodeName}</Table.Cell>
            <Table.Cell onClick={this.goToPromo()} className="listcontent">
              <ActionView actionsList={activitySelectorsUtils.reduceActionsForSummary(promoCode)} />
            </Table.Cell>
            <Table.Cell onClick={this.goToPromo()} className="listcontent">
              {isGenerated ? new Intl.NumberFormat().format(codesTotal) : ''}
            </Table.Cell>
            <Table.Cell onClick={this.goToPromo()}>
              <ActivityStatus
                status={viewStatus}
                messagesNamespace="promoCode"
                statusIconOverrideFactory={statusIconOverrideFactory}
              />
            </Table.Cell>
            <Table.Cell onClick={this.handleExpandClick}>
              {isGenerated && <Icon
                className="expand-icon no-padding no-margin"
                name={`angle ${this.state.isDetailsOpen ? 'up' : 'down'}`}
              />}
            </Table.Cell>
            <Table.Cell className="holding-absolute-elem">
              { isAccessGroupEnabled && !promoCode.get(activityConstants.ACCESS_GROUP_IDS) &&
                <Icon className="como-svg-access-group-hq-badge" />
              }
            </Table.Cell>
            <Table.Cell className="no-padding">
              <PromoCodeContextMenu
                detailsForTracker={detailsForTracker}
                // hidden={!isMouseEnter}
                onChangeActivityStatus={this.props.changeActivityStatus}
                onDetailsOpen={this.handleDetailsModalOpen}
                onDeletePromoCode={this.props.onDeletePromoCode}
                onStatsOpen={this.handleAnalysisModalOpen}
                promoCode={promoCode}
                promoCodeViewStatus={viewStatus}
                onOpenInNewTab={
                  tracker.wrapButtonAction(()=> openPromoCodeInNewTab(promoCode), trackerConstants.BUTTON_TYPE_OPEN_IN_NEW_TAB, detailsForTracker)
                }
                automationId={`promoCodes-page.promoCode.${rowIndex}.promoCode-context-menu`}
                onExtendPromoCodeStatus={this.props.extendPromoCodeStatus}
              />
            </Table.Cell>
          </Table.Row>
        </Ref>
        {(isGenerated && isDetailsOpen) &&
        <Table.Row
          className="promoCode-details-row"
          onMouseEnter={() => {
            this.setState({detailsHovered: true});
          }}
          onMouseLeave={() => {
            this.setState({detailsHovered: false});
          }}
        >
          <Table.Cell className={typesMapping[bulkType].name} />
          <Table.Cell className="no-padding" colSpan="6">
            <div className="promo-code-details">
              <ul className="code-bulks-list">
                {bulks.map((item, index) => (<PromoCodeBulkListItem
                  key={item.get('key')}
                  bulk={item}
                  serverId={promoCode.get(activityConstants.SERVER_ID)}
                  index={index}
                  status={item.get('status')}
                />))
                }
              </ul>
            </div>
          </Table.Cell>
        </Table.Row>}
      </React.Fragment>
    );
  }
}

export default injectIntl(PromoCodeRow);
