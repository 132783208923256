import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { List } from 'immutable';
import classNames from 'classnames';
import * as constants from '../../activity.constants';
import PopupWithTabsContentList
  from '../../../../common/components/PopupWrapper/PopupWithTabsContentList/PopupWithTabsContentList';
import withAuthorization from '../../../../common/helpers/authorization';
import ValidationErrorMessage from '../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';
import * as activitySelector from '../../../Activity/activity.selectors';
import * as appConstants from '../../../App/app.constants';

class CaseConditionKey extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    conditionKey: PropTypes.string,
    conditionsList: PropTypes.object,
    intl: PropTypes.object,
    onConditionUpdate: PropTypes.func,
    selectedConditionSchema: PropTypes.object,
    error: PropTypes.string,
    getRestrictions: PropTypes.func,
    features: PropTypes.object,
    disabled: PropTypes.bool,
    currentConditionType: PropTypes.string,
    businessBundlePlan: PropTypes.string.isRequired
  };

  getSelectedTabIndex = (conditionKeyOptions) => {
    const { selectedConditionSchema } = this.props;
    const schemaConditionGroup = selectedConditionSchema && selectedConditionSchema.get(constants.SCHEMA_CONDITION_GROUP);
    const selectedTab = conditionKeyOptions.find((opt) => opt.name.key === schemaConditionGroup);
    const defaultTabIndex = selectedConditionSchema && selectedConditionSchema.size ? conditionKeyOptions.length - 1 : 0;
    return selectedTab ? selectedTab.index : defaultTabIndex;
  };

  getConditionKeyOptions = () => {
    const { conditionsList } = this.props;

    const caseConditionKeyTabs = [
      constants.CASES_CONDITION_GROUP_MEMBERSHIP,
      constants.CASES_CONDITION_GROUP_DATES_AND_TIMES,
      constants.CASES_CONDITION_GROUP_GENERAL,
    ];

    return caseConditionKeyTabs.reduce((optionsArray, tab, index) => {
      const tabConditions = conditionsList.filter(
        (condition) => tab === constants.CASES_CONDITION_GROUP_GENERAL ?
          !condition.get(constants.SCHEMA_CONDITION_GROUP) :
          condition.get(constants.SCHEMA_CONDITION_GROUP) === tab);
      const option = {
        index,
        name: { key: tab, text: this.props.intl.formatMessage({ id: `condition.split.cases.tab.${tab}` }) },
        values: this.genPopupWithTabsData(tabConditions, (tab === constants.CASES_CONDITION_GROUP_MEMBERSHIP
          && (this.props.currentConditionType === constants.NON_MEMBERS || this.props.currentConditionType === constants.ALL_COSTUMERS)))
      };
      if (!tabConditions.isEmpty()) {
        optionsArray.push(option);
      }
      return optionsArray;
    }, []);
  };

  getSelectedConditionText = (conditionKeyOptions) => {
    const { conditionKey } = this.props;
    if (!conditionKey) {
      return { text: this.props.intl.formatMessage({ id: 'activity.conditions.key.placeholder' }), className: '' };
    }
    const allConditions = conditionKeyOptions.reduce((accumulator, tab) => accumulator.concat(tab.values), List());
    const conditionKeyIndex = allConditions.findIndex((condition) =>
      condition ? condition[constants.KEY] === conditionKey : false);
    if (conditionKeyIndex === -1) {
      // Key not found in the conditionKeyOptions, show it as a human-readable error
      return {
        text: this.props.intl.formatMessage({ id: 'activity.conditions.key.deleted' }, { attribute: this.props.intl.formatMessage({ id: `activity.cases.summary.condition.${conditionKey}` }) }),
        className: 'error'
      };
    }
    return { text: allConditions.get(conditionKeyIndex).text, className: '' };
  };

  genPopupWithTabsData = (data, disabled = false, prefix = constants.CONDITION) => {
    const { formatMessage } = this.props.intl;
    return data.map((item) => {
      const key = item.get(constants.GLOBAL_CONDITION_KEY);
      const text = item.get(constants.TEXT);
      const features = item.get(constants.FEATURES);
      if (text) {
        if (disabled) {
          return { key,
            text,
            disable: {
              disabled: true,
              message: formatMessage({ id: 'activity.conditions.this.option.is.unavailable.for.non.member' }),
              icon: 'como-ic-unavailable como-ic-unavailable-tooltip-trigger'
            }
          };
        }

        return { key, text };
      }
      if (!key) {
        return null;
      }

      const value = { key, text: formatMessage({ id: `${prefix}.${key}` }), features };

      if (disabled) {
        value.disable = {
          disabled: true,
          message: formatMessage({ id: 'activity.conditions.this.option.is.unavailable.for.non.member' }),
          icon: 'como-ic-unavailable como-ic-unavailable-tooltip-trigger'
        };
      } else if (features) {
        const isAddOnFeature = features.some((feature) => {
          const featureName = feature.get(constants.FEATURE_NAME);
          const featureBySchema = this.props.features.find((f) => f.get(constants.FEATURE_NAME) === featureName);
          return featureBySchema ? featureBySchema.get(constants.FEATURE_IS_ADD_ON) : false;
        });
        value.icon = isAddOnFeature ? 'como-ic-add-on blue' : '';
        const restrictions = this.props.getRestrictions(features, this.props.businessBundlePlan);
        const isPosRestricted = restrictions && restrictions.get(constants.RESTRICTED_BY_POS_FEATURES);
        const isAddOnRestricted = !isPosRestricted && restrictions && restrictions.get(constants.RESTRICTED_BY_ADD_ON_FEATURES);
        if (restrictions && (restrictions.get(constants.HIDE_BY_BUSINESS_PLAN))){
          value.disable = {
            hide: true,
          };
        }else{
          if (isPosRestricted) {
            value.disable = {
              disabled: true,
              message: formatMessage({ id: 'activity.conditions.this.option.is.unavailable' }),
              icon: 'como-ic-unavailable como-ic-unavailable-tooltip-trigger'
            };
          } else if (isAddOnRestricted) {
            value.disable = {
              disabled: true,
              message: this.props.businessBundlePlan === appConstants.REVEL ? formatMessage({ id: 'revel.activity.triggers.add-on.is.not.available' }) : formatMessage({ id: 'activity.triggers.add-on.is.not.available' }),
              icon: 'como-ic-add-on como-ic-unavailable-tooltip-trigger'
            };
          }
        }
      }
      return value;
    });
  };

  render() {

    const { automationIdPrefix, conditionKey, selectedConditionSchema, intl, error } = this.props;

    const conditionKeyOptions = this.getConditionKeyOptions();
    const selectedTabIndex = this.getSelectedTabIndex(conditionKeyOptions);
    const { text: selectedConditionKeyText, className: triggerErrorClass } = this.getSelectedConditionText(conditionKeyOptions);
    const triggerClasses = classNames('popup-trigger conditions-dropdown', triggerErrorClass, { placeholder: !conditionKey });
    const errorMessage = error ? intl.formatMessage({ id: error }) : '';
    const selectedConditionKey = selectedConditionSchema && selectedConditionSchema.get(constants.SCHEMA_CONDITION_KEY);
    return (
      <span>
        <PopupWithTabsContentList
          trigger={
            <div className="inline-div">
              <PopupTrigger
                className={triggerClasses}
                message={selectedConditionKeyText}
                automationId={automationIdPrefix}
              />
            </div>
        }
          onChange={(value) => this.props.onConditionUpdate({}, { name: constants.CONDITION_KEY, value })}
          options={conditionKeyOptions}
          selectedOption={selectedConditionKey}
          selectedTabIndex={selectedTabIndex}
          automationId={automationIdPrefix}
          disabled={this.props.disabled}
        />
        {error ? (
          <ValidationErrorMessage
            errorMessage={errorMessage}
            dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
          />
        ) : null}
      </span>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentConditionType: activitySelector.getMembershipGlobalConditionType(state),
});

export default connect(mapStateToProps, null)(withAuthorization(injectIntl(CaseConditionKey)));
