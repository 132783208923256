import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import injectSaga from '../../utils/injectSaga';

import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';

import { setLoadingState } from '../App/app.actions';
import { getBusinessDisplayName, getLocationId, getBusinessBundlePlan } from '../App/selectors';
import SideNavBar from '../SideNavBar/SideNavBar';
import GiftsPageToolbar from './views/GiftsPageToolbar';
import * as selectors from './gifts.selectors';
import * as giftsActions from './gifts.actions';
import * as businessCenterActions from '../BusinessCenter/businessCenter.actions';
import GiftsTable from './views/GiftsTable';
import giftsSaga from './gifts.saga';
import imagesSaga from '../Images/images.saga';
import * as imagesConstants from '../Images/images.constants';
import * as imagesSelectors from '../Images/images.selectors';
import { getImagesBySize } from '../Images/images.actions';
import './gifts.styles.less';
import * as reducerConstants from '../../constants/reducer.constants';
import { getSideNavbarSettings } from '../BusinessCenter/businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import * as appConstants from '../App/app.constants';
import * as appSelectors from "../App/selectors";
import * as activityConstants from "../Activity/activity.constants";

class GiftsContainer extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    businessCenterActions: PropTypes.object.isRequired,
    gifts: PropTypes.object.isRequired,
    giftsLoaded: PropTypes.bool.isRequired,
    filters: PropTypes.object,
    highlightedGiftId: PropTypes.string,
    isFiltered: PropTypes.bool.isRequired,
    sort: PropTypes.string,
    searchTerm: PropTypes.string,
    intl: PropTypes.object,
    thumbImagesLoaded: PropTypes.bool,
    thumbImages: PropTypes.object,
    businessDisplayName: PropTypes.string,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    businessBundlePlan: PropTypes.string,
    userAccessGroups: PropTypes.object
  };

  componentWillMount() {
    // get the gifts
    this.props.actions.getGifts();

    if (!this.props.thumbImagesLoaded) {
      this.props.actions.getImagesBySize(imagesConstants.IMAGE_SIZE_THUMBNAIL);
    }

    this.props.actions.setLoadingState(true);
  }

  componentDidMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_GIFTS);
  }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.thumbImagesLoaded && nextProps.giftsLoaded) && (
      !this.props.giftsLoaded || !this.props.thumbImagesLoaded)) {
      this.props.actions.setLoadingState(false);
    }
  }

  goToGift = (giftId) => {
    const userHasAccessGroups = this.props.userAccessGroups && this.props.userAccessGroups.size > 0;
    const selectedGift = this.props.gifts.find(x => x.get(activityConstants.HUB_ID) === giftId);
    const isAccessGroupEnabled = this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS)

    if (isAccessGroupEnabled && !selectedGift.get(activityConstants.ACCESS_GROUP_IDS) && userHasAccessGroups) {
      return;
    }

    this.props.actions.goToGift(giftId);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);
    const isAccessGroupEnabled = this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS)

    return (
      <div className="gifts-page">
        <Helmet>
          <title>Gifts{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        <div className="gifts-container">
          <GiftsPageToolbar
            filters={this.props.filters}
            sort={this.props.sort}
            isFiltered={this.props.isFiltered}
            onFilterUpdate={this.props.actions.onFilterUpdate}
            onSortUpdate={this.props.actions.onSortUpdate}
            onSearch={this.props.actions.search}
            searchTerm={this.props.searchTerm}
          />
          <div className="gifts-list">
            {this.props.businessBundlePlan !== appConstants.REVEL && <Button
              className="create-gift-button"
              data-automation-id="gifts-page.create-button"
              onClick={tracker.wrapButtonAction(this.props.actions.createGift, trackerConstants.BUTTON_TYPE_GIFT_CREATE)}
            >
              <Icon className="como-ic-plus" />
              <span>{formatMessage({ id: 'gifts-page.add_gift' })}</span>
            </Button>
            }
            <GiftsTable
              highlightedGiftId={this.props.highlightedGiftId}
              changeActivityStatus={this.props.actions.updateGift}
              onDeleteGift={this.props.actions.deleteGift}
              gifts={this.props.gifts}
              goToGift={this.goToGift}
              thumbImages={this.props.thumbImages}
              openGiftInNewTab={this.props.businessCenterActions.openActivityInNewTab}
              isAccessGroupEnabled={isAccessGroupEnabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gifts: selectors.getFilteredAndSortedGifts(state),
  giftsLoaded: selectors.getGiftsLoadedFlag(state),
  filters: selectors.getFilters(state),
  highlightedGiftId: selectors.getHighlightedGiftId(state),
  isFiltered: selectors.isGiftsFiltered(state),
  locationId: getLocationId(state),
  sort: selectors.getOrder(state),
  searchTerm: selectors.getSearchTerm(state),
  thumbImagesLoaded: imagesSelectors.isImagesListLoaded(state, imagesConstants.IMAGE_SIZE_THUMBNAIL),
  thumbImages: imagesSelectors.getImagesListBySize(state, imagesConstants.IMAGE_SIZE_THUMBNAIL),
  businessDisplayName: getBusinessDisplayName(state),
  businessBundlePlan: getBusinessBundlePlan(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...giftsActions, setLoadingState, getImagesBySize }, dispatch),
    businessCenterActions: bindActionCreators(businessCenterActions, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.GIFTS_BRANCH, saga: giftsSaga });
const withImagesSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withConnect,
  withSaga,
  withImagesSaga,
  withAuthorization,
  injectIntl
)(GiftsContainer);
