import { fromJS, List } from 'immutable';
import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as itemsPopulationReducerUtils from './itemsPopulation.reducer.utils';
import * as validationUtils from './validations/validation.utils';
import * as constants from '../../activity.constants';
import * as defaults from '../activity.reducer.defaults';
import * as staticSchema from '../../activitySchema/activitySchema';
import * as globalConditionReducerUtils from './globalCondition.reducer.utils';
import * as businessCenterConstants from '../../../BusinessCenter/businessCenter.constants';

export const createRandomBundleLineId = () => `bundleLine_${uuid.v4()}`;

export function resetData(activity) {
  const newActivity = activity
    .set(constants.DATA, fromJS(defaults.emptyActivity))
    .delete(constants.CHECKING_ACTIVITY_NAME_AVAILABILITY)
    .delete(constants.ACTIVITY_DATA_LOADED)
    .delete(constants.TEMP_ACTION_DATA)
    .delete(constants.ACTIVE_TAB)
    .delete(constants.TEMP_ACTIONS)
    .delete(constants.TEMP_CASES);
  return validationUtils.resetValidations(newActivity);
}

function actionsExist(dataActions) {
  return !(!dataActions || dataActions.length === 0 || !dataActions[0][constants.ACTIONS_LIST] || dataActions[0][constants.ACTIONS_LIST].length === 0);
}

function isActivityTagCompatibleWithThisTypeOfActivity(activity) {
  return activity[constants.TYPE] === constants.ACTIVITY_TYPE_RULE ||
          activity[constants.TYPE] === constants.ACTIVITY_TYPE_DEAL ||
          activity[constants.TYPE] === constants.ACTIVITY_TYPE_ONE_TIME ||
          activity[constants.TYPE] === constants.ACTIVITY_TYPE_PUNCH_CARD;
}

function getCopyPasteConditionBasedOnConditionType(conditionType) {
  return conditionType === constants.MEMBERS_PHONENUMBER_IMPORT ? schemaConstants.CONTEXT_MEMBERSHIP_PHONE_NUMBER
    : schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR;
}

function getImportFileConditionBasedOnConditionType(conditionType) {
  return conditionType === constants.MEMBERS_PHONENUMBER_IMPORT ? schemaConstants.MEMBERSHIP_PHONENUMBER_KEYS_FILE
    : schemaConstants.MEMBERSHIP_KEYS_FILE;
}

export function parseActivityData(activityRequestData, schema) {

  let data = activityRequestData;
  switch (data[constants.TYPE]) {
    case constants.SERVER_TYPE_RULE: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_RULE;
      // fix CNP-16001 - When choosing global condition of specific member without a trigger and save as draft - error is shown:
      data[constants.TRIGGER] = data[constants.TRIGGER] || '';
      break;
    }
    case constants.SERVER_TYPE_DEAL: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_DEAL;
      data[constants.TRIGGER] = schemaConstants.TRIGGER_DEAL;
      break;
    }
    case constants.SERVER_TYPE_ONE_TIME_ACTION: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_ONE_TIME;
      data[constants.TRIGGER] = schemaConstants.TRIGGER_ONE_TIME_ACTION;
      const filteredPopulationConditions = data[constants.ONE_TIME_ACTION_FILTERED_POPULATION][constants.FILTERED_POPULATION_CONDITIONS];
      if (!filteredPopulationConditions) {
        // If this a 1-time created from qlik - we do not add the user action conditions object
        break;
      }
      if (!filteredPopulationConditions[constants.USER_ACTIONS_CONDITIONS]
        || !filteredPopulationConditions[constants.USER_ACTIONS_CONDITIONS][constants.USER_ACTIONS_CONDITIONS_LIST]) {
        filteredPopulationConditions[constants.USER_ACTIONS_CONDITIONS]
          = defaults.userActionsEmptyFilters;
      } else {
        // Add empty aggregation conditions from schema definition, if they are not present in the model
        // (this is for fixing existing filters that where made before he ggregation section)
        const userActionFilters = filteredPopulationConditions[constants.USER_ACTIONS_CONDITIONS][constants.USER_ACTIONS_CONDITIONS_LIST];
        userActionFilters.forEach((userActionFilter) => {
          const schemaAggregatedConditions = schema.getIn([constants.CONDITIONS_PER_USER_ACTION, userActionFilter[constants.USER_ACTION_KEY], schemaConstants.AGGREGATED_CONDITIONS_PER_ACTION]);
          if (userActionFilter[constants.USER_ACTION_AGGREGATION_CONDITIONS] == null) {
            userActionFilter[constants.USER_ACTION_AGGREGATION_CONDITIONS] =
              globalConditionReducerUtils.createEmptyUserActionAggregationConditions(schemaAggregatedConditions);
          }
        });
        userActionFilters.push(defaults.userActionEmptyConditions);
      }
      filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER] = fromJS(defaults.defaultMembershipImportConditionsWrapper).toJS();
      if (filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION]) {
        const conditionKey = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION].conditionKey;
        const conditionValue = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION].conditionValue;
        const conditionType = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.CONDITION_KEY_TO_CONDITION_TYPE][conditionKey];
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.CONDITION_TYPE_TO_COPY_PASTE_VALUE][conditionType] = conditionValue;
        const copyPasteConditionKey = getCopyPasteConditionBasedOnConditionType(conditionType);
        const importFileConditionKey = getImportFileConditionBasedOnConditionType(conditionType);

        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_COPY_PASTE_MODE][constants.CONDITION_KEY] = copyPasteConditionKey;
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE][constants.CONDITION_KEY] = importFileConditionKey;

        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_COPY_PASTE_MODE]
          = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION];
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_MODE] = constants.IMPORT_MEMBERS_COPY_PASTE_MODE;
      } else if (filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION]) {
        const conditionKey = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION].conditionKey;
        let conditionValue = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION].conditionValue;
        conditionValue = {[constants.CONDITION_VALUE]: conditionValue};
        const conditionType = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.CONDITION_KEY_TO_CONDITION_TYPE][conditionKey];
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE][conditionType] = conditionValue;
        const copyPasteConditionKey = getCopyPasteConditionBasedOnConditionType(conditionType);
        const importFileConditionKey = getImportFileConditionBasedOnConditionType(conditionType);

        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_COPY_PASTE_MODE][constants.CONDITION_KEY] = copyPasteConditionKey;
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE][constants.CONDITION_KEY] = importFileConditionKey;

        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE]
          = filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION];
        filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][constants.IMPORT_MEMBERS_MODE] = constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE;
      }
      delete filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_CONDITION];
      delete filteredPopulationConditions[constants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION];

      if (data[constants.AB_TEST_MODE]) {
        const passiveCondition = getAbTestPassiveCondition(data[constants.CASES][0][constants.CONDITIONS][constants.CONDITIONS_LIST][0]);
        data[constants.CASES][1][constants.CONDITIONS][constants.CONDITIONS_LIST].push(passiveCondition);
      }
      break;
    }
    case constants.SERVER_TYPE_PUNCH_CARD: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_PUNCH_CARD;
      data[constants.TRIGGER] = schemaConstants.TRIGGER_PUNCH_CARD;
      if (data[constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION] === null) {
        data[constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION] = defaults.instanceExpirationGlobalCondition;
      }
      if (data[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER] === constants.PUNCH_CARD_RENEW_ON_LAST_PUNCH_OR_EXPIRATION) {
        data[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER] = constants.PUNCH_CARD_RENEW_ON_LAST_PUNCH;
      } else if (data[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER] === constants.PUNCH_CARD_RENEW_ON_REDEEM_OR_EXPIRATION) {
        data[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER] = constants.PUNCH_CARD_RENEW_ON_REDEEM;
      }

      break;
    }
    case constants.SERVER_TYPE_GIFT: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_GIFT;
      data[constants.TRIGGER] = schemaConstants.TRIGGER_GIFT;
      if (data[constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION] === null) {
        data[constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION] = defaults.instanceExpirationGlobalCondition;
      }
      break;
    }
    case constants.SERVER_TYPE_PROMO_CODE: {
      data[constants.TYPE] = constants.ACTIVITY_TYPE_PROMO_CODE;
      data[constants.TRIGGER] = schemaConstants.TRIGGER_PROMO_CODE;

      if (data[constants.PROMO_CODE_BULKS_TYPE] === constants.PROMO_CODE_MODE_SINGLE_CODE) {
        // create front-end model of promo codes (with both types of codes)
        const bulkCodeType = data[constants.PROMO_CODE_BULKS][0][constants.TYPE];
        const bulkCodes = defaults.getDefaultBulkCodesFixType.toJS();
        bulkCodes[constants.PROMO_CODE_MODE] = bulkCodeType;
        bulkCodes[bulkCodeType] = data[constants.PROMO_CODE_BULKS];
        data[constants.PROMO_CODE_BULKS] = bulkCodes;
      }
      break;
    }
    default:
      break;
  }

  if (actionsExist(data.actions)) {
    data = normalizeActionsObject(data);
  } else if (!data.cases || data.cases.length === 0) {
    data[constants.ACTIONS] = staticSchema.redeemableActivities.includes(data[constants.TRIGGER]) ? [defaults.defaultActionsWrapperBenefit] : [defaults.emptyActionsWrapper];
  }

  if (!data[constants.ACTIVITY_TAGS] && isActivityTagCompatibleWithThisTypeOfActivity(data)) {
    data[constants.ACTIVITY_TAGS] = fromJS([]);  //backward compatability
  }

  if (data.cases && data.cases.length) {
    data.cases = data.cases.map((activityCase) => normalizeActionsObject(activityCase));
  }

  if (data[constants.PUNCH_CARD_CHILD_ACTIVITIES]) {
    Object.keys(data[constants.PUNCH_CARD_CHILD_ACTIVITIES]).map((key) => {
      if (key === constants.PUNCH_CARD_COMMUNICATION_RULES) {
        let communicationRules = data[constants.PUNCH_CARD_CHILD_ACTIVITIES][constants.PUNCH_CARD_COMMUNICATION_RULES];
        if (communicationRules && communicationRules.length) {
          communicationRules = communicationRules.map((rule) => normalizeActionsObject(rule));
        }
      } else {
        const activity = data[constants.PUNCH_CARD_CHILD_ACTIVITIES][key];
        if (activity && activity.actions && activity.actions.length) {
          return normalizeActionsObject(activity);
        }
      }
    });
  }

  if (!data[constants.CONDITIONS]) {
    data[constants.CONDITIONS] = defaults.emptyConditions;
  }

  if (data[constants.MEMBERSHIP_GLOBAL_CONDITIONS] && data[constants.MEMBERSHIP_GLOBAL_CONDITIONS][constants.CONDITIONS] === null) {
    delete data[constants.MEMBERSHIP_GLOBAL_CONDITIONS][constants.CONDITIONS];
  }

  if (!data[constants.MEMBERSHIP_GLOBAL_CONDITIONS] && data[constants.TYPE] !== constants.ACTIVITY_TYPE_ONE_TIME) {
    // obsolete membership global condition of  'all members' type -
    // fix by simulating the same behaviour using MEMBERS_SPECIFIC type with all type of members and without conditions:
    data[constants.MEMBERSHIP_GLOBAL_CONDITIONS] = {
      [constants.TYPE]: constants.MEMBERS_SPECIFIC,
      [constants.CONDITIONS]: defaults.emptyGlobalMembershipConditionsConditions,
      [constants.MEMBERSHIP_STATUS_CONDITION]: {
        ...defaults.defaultMembershipStatusCondition,
        [constants.CONDITION_VALUE]: [
          schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED,
          schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED,
          schemaConstants.MEMBERSHIP_STATUS_VALUE_PENDING
        ]
      }
    };
  } else if (data[constants.MEMBERSHIP_GLOBAL_CONDITIONS] && data[constants.MEMBERSHIP_GLOBAL_CONDITIONS][constants.TYPE] === constants.MEMBERS_SPECIFIC
      && data[constants.MEMBERSHIP_GLOBAL_CONDITIONS][constants.CONDITIONS][constants.CONDITIONS_LIST].length === 0) {
    // push empty condition to the list if its empty:
    data[constants.MEMBERSHIP_GLOBAL_CONDITIONS][constants.CONDITIONS][constants.CONDITIONS_LIST].push(defaults.emptyCondition);
  }

  if (data[constants.DATETIME_GLOBAL_CONDITIONS] && data[constants.DATETIME_GLOBAL_CONDITIONS].size > 0) {
    data[constants.DATETIME_GLOBAL_CONDITIONS].type = constants.DATETIME_SPECIFIC;
  }

  // todo: deal with one-time action

  return data;
}

function normalizeActionsObject(data) {
  // data.actionsWrapper = data.actions[0];
  // delete data.actions;
  const actionsWrappers = data.actions;
  const defaultBenefitActionsWrapper = fromJS(defaults.defaultActionsWrapperBenefit);

  actionsWrappers.forEach((actionsWrapper, index) => {
    // benefit actions should not add an empty action to a list
    if (index === actionsWrappers.length - 1 && !staticSchema.redeemableActivities.includes(data.trigger)) {
      actionsWrapper.actionsList.push(defaults.emptyAction);
    }

    if (constants.BUNDLE in actionsWrapper && !actionsWrapper[constants.BUNDLE]) {
      delete actionsWrapper[constants.BUNDLE];
    }
    itemsPopulationReducerUtils.defaultizeItemsPopulation(actionsWrapper);
    data.actions[index] = actionsWrapper;


    if (staticSchema.redeemableActivities.includes(data.trigger) && actionsWrapper[constants.ACTIONS_LIST].length > 1) {
      actionsWrapper[constants.ACTIONS_LIST].forEach((action, actionsListIndex) => {
        const newWrapper = defaultBenefitActionsWrapper.toJS();
        newWrapper[constants.ACTIONS_LIST][0] = action;
        newWrapper[constants.ACTIONS_MODE] = actionsWrapper[constants.ACTIONS_MODE];
        data.actions.push(newWrapper);
        data.actions.splice(actionsListIndex, 1);
      });

    }

  });
  // for benefit action add a new actionsWrapper with an empty action in actionsList
  if (staticSchema.redeemableActivities.includes(data.trigger)) {
    data.actions.push(defaultBenefitActionsWrapper.toJS());
  }
  return data;
}

/**
 * If there are errors in one or more cases -
 * This function expands cases with errors in them and collapses cases without errors.
 * **This function does not change the collapsing states if there are no errors in any of the cases.**
 * @param state the state after validation
 */
export function toggleCaseCollapsingOnValidationErrors(state) {
  const cases = state.getIn([constants.DATA, constants.CASES]);
  if (cases && cases.size > 0) {
    let newState = state;
    const casesErrors = state.getIn([constants.VALIDATION_ERRORS, constants.ACTIVITY_ACTIONS, constants.CASES]);
    if (casesErrors) {
      for (let caseIndex = 0; caseIndex < cases.size; caseIndex++) {
        const collapsedPropertyPath = [constants.DATA, constants.CASES, caseIndex, constants.IS_COLLAPSED];
        const havingError = casesErrors.has(caseIndex.toString());
        newState = newState.setIn(collapsedPropertyPath, !havingError);
      }
    }
    return newState;
  }
  return state;
}

// Does the activity math the following criteria:
// - It is 1-time action
// - Status == draft
// - It has a positive number of members filtered
// - Originates from the campaogn center
export function isDraftOneTimeActionWithFilteredPopulation(activityData) {
  return activityData[constants.TYPE] === constants.ACTIVITY_TYPE_ONE_TIME &&
    activityData[constants.STATUS] === constants.ACTIVITY_STATUS_DRAFT &&
    activityData[constants.ONE_TIME_ACTION_FILTERED_POPULATION] &&
    !activityData[constants.ONE_TIME_ACTION_FILTERED_POPULATION][constants.FILTERED_POPULATION_SOURCE] &&
    activityData[constants.ONE_TIME_ACTION_FILTERED_POPULATION][constants.FILTERED_NUM_OF_MEMBERS];
}

export function isTriggerSchedulingRelated(trigger) {
  return [schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY, schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY, schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY].includes(trigger);
}

export function isTriggerBenefitsRelated(trigger) {
  return [schemaConstants.TRIGGER_RECEIVED_ASSET, schemaConstants.TRIGGER_REDEEM, schemaConstants.TRIGGER_PUNCH,
    schemaConstants.TRIGGER_EXCEEDED_PUNCH, schemaConstants.TRIGGER_PURCHASED_ASSET].includes(trigger);
}

export function isTriggerTagsRelated(trigger) {
  return [schemaConstants.TRIGGER_TAG_OPERATION, schemaConstants.TRIGGER_UNTAG_OPERATION].includes(trigger);
}


export function getDataWithUpdatedDisplayFields(dataObject, fields) {
  if (!fields || !Array.isArray(fields) || fields.length === 0) {
    return dataObject;
  }
  let returnObject = dataObject;
  fields.forEach((field) => {
    const val = typeof field.fieldValue === 'string' ? field.fieldValue.trim() : field.fieldValue;
    returnObject = returnObject.set(field.fieldName, fromJS(val));
  });
  return returnObject;
}

/**
 * create instanceExpirationCondition from hub1 data
 * @param activateTime
 * @param activateUnits
 * @param deactivateTime
 * @param deactivateUnits
 * @param deactivateConditionOnly
 * @returns {any}
 */
export function migrateInstanceExpirationConditions(activateTime, activateUnits, deactivateTime, deactivateUnits, deactivateConditionOnly) {
  let expirationCondition = fromJS(defaults.instanceExpirationGlobalCondition);
  if (activateTime && activateUnits && !deactivateConditionOnly) {
    expirationCondition = expirationCondition
      .setIn([constants.ACTIVATION_CONDITION, constants.ACTIVATION_MODE], constants.ACTIVATION_MODE_ON_SPECIFIC_TIME)
      .setIn([constants.ACTIVATION_CONDITION, constants.ACTIVATION_SPECIFIC_TIME_VALUE], activateTime)
      .setIn([constants.ACTIVATION_CONDITION, constants.ACTIVATION_SPECIFIC_TIME_UNIT], activateUnits);
  }
  if (deactivateTime && deactivateUnits) {
    expirationCondition = expirationCondition
      .setIn([constants.DEACTIVATION_CONDITION, constants.ACTIVATION_MODE], constants.ACTIVATION_MODE_ON_SPECIFIC_TIME)
      .setIn([constants.DEACTIVATION_CONDITION, constants.ACTIVATION_SPECIFIC_TIME_VALUE], deactivateTime)
      .setIn([constants.DEACTIVATION_CONDITION, constants.ACTIVATION_SPECIFIC_TIME_UNIT], deactivateUnits);
  }
  return expirationCondition;
}

/**
 * create membership status condition from hub1 data
 * @param hub1MembershipStatus
 * @returns {any}
 */
export function migrateMembershipGlobalConditions(hub1MembershipStatus) {
  const membershipConditionsToMigrate = JSON.parse(hub1MembershipStatus);
  let membershipStatusConditionValue = List();
  membershipConditionsToMigrate.forEach((status) => {
    switch (status) {
      case 'registered':
        membershipStatusConditionValue = membershipStatusConditionValue.push(schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED);
        break;
      case 'not_registered':
        membershipStatusConditionValue = membershipStatusConditionValue.push(schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED);
        break;
      case 'pending':
        membershipStatusConditionValue = membershipStatusConditionValue.push(schemaConstants.MEMBERSHIP_STATUS_VALUE_PENDING);
        break;
      default:
        break;
    }
  });
  let membershipGlobalCondition = fromJS(defaults.defaultGlobalMembershipConditions);
  if (membershipStatusConditionValue.size > 1 || membershipStatusConditionValue.get(0) !== schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED) {
    membershipGlobalCondition = fromJS(defaults.specificGlobalMembershipConditions);
    membershipGlobalCondition = membershipGlobalCondition
      .set(constants.TYPE, constants.MEMBERS_SPECIFIC)
      .setIn([constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE], membershipStatusConditionValue);
  }
  return membershipGlobalCondition;
}

export function mapCodesServerErrorsToKeys(serverError) {
  switch (serverError) {
    case constants.PROMO_CODES_SERVER_ERROR_LENGTH:
      return 'promo.codes.file.error.different.length';
    case constants.PROMO_CODES_SERVER_ERROR_NUMBERS:
      return 'promo.codes.file.error.number';
    case constants.PROMO_CODES_SERVER_ERROR_EMPTY:
      return 'promo.codes.file.error.empty';
    case constants.PROMO_CODES_SERVER_ERROR_SHORT:
      return 'promo.codes.file.error.short';
    default:
      return 'promo.codes.file.error.other';
  }
}

function getAbTestPassiveCondition(activeCondition) {
  const passiveCondition = cloneDeep(activeCondition);
  passiveCondition[constants.CONDITION_VALUE] = 100 - parseInt(activeCondition[constants.CONDITION_VALUE], 10);
  return passiveCondition;
}

export function groupAbStatsByCategory(data) {
  const stats = [];
  const keys = [];
  data.forEach((dataRow) => {

    // group by key (data is compared based on key)
    if (!keys.includes(dataRow.key)) {
      const statsObj = {};
      statsObj.isGeneral = dataRow.category === businessCenterConstants.AB_TEST_CATEGORY_GENERAL;

      keys.push(dataRow.key);
      statsObj.key = dataRow.key;
      const sameKeyDataRows = data.filter((elem) => elem.key === dataRow.key);

     // concat categories names
      const categories = [];
      sameKeyDataRows.forEach((row) => {
        if (!categories.includes(row.category)) {
          categories.push(row.category);
        }
      });
      statsObj.categories = categories;
      statsObj.format = dataRow.format;
      statsObj.type = dataRow.type;

      const caseA = sameKeyDataRows.filter((elem) => elem.caseId === 'case_a')[0];
      const caseB = sameKeyDataRows.filter((elem) => elem.caseId === 'case_b')[0];

      if (caseA) {
        statsObj.case1Value = caseA.caseValue;
      }
      if (caseB) {
        statsObj.case2Value = caseB.caseValue;
      }
      if (statsObj.case1Value && statsObj.case2Value) {
        if (isNaN(parseFloat(statsObj.case1Value)) || isNaN(parseFloat(statsObj.case2Value)) || parseFloat(statsObj.case1Value) === 0 || parseFloat(statsObj.case2Value) === 0) {
          statsObj.diff = null;
          statsObj.percentageDiff = null;
        } else {
          statsObj.diff = parseFloat(statsObj.case1Value) - parseFloat(statsObj.case2Value);
          statsObj.percentageDiff = ((parseFloat(statsObj.case1Value) / parseFloat(statsObj.case2Value)) - 1) * 100;
        }
        if (dataRow.betterValue === 'larger') {
          if (statsObj.diff > 0) {
            statsObj.betterCase = 'case_a';
          } else if (statsObj.diff < 0) {
            statsObj.betterCase = 'case_b';
          }
        } else if (dataRow.betterValue === 'smaller') {
          if (statsObj.diff > 0) {
            statsObj.betterCase = 'case_b';
          } else if (statsObj.diff < 0) {
            statsObj.betterCase = 'case_a';
          }
        }

        statsObj.timeUpdated = dataRow.timeUpdated;
      }
      stats.push(statsObj);
    }
  });

  // group ready stats by categories (data is presented based on categories)
  let categories = [];
  const statsByCategories = [];
  stats.forEach((statsRow) => {
    if (!categories.some((category) => statsRow.categories.includes(category))) {
      // get all rows that have at least one of the same categories as the current row
      const sameCategoriesStats = statsRow.categories.includes('general') ? [statsRow] : stats.filter((statsElem) => statsElem.categories.some((category) => statsRow.categories.includes(category)));
      let categoriesPerGroup = [];
      // get all the categories of the filtered group
      if (!statsRow.categories.includes('general')) {
        sameCategoriesStats.forEach((sameCategoriesStatsRow) => {
          categoriesPerGroup = categoriesPerGroup.concat(sameCategoriesStatsRow.categories);
          categories = categories.concat(sameCategoriesStatsRow.categories);
        });
      } else {
        categoriesPerGroup = [statsRow.key];
      }
      const statsByCategoriesRow = {};
      const uniqueCategories = [...new Set(categoriesPerGroup)];
      statsByCategoriesRow.categories = uniqueCategories.join(',');
      statsByCategoriesRow.data = sameCategoriesStats;
      statsByCategoriesRow.isGeneral = statsRow.isGeneral;
      statsByCategories.push(statsByCategoriesRow);
    }
  });

  return statsByCategories;
}
