import moment from 'moment';

export function getDefaultGiftDataStructure(locationId) {
  const newVar = {
    status: 'active',
    source: 'campaignCenter',
    trigger: 'gift',
    type: 'gift',
    locationId,
    activityTags: [],
    instanceExpirationGlobalCondition: {
      activationCondition: {
        mode: 'default',
        unit: 'day',
        value: 1
      },
      deactivationCondition: {
        mode: 'default',
        unit: 'day',
        value: 1
      }
    },
    imageId: null,
    dateTimeGlobalConditions: {
      datesRangeCondition: {
        conditionKey: 'context.TimeStamp',
        dataValueType: 'dateTimeBetween',
        operatorKey: 'dateTimeIsBetween',
        conditionValue: getDefaultDates()
      },
      type: 'custom',
      timeRangeInDaysOfWeekCondition: {
        conditionKey: 'context.TimeStampDays',
        dataValueType: 'daysOfWeekRangeCondition',
        operatorKey: 'dayOfWeek',
        conditionValue: [
          {
            days: [1, 2, 3, 4, 5, 6, 7],
            timeRange: {
              timeFrom: '00:00:00',
              timeTo: '23:59:59'
            }
          }
        ]
      },
    }
  };
  return newVar;
}

export function getDefaultDates() {
  return {
    dateFrom: moment(),
    // 87600 - 10 years
    dateTo: moment().add(87600, 'hours')
  };
}

export function membershipGlobalConditionsStructure(tags) {
  return {
    conditions: {
      conditionsList: [
        {
          conditionKey: 'context.Membership.Tag',
          conditionValue: tags,
          dataValueType: 'stringArray',
          operatorKey: 'containsAtLeastOneValue'
        },
      ],
      conditionsOperator: 'and'
    },
    type: 'custom'
  };
}

export function dateTimeGlobalConditionsStructure(condition) {
  const dateFrom = moment(condition.conditionValue.dateFrom).format();
  const dateTo = moment(condition.conditionValue.dateTo).format();
  return {
    dateTimeGlobalConditions: {
      datesRangeCondition: {
        conditionKey: condition.conditionKey,
        dataValueType: condition.dataValueType,
        operatorKey: condition.operatorKey,
        conditionValue: condition.conditionValue.hasOwnProperty('dateTo') ? {
          dateFrom: dateFrom,
          dateTo: dateTo
        } : condition.conditionValue
      },
      type: 'custom',
      timeRangeInDaysOfWeekCondition: {
        conditionKey: 'context.TimeStampDays',
        dataValueType: 'daysOfWeekRangeCondition',
        operatorKey: 'dayOfWeek',
        conditionValue: [
          {
            days: [1, 2, 3, 4, 5, 6, 7],
            timeRange: {
              timeFrom: '00:00:00',
              timeTo: '23:59:59'
            }
          }
        ]
      }
    }
  }
}
