import {fromJS} from 'immutable';
import * as constants from './franchise.constants'

const initialState = fromJS({});

function franchiseReducer(state = initialState, action) {

  let newState;

  switch (action.type) {
    case constants.RESET_ACCESS_GROUP_ERROR: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, false)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, '');
      break;
    }
    case constants.DISABLE_ACCESS_GROUP_ADD_BUTTON: {
      newState = state.set(constants.ACCESS_GROUP_ADD_BUTTON_DISABLED, true);
      break;
    }
    case constants.GETTING_ACCESS_GROUPS_SUCCESS: {
      newState = state.set(constants.ACCESS_GROUPS, fromJS(action.payload.accessGroups))
        .set(constants.HUB_USERS, fromJS(action.payload.hubUsers));
      break;
    }
    case constants.GETTING_ACCESS_GROUPS_FAIL: {
      newState = state.set(constants.ACCESS_GROUPS, fromJS([]))
        .set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.fetch.error')
        .set(constants.ACCESS_GROUP_FETCH_ERROR, true);
      break;
    }
    case constants.ADDING_ACCESS_GROUP_SUCCESS: {
      newState = state.set(constants.ACCESS_GROUPS, fromJS(action.payload))
        .set(constants.ACCESS_GROUP_ADD_BUTTON_DISABLED, false);
      break;
    }
    case constants.ADDING_USER_TO_ACCESS_GROUP_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.adding-user.error');
      break;
    }
    case constants.ADDING_ACCESS_GROUP_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.adding-access-group.error')
        .set(constants.ACCESS_GROUP_ADD_BUTTON_DISABLED, false);
      break;
    }
    case constants.ADDING_USER_TO_ACCESS_GROUP_SUCCESS: {
      newState = state.set(constants.ACCESS_GROUPS, fromJS(action.payload));
      break;
    }
    case constants.CHANGING_ACCESS_GROUP_NAME_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.change-name.error');
      break;
    }
    case constants.REMOVING_USER_FROM_ACCESS_GROUP_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.remove-user.error');
      break;
    }
    case constants.REMOVING_ACCESS_GROUP_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.access-group.remove-group.error');
      break;
    }
    case constants.CHANGE_ACCESS_GROUP_NAME_SUCCESS: {
      const {accessGroupId, newName} = action.payload;
      const accessGroups = state.get(constants.ACCESS_GROUPS);
      const accessGroupIndex = accessGroups.findIndex(ag => ag.get(constants.ACCESS_GROUP_ID) === accessGroupId);
      newState = state.set(constants.ACCESS_GROUPS, accessGroups.setIn([accessGroupIndex, constants.NAME], newName));
      break;
    }
    case constants.REMOVING_USER_FROM_ACCESS_GROUP_SUCCESS: {
      const {accessGroupId, userId} = action.payload;
      const accessGroups = state.get(constants.ACCESS_GROUPS);
      const accessGroupIndex = accessGroups.findIndex(ag => ag.get(constants.ACCESS_GROUP_ID) === accessGroupId);
      const accessGroup = accessGroups.get(accessGroupIndex);
      const users = accessGroup.get(constants.ACCESS_GROUP_HUB_USERS);
      const userIndex = users.findIndex(u => u.get(constants.HUB_USER_ID) === userId);
      newState = state.set(constants.ACCESS_GROUPS, accessGroups.setIn([accessGroupIndex, constants.ACCESS_GROUP_HUB_USERS], users.delete(userIndex)));
      break;
    }
    case constants.SAVING_FRANCHISE_FIELD_FAIL: {
      newState = state.set(constants.ACCESS_GROUP_ERROR, true)
        .set(constants.ACCESS_GROUP_ERROR_MESSAGE, 'franchise.list.save-error');
      break;
    }
    case constants.REMOVING_ACCESS_GROUP_SUCCESS: {
      const accessGroups = state.get(constants.ACCESS_GROUPS);
      const accessGroupIndex = accessGroups.findIndex(ag => ag.get(constants.ACCESS_GROUP_ID) === action.payload.accessGroupId);
      newState = state.set(constants.ACCESS_GROUPS, accessGroups.delete(accessGroupIndex));
      break;
    }
    case constants.START_LOADING_ACCESS_GROUP: {
      const accessGroupId = action.payload;
      const accessGroups = state.get(constants.ACCESS_GROUPS);
      const accessGroupIndex = accessGroups.findIndex(ag => ag.get(constants.ACCESS_GROUP_ID) === accessGroupId);
      newState = state.set(constants.ACCESS_GROUPS, accessGroups.setIn([accessGroupIndex, constants.LOADING], true));
      break;
    }
    case constants.END_LOADING_ACCESS_GROUP: {
      const accessGroupId = action.payload;
      const accessGroups = state.get(constants.ACCESS_GROUPS);
      const accessGroupIndex = accessGroups.findIndex(ag => ag.get(constants.ACCESS_GROUP_ID) === accessGroupId);
      newState = state.set(constants.ACCESS_GROUPS, accessGroups.setIn([accessGroupIndex, constants.LOADING], false));
      break;
    }
    default:
      newState = state;
  }

  return newState;

}

export default franchiseReducer;
