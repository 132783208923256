export const ACTIVITY_GOALS_BRANCH = 'activityGoals';
export const ACTIVITY_BRANCH = 'activity';
export const ROUTE_BRANCH = 'route';
export const TOASTR_BRANCH = 'toastr';
export const APP_BRANCH = 'app';
export const AI_BRANCH = 'ai';
export const BENEFITS_BRANCH = 'benefits';
export const FORMS_BRANCH = 'forms';
export const BUSINESS_CENTER_BRANCH = 'businessCenter';
export const FILTER_MEMBERS = 'filterMembers';
export const DATA_AND_BI = 'dataAndBi';
export const IMAGES_BRANCH = 'images';
export const PROMO_CODES_BRANCH = 'promoCodes';
export const ITEM_GROUPS_BRANCH = 'itemGroups';
export const GENERIC_ACTION_BRANCH = 'genericAction';
export const MAIL_TEMPLATES_BRANCH = 'mailTemplates';
export const TEMPLATES_BRANCH = 'templates';
export const ERROR_MESSAGE_BRANCH = 'errorMessage';
export const SIDE_NAVBAR_BRANCH = 'sideNavBar';
export const GIFTS_BRANCH = 'gifts';
export const EMAIL_BRANCH = 'email';
export const EMAILS_BRANCH = 'emails';
export const POINT_SHOP_BRANCH = 'pointShop';
export const CUSTOMER_BRANCH = 'customer';
export const MICRO_CAMPAIGNS_BRANCH = 'microCampaigns';
export const MICRO_CAMPAIGN_BRANCH = 'microCampaign';
export const COMILLIA_HINT_BRANCH = 'comilliaHint';
export const CUSTOMER_PORTAL_BRANCH = 'customerPortal';
export const AGENT_CONFIGURATION_BRANCH = 'agentConfiguration';
export const OLO_CONFIGURATION_BRANCH = 'oloConfiguration';
export const FRANCHISE_BRANCH = 'franchise';

