import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import * as emailsActions from './emails.actions';
import * as emailsSelectors from './emails.selectors';
import saga from './emails.saga';

import './emails.styles.less';
import SideNavBar from '../SideNavBar/SideNavBar';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import { setLoadingState } from '../App/app.actions';
import EmailsPageToolbar from './views/EmailsPageToolbar';
import EmailsTable from './views/EmailsTable';
import * as routerConstants from '../../constants/reducer.constants';
import * as appSelectors from '../App/selectors';
import * as routeConstants from '../../constants/route.contants';
import * as constants from './emails.constants';
import * as businessCenterActions from '../BusinessCenter/businessCenter.actions';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import { getSideNavbarSettings } from '../BusinessCenter/businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import * as mentionsSelectors from '../Activity/activitySchema/mentions.selectos';
import BasicPage from '../Basic/Basic.container';
import * as appConstants from '../App/app.constants';
import * as activityConstants from '../Activity/activity.constants';


export class Emails extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    emails: PropTypes.object,
    businessCenterActions: PropTypes.object.isRequired,
    actions: PropTypes.object,
    emailsLoaded: PropTypes.bool,
    isFiltered: PropTypes.bool,
    filters: PropTypes.object,
    highlightedEmailId: PropTypes.string,
    sort: PropTypes.string,
    searchTerm: PropTypes.string,
    intl: PropTypes.object,
    businessDisplayName: PropTypes.string,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    businessBundlePlan: PropTypes.string.isRequired,
    hasBasicPlanPermissions: PropTypes.bool.isRequired,
    transformedMentions: PropTypes.object,
    userAccessGroups: PropTypes.object
  };

  componentWillMount() {
    this.props.actions.getEmails();
    this.props.actions.setLoadingState(true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emailsLoaded && !this.props.emailsLoaded) {
      this.props.actions.setLoadingState(false);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearEmailList();
  }

  goToEmail = (hubId) => {
    const userHasAccessGroups = this.props.userAccessGroups && this.props.userAccessGroups.size > 0;
    const selectedEmail = this.props.emails.find(x => x.get(activityConstants.HUB_ID) === hubId);
    const isAccessGroupEnabled = this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS)

    if (isAccessGroupEnabled && !selectedEmail.get(activityConstants.ACCESS_GROUP_IDS) && userHasAccessGroups) {
      return;
    }

    this.props.actions.goToEmail(hubId)
  }

  getFullEmailsContents = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);

    return (
      <div className="emails-page">
        <Helmet>
          <title>Emails{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        <div className="emails-container">
          <EmailsPageToolbar
            filters={this.props.filters}
            sort={this.props.sort}
            onFilterUpdate={this.props.actions.onFilterUpdate}
            onSortUpdate={this.props.actions.onSortUpdate}
            onSearch={this.props.actions.search}
            searchTerm={this.props.searchTerm}
            isFiltered={this.props.isFiltered}
          />
          <div className="emails-list">
            <Link to={{ pathname: routeConstants.TEMPLATE_LIBRARY_ROUTE, search: location.search, type: constants.EMAIL }}>
              <Button
                className="create-email-button"
                data-automation-id="emails-page.add_email"
                onClick={tracker.wrapButtonAction(this.props.actions.createEmail, trackerConstants.BUTTON_TYPE_EMAIL_CREATE)}
              >
                <Icon className="como-ic-plus" />
                <span>{formatMessage({ id: 'emails-page.add_email' })}</span>
              </Button>
            </Link>
            <EmailsTable
              highlightedEmailId={this.props.highlightedEmailId}
              changeActivityStatus={this.props.actions.updateEmailStatus}
              emails={this.props.emails}
              goToEmail={this.goToEmail}
              openEmailInNewTab={this.props.businessCenterActions.openActivityInNewTab}
              transformedMentions={this.props.transformedMentions}
              hasFullPermission={this.props.hasFullPermission}
            />
          </div>
        </div>
      </div>
    );
  }

  getBasicEmailsContents = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <BasicPage isEmails>
        <div className="emails-page">
          <Helmet>
            <title>Emails{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
          </Helmet>
          <div className="emails-container">
            <EmailsPageToolbar
              filters={this.props.filters}
              sort={this.props.sort}
              onFilterUpdate={this.props.actions.onFilterUpdate}
              onSortUpdate={this.props.actions.onSortUpdate}
              onSearch={this.props.actions.search}
              searchTerm={this.props.searchTerm}
              isFiltered={this.props.isFiltered}
            />
            <div className="emails-list">
              <Link to={{ pathname: this.props.hasBasicPlanPermissions ? routeConstants.BASIC_TEMPLATE_LIBRARY_ROUTE : routeConstants.TEMPLATE_LIBRARY_ROUTE, search: location.search, type: constants.EMAIL }}>
                <Button
                  className="create-email-button"
                  data-automation-id="emails-page.add_email"
                  onClick={tracker.wrapButtonAction(this.props.actions.createEmail, trackerConstants.BUTTON_TYPE_EMAIL_CREATE)}
                >
                  <Icon className="como-ic-plus" />
                  <span>{formatMessage({ id: 'emails-page.add_email' })}</span>
                </Button>
              </Link>
              <EmailsTable
                highlightedEmailId={this.props.highlightedEmailId}
                changeActivityStatus={this.props.actions.updateEmailStatus}
                emails={this.props.emails}
                goToEmail={this.goToEmail}
                openEmailInNewTab={this.props.businessCenterActions.openActivityInNewTab}
                transformedMentions={this.props.transformedMentions}
              />
            </div>
          </div>
        </div>
      </BasicPage>
    );
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);

    return this.props.businessBundlePlan === appConstants.TRIAL ? this.getBasicEmailsContents() : this.getFullEmailsContents();
  }
}

const mapStateToProps = (state) => ({
  emails: emailsSelectors.getFilteredAndSortedEmails(state),
  transformedMentions: mentionsSelectors.getEmailMentionsTransformed(state),
  emailsLoaded: emailsSelectors.getEmailsLoadedFlag(state),
  highlightedEmailId: emailsSelectors.getHighlightedEmailId(state),
  sort: emailsSelectors.getOrder(state),
  isFiltered: emailsSelectors.isEmailsFiltered(state),
  searchTerm: emailsSelectors.getSearchTerm(state),
  businessDisplayName: appSelectors.getBusinessDisplayName(state),
  businessBundlePlan: appSelectors.getBusinessBundlePlan(state),
  hasBasicPlanPermissions: appSelectors.hasBasicPlanPermissions(state),
  filters: emailsSelectors.getFilters(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...emailsActions, setLoadingState }, dispatch),
    businessCenterActions: bindActionCreators(businessCenterActions, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: routerConstants.EMAILS_BRANCH, saga });
const withBusinessSaga = injectSaga({ key: routerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });


export default compose(withSaga, withBusinessSaga, withConnect, withAuthorization, injectIntl)(Emails);
