                             /*
 *
 * FilterMembers actions
 *
 */

import * as constants from './filterMembers.constants';

export function filterMembersByAttributesAndActions() {
  return {
    type: constants.FILTER_MEMBERS_BY_ATTRIBUTES_AND_ACTIONS,
  };
}
export function deleteFilterAttributeCondition(index) {
  return {
    type: constants.DELETE_FILTER_MEMBERSHIP_CONDITION,
    index
  };
}

export function createOneTimeAction(filteredPopulation, filterTempGlobalCondition) {
  return {
    type: constants.CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION,
    filteredPopulation,
    filterTempGlobalCondition,
    source: constants.FILTER_SOURCE,
  };
}

export function updateFilterUserActionFilterAggregationAttribute(attributeName, attributeValue, isActive, attributeIndex, userActionIndex) {
  return {
    type: constants.UPDATE_USER_ACTION_AGGREGATION_CRITERIA,
    name: attributeName,
    value: attributeValue,
    isActive,
    attributeIndex,
    userActionIndex,
  };
}

export function getActivitiesList() {
  return {
    type: constants.GET_ACTIVITIES
  };
}
export function deleteFilterUserActionFromFilter(userActionIndex) {
  return {
    type: constants.DELETE_USER_ACTION_FROM_FILTER,
    userActionIndex
  };
}
export function exportFilteredPopulation() {
  return {
    type: constants.EXPORT_FILTERED_POPULATION
  };
}
export function deleteFilterUserActionCriteriaFromFilter(userActionIndex, conditionIndex) {
  return {
    type: constants.DELETE_FILTER_MEMBERSHIP_CONDITION,
    conditionParent: constants.FILTERS_USER_ACTION,
    userActionIndex,
    conditionIndex
  };
}
export function updateFilterUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone) {
  return {
    type: constants.UPDATE_DATE_RANGE_DATE,
    dateObject,
    isStart,
    localeTimeFormat,
    conditionParent: constants.FILTERS_USER_ACTION,
    allowInfinite,
    userActionIndex,
    businessTimezone,
  };
}
export function updateFilterMembersUserActionAttribute(attributeName, attributeValue, conditionIndex, userActionIndex, conditionParent) {
  return {
    type: constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_FIELD,
    conditionParent,
    name: attributeName,
    value: attributeValue,
    conditionIndex,
    userActionIndex,
  };
}
export function clearFilterFields() {
  return {
    type: constants.CLEAR_FILTER_CONDITION_FIELDS,
  };
}
export function filterAllMembers() {
  return {
    type: constants.FILTER_ALL_MEMBERS,
  };
}
export function updateFilterMembersUserActionType(userActionType, userActionIndex, businessTimezone) {
  return {
    type: constants.FILTER_MEMBERS_UPDATE_FILTER_USER_ACTION_TYPE,
    userActionType,
    userActionIndex,
    businessTimezone
  };
}
export function addUserActionCriteriaToFilterMembers(userActionIndex) {
  return {
    type: constants.ADD_USER_ACTION_CRITERIA_TO_FILTER_MEMBERS,
    userActionIndex
  };
}

export function createFilterReferralCodeTempModel(userActionIndex, conditionIndex) {
  return {
    type: constants.CREATE_FILTER_REFERRAL_CODE_TEMP_MODEL,
    userActionIndex,
    conditionIndex
  };
}

export function deleteFilterReferralCodeTempModel() {
  return {
    type: constants.DELETE_FILTER_REFERRAL_CODE_TEMP_MODEL
  };
}

export function saveFilterReferralCodeTempModelToGlobalConditions(userActionIndex, conditionIndex) {
  return {
    type: constants.SAVE_FILTER_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS,
    userActionIndex,
    conditionIndex
  };
}

export function updateFilterMembershipConditionField(name, value, index) {
  return {
    type: constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_FIELD,
    name,
    value,
    conditionIndex: index,
    conditionParent: constants.FILTER_MEMBERS_TEMP_MODEL,
  };
}

export function addFilterMembershipConditionField() {
  return {
    type: constants.ADD_FILTER_GLOBAL_MEMBERSHIP_CONDITION
  };
}

export function updateConditionsOperator(value) {
  return {
    type: constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ATTRIBUTE_OPERATOR,
    value
  };
}

export function updateConditionsActionOperator(value) {
  return {
    type: constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ACTION_OPERATOR,
    value
  };
}

export function goToFilterMembers() {
  return {
    type: constants.GO_TO_FILTER_MEMBERS
  };
}

export function updateDefaultFilterWithAccessGroup(value) {
  return {
    type: constants.UPDATE_EMPTY_FILTER_WITH_ACCESS_GROUP,
    value
  }
}
