import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon, Image, Table} from 'semantic-ui-react';
import { Ref } from '@stardust-ui/react-component-ref';
import { injectIntl } from 'react-intl';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import ActionView from '../../Activity/views/ActionView';
import GiftHistoryLog from './GiftHistoryLog';
import GiftContextMenu from './GiftContextMenu';
import GiftStats from './GiftStats';
import GiftRelatedActivities from './GiftRelatedActivities';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as activityConstants from '../../Activity/activity.constants';
import * as imagesConstants from '../../Images/images.constants';
import * as activitySelectorsUtils from '../../Activity/activity.selectors.utils';

class GiftRow extends React.PureComponent {

  static propTypes = {
    changeActivityStatus: PropTypes.func.isRequired,
    onDeleteGift: PropTypes.func.isRequired,
    goToGift: PropTypes.func.isRequired,
    openGiftInNewTab: PropTypes.func.isRequired,
    highlightedGiftId: PropTypes.string,
    gift: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    thumbImage: PropTypes.object,
    showHQTag: PropTypes.bool,
  };

  state = {
    isMouseEnter: false,
    isDetailsModalOpen: false,
    isAnalysisModalOpen: false
  };

  handleOnMouseEnter = () => {
    this.setState({
      isMouseEnter: true
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      isMouseEnter: false
    });
  };

  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };

  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };

  handleAnalysisModalOpen = () => {
    this.setState({
      isAnalysisModalOpen: true
    });
  };

  handleAnalysisModalClose = () => {
    this.setState({
      isAnalysisModalOpen: false
    });
  };

  handleUsagesModalOpen = () => {
    this.setState({
      isUsagesModalOpen: true
    });
  };

  handleUsagesModalClose = () => {
    this.setState({
      isUsagesModalOpen: false
    });
  };

  handleRef = (node) => {
    const { highlightedGiftId, gift } = this.props;
    if (highlightedGiftId && highlightedGiftId === gift.get(activityConstants.HUB_ID)) {
      window.scrollTo({
        top: node ? node.getBoundingClientRect().top : 0,
        behavior: 'smooth'
      });
    }
  };

  render() {
    const { gift, rowIndex, changeActivityStatus, highlightedGiftId, thumbImage, openGiftInNewTab } = this.props;
    const { isMouseEnter, isDetailsModalOpen, isAnalysisModalOpen, isUsagesModalOpen } = this.state;
    const giftName = gift.get(activityConstants.NAME);
    const giftLandingPage = gift.get(activityConstants.LANDING_PAGE_DATA);
    const rowHistoryLog = gift.get(activityConstants.HISTORY_LOG);
    const rowViewStatus = activitySelectorsUtils.calculateActivityViewStatus(gift);
    const rowClass = classNames('gift-row holding-absolute-elem', {
      'disable-pointer-events': rowViewStatus === activityConstants.ACTIVITY_STATUS_INVALID,
      'clickable': rowViewStatus !== activityConstants.ACTIVITY_STATUS_INVALID,
      'highlighted': highlightedGiftId && gift.get(activityConstants.HUB_ID) === highlightedGiftId,
    });

    const giftDetailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: gift.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: gift.get(activityConstants.SERVER_ID),
      [trackerConstants.ACTIVITY_STATUS]: gift.get(activityConstants.STATUS),
      [trackerConstants.ACTIVITY_NAME]: gift.get(activityConstants.NAME),
      [trackerConstants.ACTIVITY_TYPE]: gift.get(activityConstants.ACTIVITY_TYPE_GIFT),
    };

    return (
      <Ref innerRef={this.handleRef}>
        <Table.Row
          data-automation-id={`gifts-page.campaign.row.${rowIndex}`}
          className={rowClass}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
        >
          <GiftHistoryLog
            name={giftName}
            open={isDetailsModalOpen}
            thumbImage={thumbImage}
            onConfirm={this.handleDetailsModalClose}
            historyLog={rowHistoryLog}
            migrated={gift.get(activityConstants.ACTIVITY_MIGRATED)}
          />
          <GiftStats
            gift={gift}
            onConfirm={this.handleAnalysisModalClose}
            thumbImage={thumbImage}
            open={isAnalysisModalOpen}
          />
          <GiftRelatedActivities
            gift={gift}
            onConfirm={this.handleUsagesModalClose}
            thumbImage={thumbImage}
            open={isUsagesModalOpen}
          />
          <Table.Cell
            onClick={tracker.wrapButtonAction(() => this.props.goToGift(gift.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_GIFT_LOADED,
              giftDetailsForTracker)
            }
          >
            {thumbImage && <Image
              className="gift-mini-thumbnail"
              src={thumbImage ? thumbImage.get(imagesConstants.URL) : ''}
            />}
          </Table.Cell>
          <Table.Cell
            className="listcontent"
            title={giftName}
            onClick={tracker.wrapButtonAction(() => this.props.goToGift(gift.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_GIFT_LOADED,
              giftDetailsForTracker)
            }
          >
            {giftName}
          </Table.Cell>
          <Table.Cell
            className="listcontent"
            onClick={tracker.wrapButtonAction(() => this.props.goToGift(gift.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_GIFT_LOADED,
              giftDetailsForTracker)
            }
          >
            <ActionView actionsList={activitySelectorsUtils.reduceActionsForSummary(gift)} />
          </Table.Cell>
          <Table.Cell
            onClick={tracker.wrapButtonAction(() => this.props.goToGift(gift.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_GIFT_LOADED,
              giftDetailsForTracker)
            }
          >
            <ActivityStatus status={rowViewStatus} messagesNamespace="gift" />
          </Table.Cell>
          <Table.Cell className={"listcontent holding-absolute-elem"}>
            { giftLandingPage && giftLandingPage.get(activityConstants.LANDING_PAGE_ACTIVATION_STATUS)
              && <Icon className="como-svg-share-gift-badge" />
            }
            { this.props.showHQTag && <Icon className="como-svg-access-group-hq-badge" /> }


          </Table.Cell>
          <Table.Cell className="listcontent holding-absolute-elem" textAlign="right">
            <GiftContextMenu
              hidden={!isMouseEnter}
              onChangeActivityStatus={(newActivity) => {
                const eventType = newActivity.get(activityConstants.STATUS) === activityConstants.ACTIVITY_STATUS_INACTIVE
                  ? trackerConstants.BUTTON_TYPE_STOP_ACTIVITY
                  : trackerConstants.BUTTON_TYPE_RE_ACTIVATE_ACTIVITY;
                tracker.onButtonClick(eventType, giftDetailsForTracker);
                changeActivityStatus(newActivity);
              }}
              onDetailsOpen={
                tracker.wrapButtonAction(this.handleDetailsModalOpen, trackerConstants.BUTTON_TYPE_SHOW_ACTIVITY_DETAILS, giftDetailsForTracker)
              }
              onAnalysisOpen={
                tracker.wrapButtonAction(this.handleAnalysisModalOpen, trackerConstants.BUTTON_TYPE_SHOW_GIFT_ANALYSIS, giftDetailsForTracker)
              }
              onUsagesOpen={
                tracker.wrapButtonAction(this.handleUsagesModalOpen, trackerConstants.BUTTON_TYPE_SHOW_GIFT_USAGES, giftDetailsForTracker)
              }
              onDeleteGift={
                tracker.wrapButtonAction(this.props.onDeleteGift, trackerConstants.BUTTON_TYPE_DELETE_ACTIVITY, giftDetailsForTracker)
              }
              onOpenInNewTab={
                tracker.wrapButtonAction(()=> openGiftInNewTab(gift), trackerConstants.BUTTON_TYPE_OPEN_IN_NEW_TAB, giftDetailsForTracker)
              }
              gift={gift}
              automationId={`gifts-page.gift.${rowIndex}.gift-context-menu`}
            />
          </Table.Cell>
        </Table.Row>
      </Ref>
    );
  }
}

export default injectIntl(GiftRow);
