import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import AutoDirectionProvider from 'react-with-direction/dist/AutoDirectionProvider';

import * as activityConstants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import * as pointsInputField from '../../../common/components/FormInputFields/PointsInputField/PointsInputField';
import * as itemsPopulationUtils from './itemsPopulation/itemsPopulation.utils';
import * as itemsPopulationActions from './itemsPopulation/itemsPopulation.actions';
import * as benefitConstants from '../../Benefits/benefits.constants';
import * as mentionsSelectors from '../activitySchema/mentions.selectos';
import * as benefitsSelectors from '../../Benefits/benefits.selector';
import * as appSelectors from '../../App/selectors';
import * as itemGroupsSelectors from '../../ItemGroups/itemGroups.selectors';
import { transformMentionText } from '../../../utils/textFormat';

class ActionView extends React.PureComponent {

  static propTypes = {
    actionsList: PropTypes.object.isRequired,
    activityType: PropTypes.string,
    intl: PropTypes.object.isRequired,
    decimalAmount: PropTypes.number.isRequired,
    itemGroups: PropTypes.object,
    benefits: PropTypes.object,
    mentions: PropTypes.object,
    abTestMode: PropTypes.bool,
    benefitsFetched: PropTypes.bool,
    itemGroupsFetched: PropTypes.bool
  };

  render() {
    const { actionsList, activityType, decimalAmount, itemGroups, benefits } = this.props;
    const { formatMessage } = this.props.intl;

    if (this.props.abTestMode) {
      return formatMessage({ id: 'activity.action.startABTest' });
    }
    const actionsTranslations = actionsList.map((action) => {
      const actionType = action.get(activityConstants.ACTION_TYPE);
      let formatArgs = action.delete(activityConstants.ACTION_TYPE);
      let actionTranslationKey = actionType;

      switch (actionType) {
        case schemaConstants.ACCUMULATE_POINTS_ACTION: {
          actionTranslationKey = `${actionType}${formatArgs.get(activityConstants.BUDGET_TYPE) === activityConstants.ADD_POINTS_WALLET_BUDGET ? '.budget' : ''}.${formatArgs.get(activityConstants.TYPE)}`;

          const accumulationType = formatArgs.get(activityConstants.TYPE);
          const points = formatArgs.get(activityConstants.POINTS);

          if (decimalAmount === pointsInputField.defaultPrecision) {
            if (accumulationType === activityConstants.ACCUMULATE_POINTS_TYPE_FIXED
              || accumulationType === activityConstants.ACCUMULATE_POINTS_TYPE_RATE) {
              if (!isNaN(points)) {
                formatArgs = formatArgs.set(activityConstants.POINTS, points / pointsInputField.defaultPrecisionPow);
              }
            }
          }
          const pointsPerRate = formatArgs.get(activityConstants.POINTS_PER_RATE);
          if (accumulationType === activityConstants.ACCUMULATE_POINTS_TYPE_RATE && !isNaN(pointsPerRate)) {
            formatArgs = formatArgs.set(activityConstants.POINTS_PER_RATE, pointsPerRate / 100);
          }

          if (accumulationType === activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE && decimalAmount === 0) {
            if (!isNaN(points)) {
              formatArgs = formatArgs.set(activityConstants.POINTS, points * pointsInputField.defaultPrecisionPow);
            }
          }
          break;
        }
        case schemaConstants.TOP_UP_POINTS_ACTION:
        case schemaConstants.DEDUCT_DYNAMIC_POINTS_ACTION:
        case schemaConstants.ADD_DYNAMIC_POINTS_ACTION: {
          const accumulationType = formatArgs.get(activityConstants.POINTS_TYPE);
          actionTranslationKey = `${actionType}.${accumulationType}`;
          const points = formatArgs.get(activityConstants.POINTS);
          if (decimalAmount === pointsInputField.defaultPrecision) {
            if (accumulationType === activityConstants.ACCUMULATE_POINTS_TYPE_FIXED) {
              if (!isNaN(points)) {
                formatArgs = formatArgs.set(activityConstants.POINTS, points / pointsInputField.defaultPrecisionPow);
              }
            }
          }
          break;
        }
        case schemaConstants.DEDUCT_POINTS_ACTION:
        case schemaConstants.ADD_POINTS_ACTION: {
          actionTranslationKey = `${actionTranslationKey}${formatArgs.get(activityConstants.BUDGET_TYPE) === activityConstants.ADD_POINTS_WALLET_BUDGET ? '.budget' : ''}`;

          const points = formatArgs.get(activityConstants.POINTS);
          const pointsType = formatArgs.get(activityConstants.TYPE);
          if (!isNaN(points) && decimalAmount === pointsInputField.defaultPrecision && pointsType !== activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE) {
            formatArgs = formatArgs.set(activityConstants.POINTS, points / pointsInputField.defaultPrecisionPow);
          }
          if (pointsType === activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE) {
            actionTranslationKey = `${actionTranslationKey}${'.percentage'}`;
          }

          break;
        }
        case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION: {
          const discountValue = action.get(activityConstants.DISCOUNT_VALUE);
          if (!isNaN(discountValue)) {
            formatArgs = formatArgs.set(activityConstants.DISCOUNT_VALUE, discountValue / 100);
          }
          break;
        }
        case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION: {
          if (action.get(activityConstants.BUNDLE_ITEMS_MODE) === activityConstants.BUNDLE_ITEMS_MODE_ENTIRE) {
            actionTranslationKey = 'discountPercentOffOnEntireRecurringBundle';
          }
          break;
        }
        case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION: {
          const discountValue = action.get(activityConstants.DISCOUNT_VALUE);
          if (!isNaN(discountValue)) {
            formatArgs = formatArgs.set(activityConstants.DISCOUNT_VALUE, discountValue / 100);
          }
          if (action.get(activityConstants.BUNDLE_ITEMS_MODE) === activityConstants.BUNDLE_ITEMS_MODE_ENTIRE) {
            actionTranslationKey = 'discountAmountOffOnEntireRecurringBundle';
          }
          break;
        }
        case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION: {
          const discountValue = action.get(activityConstants.DISCOUNT_VALUE);
          if (!isNaN(discountValue)) {
            formatArgs = formatArgs.set(activityConstants.DISCOUNT_VALUE, discountValue / 100);
          }
          if (!this.props.itemGroupsFetched) {
            return null;
          }
          formatArgs = formatArgs.set(activityConstants.DISCOUNT_ON,
            itemsPopulationUtils.getItemsPopulationDisplayList(
              action.get(activityConstants.DISCOUNT_ON), itemGroups, formatMessage));
          break;
        }
        case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION: {
          if (!this.props.itemGroupsFetched) {
            return null;
          }
          formatArgs = formatArgs.set(activityConstants.DISCOUNT_ON,
            itemsPopulationUtils.getItemsPopulationDisplayList(
              action.get(activityConstants.DISCOUNT_ON), itemGroups, formatMessage));
          break;
        }
        case schemaConstants.DISCOUNT_FREE_ITEMS_ACTION: {
          if (!this.props.itemGroupsFetched) {
            return null;
          }
          formatArgs = formatArgs.set(activityConstants.DISCOUNT_ON,
            itemsPopulationUtils.getItemsPopulationDisplayList(action.get(activityConstants.DISCOUNT_ON),
              itemGroups, formatMessage));
          if (activityType === activityConstants.SERVER_TYPE_PUNCH_CARD) {
            actionTranslationKey = `${activityConstants.ACTIVITY_TYPE_PUNCH_CARD}.${actionType}`;
          }
          break;
        }
        case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION: {
          const discountValue = action.get(activityConstants.DISCOUNT_VALUE);
          if (!isNaN(discountValue)) {
            formatArgs = formatArgs.set(activityConstants.DISCOUNT_VALUE, discountValue / 100);
          }
          break;
        }
        case schemaConstants.DISCOUNT_SPECIAL_PRICE_ACTION: {
          const discountValue = action.get(activityConstants.DISCOUNT_VALUE);
          if (!isNaN(discountValue)) {
            formatArgs = formatArgs.set(activityConstants.DISCOUNT_VALUE, discountValue / 100);
          }
          if (!this.props.itemGroupsFetched) {
            return null;
          }
          formatArgs = formatArgs.set(activityConstants.DISCOUNT_ON,
            itemsPopulationUtils.getItemsPopulationDisplayList(
              action.get(activityConstants.DISCOUNT_ON), itemGroups, formatMessage));
          break;
        }
        case schemaConstants.SEND_MEMBER_SMS_ACTION:
          if (action.get('sendSmsWithImage')) {
            actionTranslationKey = 'sendMemberMms';
          }
          formatArgs = formatArgs.set(activityConstants.SMS_MESSAGE,
            transformMentionText(action.get(activityConstants.SMS_MESSAGE), this.props.mentions));

          break;
        case schemaConstants.SEND_MESSAGE_ACTION:
          formatArgs = formatArgs.set(activityConstants.POPUP_MESSAGE,
            transformMentionText(action.get(activityConstants.POPUP_MESSAGE), this.props.mentions));
          break;

        case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
        case schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION:
          formatArgs = formatArgs.set(activityConstants.PUSH_MESSAGE,
            transformMentionText(action.get(activityConstants.PUSH_MESSAGE), this.props.mentions));
          break;
        case schemaConstants.SEND_MEMBER_EMAIL_ACTION:
          formatArgs = formatArgs.set(activityConstants.NAME, action.get(activityConstants.NAME));
          break;
        case schemaConstants.AUTO_PUNCH_ACTION: {
          const punchCardKey = action.get(activityConstants.PUNCH_CARD_KEY);
          if (!this.props.benefitsFetched || !punchCardKey) {
            return null;
          }
          const asset = benefitsSelectors.getSelectedBenefitFromList(benefits, punchCardKey);
          formatArgs = formatArgs.set(benefitConstants.PUNCH_CARD_OLD,
            asset ? asset.get(benefitConstants.BENEFIT_TITLE) : '');
          break;
        }
        case schemaConstants.ASSIGN_ASSET_ACTION: {
          const assignAssetBenefitKey = action.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD);
          if (!this.props.benefitsFetched || !assignAssetBenefitKey) {
            return null;
          }
          const asset = benefitsSelectors.getSelectedBenefitFromList(benefits, assignAssetBenefitKey);
          formatArgs = formatArgs.set(benefitConstants.GIFT,
            asset ? asset.get(benefitConstants.BENEFIT_TITLE) : '');
          break;
        }
        case schemaConstants.UNSUBSCRIBE_EMAIL_OR_SMS: {
          const unsubscribeType = action.get(schemaConstants.UNSUBSCRIBE_TYPE);
          if (!unsubscribeType) {
            return null;
          }
          actionTranslationKey = unsubscribeType;
          break;
        }
        case schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION: {
          const updateType = action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE);
          switch (updateType) {
            case 'sms':
              actionTranslationKey += `.${updateType}.${action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD) ? 'allow' : 'disallow'}`;
              break;
            case 'email':
              actionTranslationKey += `.${updateType}.${action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD) ? 'allow' : 'disallow'}`;
              break;
            case 'consent':
            default:
              actionTranslationKey += `.${updateType}`;
              break;
          }
          break;
        }
        case '':
          return null;

        default:
          break;
      }

      // stringify message arguments (otherwise values like 0 are ignored):
      const formatArgsAsStringsMap = formatArgs.reduce((accumulator, value, key) => {
        accumulator[key] = String(value);
        return accumulator;
      }, {});

      return formatMessage(
        {
          id: `loyalty.campaign.activity.action.${actionTranslationKey}`,
          defaultMessage: actionType
        },
        { ...formatArgsAsStringsMap }
      );
    });

    const text = actionsTranslations.filter((t) => !!t).join(', ');

    return (
      <AutoDirectionProvider text={text}>
        <div className="overflow-ellipsis" title={text}>{text}</div>
      </AutoDirectionProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  benefits: benefitsSelectors.getBenefitsList(state),
  decimalAmount: appSelectors.getDecimalPrecision(state),
  itemGroups: itemGroupsSelectors.getItemGroupsList(state),
  mentions: mentionsSelectors.getAllMentionsUnsortedTransformed(state),
  benefitsFetched: benefitsSelectors.getBenefitsListFetchedFlag(state),
  itemGroupsFetched: itemGroupsSelectors.getItemGroupsListFetchedFlag(state),
});

const mapDispatchToProps = (dispatch) => {
  const creators = {
    ...itemsPopulationActions
  };
  return { actions: bindActionCreators(creators, dispatch) };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect, injectIntl)(ActionView);
