import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { Helmet } from "react-helmet";

import injectSaga from '../../utils/injectSaga';
import pointShopSaga from './pointShop.saga';
import * as reducerConstants from '../../constants/reducer.constants';
import * as selectors from './pointShop.selectors';
import * as pointShopActions from './pointShop.actions';
import * as pointShopItemActions from '../PointShopItem/pointShopItem.actions';
import * as businessCenterAction from '../BusinessCenter/businessCenter.actions';
import imagesSaga from '../Images/images.saga';
import { getSideNavbarSettings } from '../BusinessCenter/businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import SideNavBar from '../SideNavBar/SideNavBar';
import PointShopPageToolbar from './views/PointShopPageToolbar';
import PointShopTable from './views/PointShopTable';
import './pointShop.styles.less';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import tracker from '../../common/utils/tracking/tracker';
import * as benefitsConstants from '../Benefits/benefits.constants';
import PopupWrapper from '../../common/components/PopupWrapper/PopupWrapper';
import CustomMenu from '../../common/components/CustomMenu/CustomMenu';
import PointShopAnalysis from './views/pointShop.analysis.modal';
import * as activityConstants from '../Activity/activity.constants';
import businessCenterSaga from '../BusinessCenter/businessCenter.saga';
import * as appConstants from '../App/app.constants';
import * as appSelectors from '../App/selectors';

class PointShopContainer extends React.PureComponent {


  static propTypes = {
    actions: PropTypes.object.isRequired,
    pointShopItems: PropTypes.object.isRequired,
    pointShopItemsLoaded: PropTypes.bool,
    sort: PropTypes.string,
    intl: PropTypes.object,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    businessDisplayName: PropTypes.string,
    userAccessGroups: PropTypes.object,
  };
  state = {
    menuOpen: false,
    analysisModalOpen: false,
  };

  componentDidMount() {
    if (!this.props.hasFullPermission(appConstants.FEATURE_NAME_POINTS_SHOP)) {
      this.props.actions.goToUpgradePage('pointShop');
    } else {
      this.props.actions.getPointShopItems();
      tracker.onPageLoaded(trackerConstants.PAGE_TYPE_POINT_SHOP);
    }
  }

  wrapOptionsCallbackWithCloseMenuAction = (options) =>
    options.map((opt) => ({
      ...opt,
      cb: () => {
        opt.cb();
        this.handleOnClose();
      }
    }));

  handleOnClose = () => {
    this.setState({
      menuOpen: false
    });
  };
  handleOnOpen = () => {
    this.setState({
      menuOpen: true
    });
  };
  deactivate = (item) => {
    const itemToUpdate = item.toJS();
    itemToUpdate.status = benefitsConstants.BENEFIT_INACTIVE;
    this.props.actions.updatePointShopItemStatus(itemToUpdate);
  };

  delete = (id) => {
    this.props.actions.deletePointShopItem(id);
  };

  getMenuOptions() {
    const { formatMessage } = this.props.intl;
    return [{
      key: activityConstants.CONTEXT_MENU_OPTION_STATS,
      iconName: 'como-ic-activity-analysis',
      text: formatMessage({ id: 'point-shop-items-page.activity.menu.analysis' }),
      cb: this.handleShowStatsClick
    }];
  }

  handleShowStatsClick = () => {
    // tracker.onButtonClick(trackerConstants.BUTTON_TYPE_DELETE_ACTIVITY, this.props.detailsForTracker);
    // this.props.onStatsOpen(this.props.promoCode.get(activityConstants.HUB_ID));
    this.setState({ analysisModalOpen: true });
  };
  handleCloseStatsModal = () => {
    this.setState({ analysisModalOpen: false });
  };

  render() {
    const { pointShopItems } = this.props;
    const { formatMessage } = this.props.intl;
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);
    return (
      <div className="point-shop-page">
        <Helmet>
          <title>Point Shop{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems}/>
        <div className="point-shop-container">
          <PointShopPageToolbar/>
          <div className="point-shop-list">
            <PopupWrapper
              className="no-padding no-border"
              on="click"
              position="bottom right"
              open={this.state.menuOpen}
              onOpen={this.handleOnOpen}
              onClose={this.handleOnClose}
              automationId={`point-shop-items.contextual-menu.popup`}
              trigger={
                <Icon
                  className="como-ic-more"
                  data-automation-id={`point-shop-items-icon.contextual-menu`}
                />
              }
            >
              <CustomMenu
                AutomationIdPrefix={`point-shop-items-menu.contextual-menu`}
                data={this.getMenuOptions()}
              />
            </PopupWrapper>
            <Button
              className="create-point-shop-item-button"
              data-automation-id="gifts-page.create-button"
              onClick={tracker.wrapButtonAction(() => {
                this.props.history.push({ search: this.props.location.search, pathname: '/pointShop/create' });
              }, trackerConstants.BUTTON_TYPE_POINT_SHOP_ITEM_CREATE)}
            >
              <Icon className="como-ic-plus"/>
              <span>{formatMessage({ id: 'point-shop-page.add_item' })}</span>
            </Button>
            <PointShopTable
              location={this.props.location}
              history={this.props.history}
              items={pointShopItems}
              deactivate={this.deactivate}
              delete={this.delete}
              openPointShopInNewTab={this.props.actions.openActivityInNewTab}
              hasFullPermission={this.props.hasFullPermission}
              userAccessGroups={this.props.userAccessGroups}
            />
          </div>
        </div>
        {this.state.analysisModalOpen &&
          <PointShopAnalysis pointShopItems={pointShopItems} open={this.state.analysisModalOpen}
                             onClose={this.handleCloseStatsModal}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pointShopItems: selectors.getPointShopItems(state),
  businessDisplayName: appSelectors.getBusinessDisplayName(state),
  userAccessGroups: appSelectors.getUserAccessGroups(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...pointShopActions, ...pointShopItemActions, ...businessCenterAction }, dispatch),

  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.POINT_SHOP_BRANCH, saga: pointShopSaga });
const withImagesSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });
const withBusinessCenterSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessCenterSaga });

export default compose(
  withConnect,
  withSaga,
  withImagesSaga,
  withBusinessCenterSaga,
  injectIntl,
  withAuthorization
)(PointShopContainer);
