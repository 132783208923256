import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import PointShopRow from './PointShopRow';
import * as activityConstants from '../../Activity/activity.constants';
import * as appConstants from '../../App/app.constants';

class PointShopTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.object.isRequired,
    changeActivityStatus: PropTypes.func,
    openPointShopInNewTab: PropTypes.func.isRequired,
    onDeleteGift: PropTypes.func,
    goToGift: PropTypes.func,
    highlightedGiftId: PropTypes.string,
    thumbImages: PropTypes.object,
    hasFullPermission: PropTypes.func,
    userAccessGroups: PropTypes.object,
  };

  goToItem = (item) => {
    const isAccessGroupEnabled = this.props.hasFullPermission(appConstants.FEATURES_ACCESS_GROUPS)
    const userHasAccessGroups = this.props.userAccessGroups && this.props.userAccessGroups.size > 0;
    const selectedGift = this.props.items.find(x => x.get(activityConstants.HUB_ID) === item.hubId);

    if (isAccessGroupEnabled && userHasAccessGroups && !selectedGift.get(activityConstants.ACCESS_GROUP_IDS)) {
      return;
    }

    this.props.history.push({ search: this.props.location.search, pathname: `/pointShop/${item.hubId}`, state: { item } });
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="list-column-name point-shop-picture-column" />
            <Table.HeaderCell
              className="list-column-name point-shop-name-column"
            >{formatMessage({ id: 'point-shop-page.item.name' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name point-shop-price-column"
            >{formatMessage({ id: 'point-shop-page.item.price' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name point-shop-status-column"
            >{formatMessage({ id: 'point-shop-page.item.status' })}</Table.HeaderCell>
            <Table.HeaderCell className="point-shop-context-badge-column" />
            <Table.HeaderCell className="point-shop-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.items.map((item, index) => {
            return (
              <PointShopRow
                key={index}
                delete={this.props.delete}
                deactivate={this.props.deactivate}
                goToItem={this.goToItem}
                item={item}
                rowIndex={index}
                openPointShopInNewTab={this.props.openPointShopInNewTab}
                hasFullPermission={this.props.hasFullPermission}
              />
            );
          }, this)}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(PointShopTable);
