import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import ActivitiesRow from './ActivityRow';

class ActivitiesTable extends React.PureComponent {
  static propTypes = {
    activities: PropTypes.object.isRequired,
    activityMoveTo: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    campaignOrderIndex: PropTypes.number.isRequired,
    changeActivityStatus: PropTypes.func.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    goToActivity: PropTypes.func.isRequired,
    openActivityInNewTab: PropTypes.func.isRequired,
    highlightedActivityId: PropTypes.string,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    businessTimezone: PropTypes.string,
    unpinActivity: PropTypes.func.isRequired,
    pinActivity: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
    userAccessGroups: PropTypes.object,
  };

  render() {
    const { campaignOrderIndex, activities, changeActivityStatus, deleteActivity, campaignId, activityMoveTo, highlightedActivityId, businessTimezone, openActivityInNewTab } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="list-column-name activity-fav-column"></Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-type-column">{formatMessage({ id: 'business-center.campaign.type', defaultMessage: 'type' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-name-column">{formatMessage({ id: 'business-center.campaign.activity', defaultMessage: 'activity' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-trigger-column">{formatMessage({ id: 'business-center.campaign.whenMember', defaultMessage: 'When member' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-actions-column">{formatMessage({ id: 'business-center.campaign.actions', defaultMessage: 'actions' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-status-column">{formatMessage({ id: 'business-center.campaign.status', defaultMessage: 'status' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name activity-badge-column" />
            <Table.HeaderCell className="activity-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {activities.map((activity, index) => (
            <ActivitiesRow
              localeDateFormat={this.props.localeDateFormat}
              localeTimeFormat={this.props.localeTimeFormat}
              activityMoveTo={activityMoveTo}
              campaignOrderIndex={campaignOrderIndex}
              campaignId={campaignId}
              changeActivityStatus={changeActivityStatus}
              deleteActivity={deleteActivity}
              goToActivity={this.props.goToActivity}
              highlightedActivityId={highlightedActivityId}
              key={index.toString()}
              activity={activity}
              rowIndex={index}
              businessTimezone={businessTimezone}
              openActivityInNewTab={openActivityInNewTab}
              unpinActivity={this.props.unpinActivity}
              pinActivity={this.props.pinActivity}
              openToast={this.props.openToast}
              userAccessGroups={this.props.userAccessGroups}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(ActivitiesTable);
