import * as routeConstants from '../../constants/route.contants';

export function getDataAndBiSideNavbarSettings(formatMessage, location, isFranchises, hideDataExport = true, isComoUser = true) {
  return [
    {
      id: 'databi',
      text: formatMessage({ id: 'data-and-bi.dashboard.title' }),
      target: { search: location.search, pathname: routeConstants.DATA_BI_ROUTE },
      automationId: 'navigation.campaign.center',
      icon: 'new-dashboard-icon'
    },
    {
      id: 'databi.looker',
      text: formatMessage({ id: 'data-and-bi.looker.title' }),
      target: { search: location.search, pathname: routeConstants.DATA_BI_LOOKER_ROUTE },
      automationId: 'navigation.campaign.center',
      icon: 'looker'
    },
    {
      id: 'databi.lookerDashboards',
      text: formatMessage({ id: 'data-and-bi.lookerDashboards.title' }),
      target: { search: location.search, pathname: routeConstants.DATA_BI_LOOKER_DASHBOARDS_ROUTE },
      automationId: 'navigation.campaign.center',
      icon: 'all-dashboards-ic',
      hide: isFranchises
    },
    {
      id: 'databi.campaignAnalysis',
      text: formatMessage({ id: 'data-and-bi.campaignAnalysis.title' }),
      target: { search: location.search, pathname: routeConstants.DATA_CAMPAIGN_ANALYSIS_ROUTE },
      automationId: 'navigation.campaign.center.campaignAnalysis',
      icon: 'all-dashboards-ic',
      hide: !isComoUser
    },
    {
      id: 'databi.filterMembers',
      text: formatMessage({ id: 'filterMembers' }),
      target: { search: location.search, pathname: routeConstants.FILTER_MEMBERS_ROUTE },
      automationId: 'navigation.campaign.center.filter',
      icon: 'como-svg-side-nav-icon-filter-members',
    },
    {
      id: 'databi.dataExport',
      text: formatMessage({ id: 'data-and-bi.export.title' }),
      target: { search: location.search, pathname: routeConstants.DATA_EXPORT_ROUTE },
      automationId: 'navigation.campaign.center.export',
      icon: 'como-ic-export large top-0',
      hide: hideDataExport
    },

  ].filter((f) => !f.hide);
}

export function getFilterPermLevel(getPermissionLevel, permissionKey) {
  return getPermissionLevel(permissionKey);
}
