import { fromJS, List } from 'immutable';
import moment from 'moment';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import * as templatesConstants from '../../Templates/templates.constants';
import * as validationUtils from '../reducers/utils/validations/validation.utils';
import * as defaults from './activity.reducer.defaults';
import { activityActionsReducer } from './activity.actions.reducer';
import { activityConditionsReducer } from './acitivity.conditions.reducer';
import { activityCasesReducer } from './activity.cases.reducer';
import { activityDatetimeConditionsReducer } from './activity.datetime.conditions.reducer';
import { activityItemsPopulationReducer } from './activity.items.population.reducer';
import { activityGlobalConditionsReducer } from './activity.global.conditions.reducer';
import { activityOneTimeActionReducer } from './activity.one.time.action.reducer';
import { activityPunchCardReducer } from './activity.punchCard.reducer';
import * as utils from './utils/activity.reducer.utils';
import * as dateConstants from '../../../common/utils/dateTime.constants';
import { activityPromoCodeReducer } from './activity.promoCode.reducer';
import * as activitySchemaSelectors from '../activitySchema/activitySchema.selectors';
import * as featuresConstants from '../../features.constants';
import * as schemaConditions from '../activitySchema/activitySchema.conditions';
import { occurOnceCondition } from './activity.condition.defaults';
import * as filterConstants from '../../FilterMembers/filterMembers.constants';
import * as membersConstants from '../../Members/members.constants';
import {getAccessGroupNames, isAccessGroupEnabledAndUserHasAccessGroup} from './utils/action.reducer.utils';

export function activityReducer(state = defaults.initialState, action, rootState) {
  const validationOptions = { force: false, validate: false };
  let newState = activityReducerImpl(state, action, validationOptions, rootState);
  if (validationOptions.validate) {
    newState = activityValidationReducerImpl(newState, validationOptions, rootState);
  }
  if (!action.dontMarkModelAsDirty) {
    newState = markAsDirtyIfNeeded(state, newState, action);
  }
  return newState;
}

function activityReducerImpl(state, action, validationOptions, rootState) {
  let newState = state;

  switch (action.type) {

    case constants.CREATE_ACTIVITY: {

      let data;
      switch (action.activityType) {
        case constants.ACTIVITY_TYPE_DEAL: {
          data = defaults.dealData;
          break;
        }
        case constants.ACTIVITY_TYPE_RULE: {
          data = defaults.ruleData;
          break;
        }
        case constants.ACTIVITY_TYPE_ONE_TIME: {
          data = defaults.oneTimeActionData;
          if(isAccessGroupEnabledAndUserHasAccessGroup(rootState)) {
            data = data.setIn([constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS, constants.TYPE], constants.MEMBERS_SPECIFIC);
          }
          break;
        }
        case constants.ACTIVITY_TYPE_PUNCH_CARD: {
          data = defaults.createPunchCardData(action.locationId);
          newState = newState
            .set(constants.ACTIVE_TAB, fromJS(defaults.initialPunchCardTab));
          break;
        }
        case constants.ACTIVITY_TYPE_GIFT: {
          data = defaults.giftData();
          newState = newState
            .set(constants.ACTIVE_TAB, fromJS(defaults.initialGiftTab));
          break;
        }
        case constants.ACTIVITY_TYPE_PROMO_CODE: {
          const selectedPromoCodeType = rootState.getIn(['route', 'location', 'state', 'type']);
          data = defaults.promoCodeData(selectedPromoCodeType);
          newState = newState
            .set(constants.ACTIVE_TAB, fromJS(defaults.initialPromoCodeTab));
          break;
        }
        default:
          break;
      }

      if (action.locationId) {
        data = data.set(constants.LOCATION_ID, action.locationId);
      }
      if (action.campaignKey) {
        data = data.set(constants.CAMPAIGN_KEY, action.campaignKey);
      }
      if (action.templateKey) {
        data = data.set(constants.TEMPLATE_KEY, action.templateKey);
      }
      if (action.goalKey) {
        data = data.set(constants.GOAL_KEY, action.goalKey);
      }

      if(isAccessGroupEnabledAndUserHasAccessGroup(rootState) && action.activityType !== constants.ACTIVITY_TYPE_PROMO_CODE && action.activityType !== constants.ACTIVITY_TYPE_ONE_TIME) {
          data = data.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.TYPE], constants.MEMBERS_SPECIFIC);
          const accessGroupNames = getAccessGroupNames(rootState);
          const tempMembershipStatusCondition = {
            [constants.CONDITION_KEY]: schemaConstants.CONTEXT_MEMBERSHIP_STATUS,
            [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
            [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
            [constants.CONDITION_VALUE]: [
              schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED
            ]
          }
          const conditions = {
            [constants.CONDITIONS_LIST]: [
              {
                [constants.CONDITION_KEY]: constants.CONTEXT_MEMBERSHIP_TAG,
                [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
                [constants.CONDITION_VALUE]: accessGroupNames,
                [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY
              }
            ],
            [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND
          }

          newState = newState.set(constants.IS_ACTIVITY_DIRTY, true)
            .set(constants.TEMP_MEMBERSHIP_STATUS_CONDITION, fromJS(tempMembershipStatusCondition))
            .set(constants.DATA, data.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.CONDITIONS], fromJS(conditions)))
      } else {
        newState = newState.set(constants.DATA, data)
      }

      newState = newState
        .set(constants.ORIGINAL_DATA, data)
        .delete(constants.CHECKING_ACTIVITY_NAME_AVAILABILITY)
        .delete(constants.ACTIVITY_DATA_LOADED)
        .delete(constants.TEMP_ACTION_DATA);
      newState = validationUtils.resetValidations(newState);

      break;
    }

    case constants.SET_ACTIVITY_TYPE_DATA: {
      newState = newState.setIn([constants.DATA, constants.TYPE], fromJS(action.activityType));
      if (action.activityType === constants.ACTIVITY_TYPE_DEAL) {
        newState = newState.setIn([constants.DATA, constants.TRIGGER], fromJS(schemaConstants.TRIGGER_DEAL));
      } else if (action.activityType === constants.ACTIVITY_TYPE_ONE_TIME) {
        newState = newState.setIn([constants.DATA, constants.TRIGGER], schemaConstants.TRIGGER_ONE_TIME_ACTION);
        newState = newState.setIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING], fromJS(defaults.emptyActionScheduling));
      } else if (action.activityType === constants.ACTIVITY_TYPE_PUNCH_CARD) {
        newState = newState.setIn([constants.DATA, constants.TRIGGER], fromJS(schemaConstants.TRIGGER_PUNCH_CARD));
      } else if (action.activityType === constants.ACTIVITY_TYPE_GIFT) {
        newState = newState.setIn([constants.DATA, constants.TRIGGER], fromJS(schemaConstants.TRIGGER_GIFT));
      }
      break;
    }

    case constants.SET_ACTIVITY_SOURCE: {
      newState = newState.setIn([constants.DATA, constants.ACTIVITY_SOURCE], fromJS(action.source));
      break;
    }

    case templatesConstants.LOAD_TEMPLATE_DATA_INTO_ACTIVITY: {
      const { template, campaignKey, locationId } = action.payload;

      let activity = template.get(constants.ACTIVITY);

      if (activity.get(constants.TYPE) === constants.SERVER_TYPE_DEAL) {
        activity = activity.set(constants.TRIGGER, schemaConstants.TRIGGER_DEAL);
      }
      if (activity.get(constants.TYPE) === constants.SERVER_TYPE_GIFT) {
        activity = activity.set(constants.TRIGGER, schemaConstants.TRIGGER_GIFT);
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialGiftTab));
      }
      if (activity.get(constants.TYPE) === constants.SERVER_TYPE_PUNCH_CARD) {
        activity = activity.set(constants.TRIGGER, schemaConstants.TRIGGER_PUNCH_CARD);
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialPunchCardTab));
      }
      if (activity.get(constants.TYPE) === constants.SERVER_TYPE_ONE_TIME_ACTION) {
        activity = activity.set(constants.TRIGGER, schemaConstants.TRIGGER_ONE_TIME_ACTION);
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialPromoCodeTab));
      }

      const activityData = utils.parseActivityData(
        activity
          .set(constants.TEMPLATE_KEY, template.get(templatesConstants.DB_ID))
          .set(constants.LOCATION_ID, locationId)
          .set(constants.CAMPAIGN_KEY, campaignKey)
          .set(constants.TYPE, action.entityType)
          .toJS()
      );
      newState = newState
        .set(constants.DATA, fromJS(activityData))
        .set(constants.TEMPLATE, template.delete(constants.ACTIVITY))
        .delete(constants.TEMP_ACTION_DATA);
      break;
    }

    case constants.CLEAR_DATA_ON_UNMOUNT: {
      newState = newState
        .delete(constants.TEMPLATE)
        .delete(constants.LANDING_PAGE_MODAL_IS_OPEN)
        .delete(constants.TEST_TO_MYSELF)
        .delete(constants.COMILLIA_HINT_OPEN_IN_ACTION);
      break;
    }

    case constants.RESET_ACTIVITY_DATA: {
      newState = utils.resetData(newState);
      break;
    }

    case constants.GET_ACTIVITY_SUCCESS: {
      newState = utils.resetData(newState);
      // save rule data to store
      const activityData = utils.parseActivityData(action.data, activitySchemaSelectors.getSchema(newState));
      const activityDataImmutable = fromJS(activityData);

      newState = newState
        .set(constants.DATA, activityDataImmutable)
        .set(constants.ORIGINAL_DATA, activityDataImmutable)
        .set(constants.ACTIVITY_DATA_LOADED, true);

      if (utils.isDraftOneTimeActionWithFilteredPopulation(activityData)) {
        newState = newState.set(constants.DRAFT_OLD_FILTERED_POPULATION_FLAG, true);
      }

      if (action.data[constants.TYPE] === constants.ACTIVITY_TYPE_PUNCH_CARD) {
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialPunchCardTab));
      }

      if (action.data[constants.TYPE] === constants.ACTIVITY_TYPE_GIFT) {
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialGiftTab));
      }

      if (action.data[constants.TYPE] === constants.ACTIVITY_TYPE_PROMO_CODE) {
        const promoCodeType = newState.getIn([constants.DATA, constants.PROMO_CODE_BULKS_TYPE]);
        if (promoCodeType === constants.PROMO_CODE_MODE_GENERATED) {
          const testBulk = newState.getIn([constants.DATA, constants.PROMO_CODE_BULKS]).toJS().find((c) => c.isTest);
          const testBulkMinSize = testBulk ? testBulk.size : 0;
          newState = newState.set(constants.PROMO_CODE_TEST_BULK_MIN_SIZE, testBulkMinSize);
        }
        newState = newState.set(constants.ACTIVE_TAB, fromJS(defaults.initialPromoCodeTab));
      }

      break;
    }

    case constants.RESET_DRAFT_ONE_TIME_ACTION_WITH_FILTERED_POPULATION_FLAG: {
      newState = newState.set(constants.DRAFT_OLD_FILTERED_POPULATION_FLAG, false);
      break;
    }

    case constants.SET_PUBLISH_OR_SAVE_IN_PROGRESS: {
      newState = newState.set(action[featuresConstants.ACTION_IN_PROGRESS_NAME], action[featuresConstants.ACTION_IN_PROGRESS_VALUE]);
      break;
    }

    case constants.SET_FILTERS_IN_PROGRESS: {
      newState = newState.setIn([constants.FILTERS_IN_PROGRESS], fromJS(action[constants.FILTERS_IN_PROGRESS]));
      return newState;
    }

    case constants.CHECK_ACTIVITY_NAME_AVAILABILITY: {
      newState = newState.set(constants.CHECKING_ACTIVITY_NAME_AVAILABILITY, true);
      break;
    }

    case constants.CHECK_ACTIVITY_NAME_AVAILABILITY_DONE: {
      newState = newState.set(constants.CHECKING_ACTIVITY_NAME_AVAILABILITY, false);
      break;
    }

    case constants.UPDATE_METADATA: {
      newState = newState.setIn([constants.DATA, action.key], action.value);
      break;
    }

    case constants.FORCE_UPDATE_NAME_AFTER_VALIDATION: {
      newState = newState.setIn([constants.DATA, constants.NAME], action.value);
      break;
    }

    case constants.CHECK_ACTIVITY_NAME_AVAILABILITY_SUCCESS: {
      // update metadata before validation
      newState = newState.setIn([constants.DATA, constants.VALIDATION_NAME_TAKEN_FLAG], !action.nameAvailable);
      validationOptions.force = true;
      break;
    }

    case constants.PROMO_CODE_BULK_VALUE_TAKEN: {
      // update metadata before validation
      newState = newState.setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], true);
      validationOptions.force = true;
      break;
    }

    case constants.PROMO_CODE_BULK_ERROR: {
      const errorKey = utils.mapCodesServerErrorsToKeys(action.errorMessage);
      newState = newState.setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_ERROR], errorKey);
      validationOptions.force = true;
      break;
    }

    case constants.UPDATE_TRIGGER: {
      newState = newState
        .setIn([constants.DATA, constants.TRIGGER], action.trigger)
        .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST], List())
        .delete(constants.PENDING_TRIGGER);
      if (action.deleteActionsAndCases) {
        newState = newState
          .setIn([constants.DATA, constants.ACTIONS], fromJS([defaults.emptyActionsWrapper]))
          .deleteIn([constants.DATA, constants.CASES]);
      }

      if (!utils.isTriggerSchedulingRelated(action.trigger)) {
        newState = newState.deleteIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS]);
        if (action.trigger === schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY) {
          newState = newState.deleteIn([constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS]);
        }
      }
      if (utils.isTriggerBenefitsRelated(action.trigger)) {
        newState = newState
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST], fromJS([defaults.emptyCondition]))
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.CONDITION_KEY], schemaConstants.CONTEXT_ASSET_TEMPLATE)
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.CONDITION_VALUE_DATA_TYPE], schemaConstants.DATA_VALUE_TYPE_STRING)
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.OPERATOR_KEY], constants.EQUALS_OPERATOR);
      }
      if (utils.isTriggerTagsRelated(action.trigger)) {
        const tagCondition = schemaConditions.tag;
        newState = newState
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST], fromJS([defaults.emptyCondition]))
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.CONDITION_KEY], tagCondition.key)
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.CONDITION_VALUE_DATA_TYPE], tagCondition.dataValueType)
          .setIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.OPERATOR_KEY], constants.IS_ONE_OF);
      }

      break;
    }

    case constants.CONFIRM_UPDATE_TRIGGER: {
      newState = newState
        .set(constants.PENDING_TRIGGER, fromJS(action.triggerObj));
      break;
    }

    case constants.CLEAR_TEMP_TRIGGER: {
      newState = newState
        .delete(constants.PENDING_TRIGGER);
      break;
    }

    case constants.SET_ACTIVITY_ERRORS: {
      newState = newState
        .set(constants.VALIDATION_ERRORS, action[constants.VALIDATION_ERRORS])
        .setIn([constants.VALIDATION_METADATA, constants.ACTIVITY_VALIDATION_MODE],
          action[constants.ACTIVITY_VALIDATION_MODE])
        .setIn([constants.DATA, constants.VALIDATION_NAME_TAKEN_FLAG],
          action[constants.VALIDATION_NAME_TAKEN_FLAG]);
      newState = utils.toggleCaseCollapsingOnValidationErrors(newState);
      return newState;
    }

    case constants.ADD_TEMP_FILTERS_ERRORS: {
      newState = newState.set(constants.TEMP_FILTERS_ERRORS, action[constants.TEMP_FILTERS_ERRORS]);
      return newState;
    }

    case constants.GET_FEATURES_SUCCESS: {
      newState = state.set(constants.FEATURES, fromJS(action.data));
      break;
    }

    case constants.GET_ACTIVITY_STATS:
    case constants.GET_AB_TEST_STATS: {
      newState = state
        .setIn([constants.ACTIVITY_STATS], null)
        .setIn([constants.ACTIVITY_AB_TEST_STATS], null)
        .setIn([constants.ACTIVITY_STATS_LOADED], false);
      break;

    }

    case constants.GET_ACTIVITY_STATS_SUCCESS: {
      const stats = action.data;
      newState = state.setIn([constants.ACTIVITY_STATS], fromJS(stats));
      break;

    }

    case constants.GET_AB_TEST_STATS_SUCCESS: {
      let stats = action.data;
      if (Array.isArray(stats) && stats.length > 0) {
        stats = utils.groupAbStatsByCategory(stats);
        newState = state.set(constants.ACTIVITY_AB_TEST_STATS, fromJS(stats));
      }
      newState = newState.set(constants.ACTIVITY_STATS_LOADED, true);
      break;
    }

    case constants.MARK_ACTIVITY_DIRTY: {
      newState = state.set(constants.IS_ACTIVITY_DIRTY, fromJS(true));
      break;
    }

    case constants.MARK_ACTIVITY_CLEAN: {
      newState = state.set(constants.IS_ACTIVITY_DIRTY, fromJS(false));
      break;
    }

    case constants.UPDATE_TRIGGER_SCHEDULE_VALUE: {
      newState = state.setIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.TRIGGER_SCHEDULE_VALUE], action.value);
      break;
    }

    case constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT: {
      newState = state.setIn([constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.SCHEDULE], action.value);
      break;
    }

    case constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_VALUE: {
      newState = state.setIn([constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.SCHEDULE, constants.VALUE], action.value);
      break;
    }
    case constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_UNIT: {
      newState = state.setIn([constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.SCHEDULE, constants.UNIT], action.value);
      break;
    }

    case constants.SELECT_ACTIVITY_TAB: {
      return state.set(constants.ACTIVE_TAB, action.tab);
    }

    case constants.UPDATE_DISPLAY_FIELD: {
      const newDataObject = utils.getDataWithUpdatedDisplayFields(state.get(constants.DATA), action.fields);
      return state.set(constants.DATA, newDataObject);
    }

    case constants.GET_MIGRATED_ACTIVITY_DATA_SUCCESS: {
      if (action.activityType === constants.ACTIVITY_TYPE_GIFT) {
        const instanceExpirationGlobalCondition = utils.migrateInstanceExpirationConditions(action.data.activateTime, action.data.activateUnits, action.data.deactivateTime, action.data.deactivateUnits, false);
        const membershipGlobalConditions = utils.migrateMembershipGlobalConditions(action.data.membershipStatus);
        newState = state
          // default data
          .set(constants.DATA, defaults.giftData())
          .setIn([constants.DATA, constants.ACTIVITY_MIGRATED], true)
          .set(constants.ACTIVE_TAB, defaults.initialGiftTab)
          // migration data
          .setIn([constants.DATA, constants.LOCATION_ID], action.data.locationId)
          .setIn([constants.DATA, constants.NAME], action.data.name)
          .setIn([constants.DATA, constants.DESCRIPTION], action.data.description)
          .setIn([constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS], membershipGlobalConditions)
          .setIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, dateConstants.DATE_FROM], moment(action.data.validFrom))
          .setIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, dateConstants.DATE_TO], moment(action.data.validUntil))
          .setIn([constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION], instanceExpirationGlobalCondition)
          .setIn([constants.DATA, constants.PREVIEW_IMAGE_ID], action.data.imageId)
          .setIn([constants.DATA, constants.SERVER_ID], action.data.serverId);
        // scratch card
        if (action.data.tags.indexOf(constants.SCRATCH_CARD_HUB1_TAG) > -1) {
          newState = newState.setIn([constants.DATA, constants.GIFT_INTERACTIVE_TYPE], constants.GIFT_INTERACTIVE_TYPE_SCRATCH);
        }
      } else if (action.activityType === constants.ACTIVITY_TYPE_RULE &&
        (action.data.activityTrigger === schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY || action.data.activityTrigger === schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY)) {
        const membershipGlobalConditions = utils.migrateMembershipGlobalConditions(action.data.membershipStatus);
        newState = state
          .set(constants.DATA, defaults.ruleData)
          .setIn([constants.DATA, constants.ACTIVITY_MIGRATED], true)
          .setIn([constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS], membershipGlobalConditions)
          .setIn([constants.DATA, constants.LOCATION_ID], action.data.locationId)
          .setIn([constants.DATA, constants.NAME], action.data.name)
          .setIn([constants.DATA, constants.TRIGGER], action.data.activityTrigger)
          .setIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.EVENT_SCOPE], action.data.eventScope)
          .setIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.TRIGGER_SCHEDULE_VALUE], action.data.eventTime)
          .setIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS], fromJS(defaults.defaultBirthdayOrAnniversaryOrClubMemberAnniversaryGlobalOccurrencesLimitCondition))
          .setIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS, constants.LIMITATION_TAG], action.data.limitationTag);

      } else if (action.activityType === constants.ACTIVITY_TYPE_PUNCH_CARD) {
        const instanceExpirationGlobalCondition = utils.migrateInstanceExpirationConditions(action.data.activateTime, action.data.activateUnits, action.data.deactivateTime, action.data.deactivateUnits, true);
        const membershipGlobalConditions = utils.migrateMembershipGlobalConditions(action.data.membershipStatus);
        newState = state
          .set(constants.DATA, defaults.createPunchCardData(action.data.locationId))
          .setIn([constants.DATA, constants.ACTIVITY_MIGRATED], true)
          .set(constants.ACTIVE_TAB, defaults.initialPunchCardTab)
          // migration data
          .setIn([constants.DATA, constants.LOCATION_ID], action.data.locationId)
          .setIn([constants.DATA, constants.NAME], action.data.name)
          .setIn([constants.DATA, constants.DESCRIPTION], action.data.description)
          .setIn([constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS], membershipGlobalConditions)
          .setIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, dateConstants.DATE_FROM], moment(action.data.validFrom))
          .setIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, dateConstants.DATE_TO], moment(action.data.validUntil))
          .setIn([constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION], instanceExpirationGlobalCondition)
          .setIn([constants.DATA, constants.PREVIEW_IMAGE_ID], action.data.previewImageId)
          .setIn([constants.DATA, constants.STAMP_IMAGE_ID], action.data.stampImageId)
          .setIn([constants.DATA, constants.BACKGROUND_IMAGE_ID], action.data.imageId)
          .setIn([constants.DATA, constants.REQUIRED_PUNCHES], action.data.requiredPunches)
          .setIn([constants.DATA, constants.SERVER_ID], action.data.serverId);
      }
      return newState;
    }

    case constants.REMOVE_BACK_TO_ACTIVITY_FLAG: {
      return state.delete(constants.BACK_TO_ACTIVITY_IN_STATE);
    }

    case constants.UPDATE_LANDING_PAGE_ACTIVATION_STATUS: {
      const landingPageData = state.getIn([constants.DATA, constants.LANDING_PAGE_DATA]);

      if (landingPageData == null) {
        if (action.isActive) { // First time activation on landing page
          newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA], fromJS({
            [constants.LANDING_PAGE_SHOW_SOCIAL_SECTION]: action.landingPageShowSocialSection,
            [constants.LANDING_PAGE_ACTIVATION_STATUS]: action.isActive,
            [constants.LANDING_PAGE_CHILD_ACTIVITIES]: {
              [constants.LANDING_PAGE_SUBMIT_EVENT_RULE]: {
                [constants.STATUS]: constants.ACTIVITY_STATUS_DRAFT,
                [constants.TRIGGER]: schemaConstants.TRIGGER_EXTERNAL_EVENT,
                [constants.CONDITIONS]: {
                  [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND,
                  [constants.CONDITIONS_LIST]: [
                    {
                      [constants.CONDITION_KEY]: schemaConstants.CONTEXT_DATA_TYPE,
                      [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
                      [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
                      [constants.CONDITION_VALUE]: [constants.LANDING_PAGE_EXTERNAL_EVENT_CONDITION_TYPE]
                    },
                    {
                      [constants.CONDITION_KEY]: schemaConstants.CONTEXT_DATA_SUB_TYPE,
                      [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
                      [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
                      [constants.CONDITION_VALUE]: [constants.LANDING_PAGE_SMART_VALUES_REPLACED_ON_SERVER]
                    }
                  ]
                },
                [constants.MEMBERSHIP_GLOBAL_CONDITIONS]: defaults.defaultGlobalMembershipConditions,
                [constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS]: {
                  [constants.TOTAL_OCCURRENCES_CONDITION]: occurOnceCondition,
                  [constants.TOTAL_OCCURRENCES_CONDITION_ENABLED]: true,
                  [constants.BY_PERIOD_CONDITION_ENABLED]: false,
                  [constants.TYPE]: constants.LIMITED_PER_MEMBER
                },
                [constants.ACTIONS]: [{
                  [constants.ACTIONS_LIST]: [
                    {
                      [constants.ACTION_TYPE]: schemaConstants.ASSIGN_ASSET_ACTION,
                      [schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD]: constants.LANDING_PAGE_SMART_VALUES_REPLACED_ON_SERVER,
                      [schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD]: 1
                    }
                  ],
                  [constants.ACTIONS_MODE]: constants.ACTIONS_SIMPLE_MODE
                }]

              }
            },
            [constants.LANDING_PAGE_VIEW_DIRECTION]: action.landingPageViewDirection,
            [constants.LANDING_PAGE_ENTER_PHONE_TEXT]: action.landingPageEnterPhoneText,
            [constants.LANDING_PAGE_SUCCESS_HEADER_TEXT]: action.landingPageSuccessHeaderText,
            [constants.LANDING_PAGE_SUCCESS_MESSAGE_TEXT]: action.landingPageSuccessMessageText,
            [constants.LANDING_PAGE_EXPIRED_HEADER_TEXT]: action.landingPageExpiredHeaderText,
            [constants.LANDING_PAGE_EXPIRED_MESSAGE_TEXT]: action.landingPageExpiredMessageText,
            [constants.LANDING_PAGE_EXISTING_MEMBER_HEADER_TEXT]: action.landingPageExistingMemberHeaderText,
            [constants.LANDING_PAGE_EXISTING_MEMBER_MESSAGE_TEXT]: action.landingPageExistingMemberMessageText,
            [constants.LANDING_PAGE_FACEBOOK_PIXEL]: action.landingPageFacebookPixel,
            [constants.LANDING_PAGE_GOOGLE_PIXEL]: action.landingPageGooglePixel,
            [constants.LANDING_PAGE_REDIRECT_URL_SUCCESS]: action.landingPageRedirectUrlSuccess,
            [constants.LANDING_PAGE_REDIRECT_URL_FAILURE]: action.landingPageRedirectUrlFailure,
          }));
        }
      } else {
        newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA, constants.LANDING_PAGE_ACTIVATION_STATUS], action.isActive);
      }
      break;
    }

    case constants.UPDATE_LANDING_PAGE_INPUT_TEXT: {
      let landingPageData = state.getIn([constants.DATA, constants.LANDING_PAGE_DATA]);
      landingPageData = landingPageData.set(action.field, action.value);
      newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA], landingPageData);
      break;
    }

    case constants.UPDATE_LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX: {
      let landingPageData = state.getIn([constants.DATA, constants.LANDING_PAGE_DATA]);
      landingPageData = landingPageData.set(constants.LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX, action.enabled);
      newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA], landingPageData);
      break;
    }

    case constants.UPDATE_LANDING_PAGE_SHOW_LEGAL_SECTION_TOGGLE: {
      let landingPageData = state.getIn([constants.DATA, constants.LANDING_PAGE_DATA]);
      landingPageData = landingPageData.set(constants.LANDING_PAGE_SHOW_LEGAL_SECTION, action.enabled).set(constants.LANDING_PAGE_LEGAL_TEXT, action.legalSectionText);
      newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA], landingPageData);
      break;
    }

    case constants.UPDATE_LANDING_PAGE_SHOW_SOCIAL_SECTION_TOGGLE: {
      let landingPageData = state.getIn([constants.DATA, constants.LANDING_PAGE_DATA]);
      landingPageData = landingPageData.set(constants.LANDING_PAGE_SHOW_SOCIAL_SECTION, action.enabled);
      newState = state.setIn([constants.DATA, constants.LANDING_PAGE_DATA], landingPageData);
      break;
    }

    case constants.OPEN_LANDING_PAGE_MODAL: {
      newState = state.setIn([constants.LANDING_PAGE_MODAL_DATA, constants.LANDING_PAGE_SHORTENED_URL], action.url);
      newState = newState.setIn([constants.LANDING_PAGE_MODAL_DATA, constants.LANDING_PAGE_MODAL_IS_OPEN], action.openModal);
      break;
    }

    case constants.CLEAR_LANDING_PAGE_MODAL_DATA: {
      newState = state.delete(constants.LANDING_PAGE_MODAL_DATA);
      break;
    }

    case filterConstants.CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION: {
      const filterPopulation = action[constants.ONE_TIME_ACTION_FILTERED_POPULATION];
      const oneTimeSource = action[constants.FILTERED_POPULATION_SOURCE];
      const oneTimeDefault = defaults.oneTimeActionData;
      newState = state
        .set(constants.DATA, oneTimeDefault)
        .set(constants.ORIGINAL_DATA, oneTimeDefault);
      newState = newState
        .setIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION], filterPopulation)
        .setIn([constants.DATA, constants.FILTERED_POPULATION_SOURCE], oneTimeSource);

      const filterPopulationConditions = fromJS({})
        .set(constants.TYPE, action.filterTempGlobalCondition.get(constants.TYPE))
        .set(constants.MEMBERSHIP_STATUS_CONDITION, action.filterTempGlobalCondition.get(constants.MEMBERSHIP_STATUS_CONDITION))
        .set(constants.CONDITIONS, action.filterTempGlobalCondition.get(constants.CONDITIONS))
        .set(constants.USER_ACTIONS_CONDITIONS, action.filterTempGlobalCondition.get(constants.USER_ACTIONS_CONDITIONS));

      newState = newState.setIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS], filterPopulationConditions);
      return newState.set(constants.ADJUST_BY_MEMBER_PROPERTIES, true);
    }

    case constants.SET_EXPORT_IN_PROGRESS: {
      return state.setIn([constants.DATA, constants.EXPORT_IN_PROGRESS], fromJS(action[constants.EXPORT_IN_PROGRESS]));
    }
    case constants.OPEN_CONFIRM_DELETE_ACTION_DIALOG: {
      newState = state
        .set(constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN, fromJS(action[constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN]));
      return newState;
    }
    case constants.OPEN_BLOCK_SMS_ACTION_DIALOG: {
      newState = state
        .set(constants.BLOCK_SMS_ACTION_DIALOG_OPEN, fromJS(action[constants.BLOCK_SMS_ACTION_DIALOG_OPEN]));
      return newState;
    }
    case constants.OPEN_RE_FILTER_DIALOG: {
      newState = state
        .set(constants.RE_FILTER_DIALOG_OPEN, action[constants.RE_FILTER_DIALOG_OPEN]);
      return newState;
    }
    case constants.ADD_ACTIVITY_TAGS: {
      const trimmedActivityTags = action.activityTags.map((tag) => tag.replace(/[^\w:-=\s-]/g, '')).filter((str) => str.trim() !== '');
      newState = state
        .setIn([constants.DATA, constants.ACTIVITY_TAGS], fromJS(trimmedActivityTags));
      return newState;
    }
    case constants.UPDATE_FILTERED_POPULATION: {
      newState = state;
      const path = [constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION];
      newState = newState
        .setIn([...path, constants.FILTERED_POPULATION_COUNT], action.count)
        .setIn([...path, constants.FILTERED_POPULATION_FILE_NAME], action.fileName)
        .setIn([...path, constants.FILTERED_POPULATION_BUCKET_NAME], action.bucketName)
        .setIn([...path, constants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT], action.comilliaAdjusted)
        .setIn([...path, constants.FILTER_POPULATION_QUERY], action.query);
      return newState;
    }
    case membersConstants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_DONE: {
      return newState.set(constants.ADJUST_BY_MEMBER_PROPERTIES, false);
    }

    case constants.SET_COMILLIA_HINT_OPEN_IN_ACTION: {
      return state
        .setIn([constants.COMILLIA_HINT_OPEN_IN_ACTION, constants.CASE], action.caseIndex)
        .setIn([constants.COMILLIA_HINT_OPEN_IN_ACTION, constants.ACTION], action.actionIndex);
    }

    case constants.FILTER_MEMBERS: {
      return state.delete(constants.COMILLIA_HINT_OPEN_IN_ACTION);
    }

    case constants.GET_LOOKER_QUERY_SUCCESS: {
      return state
        .set(constants.LOOKER_QUERY_FETCHED, true)
        .setIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTER_POPULATION_QUERY], action.query);
    }

    default:
      break;
  }

  newState = activityActionsReducer(newState, action, rootState);
  newState = activityConditionsReducer(newState, action);
  newState = activityCasesReducer(newState, action);
  newState = activityDatetimeConditionsReducer(newState, action);
  newState = activityItemsPopulationReducer(newState, action);
  newState = activityGlobalConditionsReducer(newState, action, rootState);
  newState = activityOneTimeActionReducer(newState, action);
  newState = activityPunchCardReducer(newState, action);
  newState = activityPromoCodeReducer(newState, action);
  validationOptions.validate = true;

  return newState;
}


function activityValidationReducerImpl(state, validationOptions, rootState) {
  // Re-validate if needed
  let newState = state;
  const validationErrors = newState.get(constants.VALIDATION_ERRORS);
  const tempValidationErrors = newState.get(constants.TEMP_VALIDATION_ERRORS);
  if (validationOptions.force || validationErrors || tempValidationErrors) {
    const validationMode = newState.getIn([constants.VALIDATION_METADATA, constants.ACTIVITY_VALIDATION_MODE]);
    if (validationMode === constants.ACTIVITY_VALIDATION_MODE_PUBLISH || validationMode === constants.ACTIVITY_VALIDATION_MODE_DONT_VALIDATE) {
      newState = validationUtils.validateActivity(newState, rootState);
    } else {
      newState = validationUtils.validateDraft(newState);
    }
  }
  // Re-validate filters if needed. In this case re-validate only the filters model
  const tempFiltersValidationErrors = newState.get(constants.TEMP_FILTERS_ERRORS);
  if (tempFiltersValidationErrors) {
    const filtersErrors = validationUtils.validateTempFilters(newState, rootState);
    newState = newState.set(constants.TEMP_FILTERS_ERRORS, filtersErrors);
  }
  return newState;
}

function markAsDirtyIfNeeded(prevState, newState, action) {
  const ignoredActions = [
    constants.RESET_ACTIVITY_DATA,
    constants.SET_ACTIVITY_TYPE_DATA,
    constants.MARK_ACTIVITY_CLEAN,
    constants.CREATE_ACTIVITY,
    constants.GET_ACTIVITY_SUCCESS,
    constants.SELECT_ACTIVITY_TAB,
    constants.SET_FILTERED_POPULATION_DATA,
    constants.ADD_COMMUNICATION_RULE,
    constants.SET_ACTIVITY_SOURCE
  ];
  if (prevState.get(constants.ACTIVITY_DATA_LOADED)
    || !prevState.getIn([constants.DATA, constants.HUB_ID])) {
    if (newState !== prevState && !ignoredActions.includes(action.type)) {
      if (action.type === constants.UPDATE_METADATA
        && (action.key === constants.CAMPAIGN_KEY || action.key === constants.LOCATION_ID)) {
        return newState;
      }
      return newState.set(constants.IS_ACTIVITY_DIRTY, true);
    }
  }
  return newState;
}



export default activityReducer;
