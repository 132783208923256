import React from 'react';
import { Header } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SideNavBar from '../SideNavBar/SideNavBar';

import { getDataAndBiSideNavbarSettings } from './dataAndBi.utils';
import withAuthorization from '../../common/helpers/authorization';

import * as selectors from './dataAndBi.selectors';
import * as appSelectors from '../App/selectors';

import * as appConstants from '../App/app.constants';

import './dataAndBi.styles.less';

export class DataAndBiContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    intl: PropTypes.object,
    isFranchise: PropTypes.bool,
    features: PropTypes.array,
    className: PropTypes.string,
    headClassName: PropTypes.string,
    translationKey: PropTypes.string,
    noTitle: PropTypes.bool,
    isComoUser: PropTypes.bool,
    children: PropTypes.object,
  };

  render() {
    const { isFranchise, intl, features, className = '', headClassName = '', noTitle, translationKey = '', children, isComoUser } = this.props;
    const { formatMessage } = intl; // eslint-disable-line react/prop-types

    const dataReports = features.find((f) => f.get('name') === appConstants.FEATURE_NAME_DATA_REPORTS);
    const dataExport = features.find((f) => f.get('name') === appConstants.FEATURE_NAME_DATA_EXPORT);
    const viewData = features.find((f) => f.get('name') === 'View Data');
    const filter = features.find((f) => f.get('name') === appConstants.FEATURE_NAME_FILTER);
    const essentials = features.find((f) => f.get('name') === 'Essentials');
    const lookerForEssentials = features.find((f) => f.get('name') === 'Looker For Essentials');

    const dataReportsLevel = dataReports ? dataReports.get('level') : null;
    const dataExportLevel = dataExport ? dataExport.get('level') : null;
    const viewDataLevel = viewData ? viewData.get('level') : null;
    const filterLevel = filter ? filter.get('level') : null;
    const essentialsLevel = essentials ? essentials.get('level') : null;
    const lookerForEssentialsLevel = lookerForEssentials ? lookerForEssentials.get('level') : null;

    const hideDataExport = dataExportLevel === 'None';

    let sideNavBarItems = getDataAndBiSideNavbarSettings(formatMessage, location, isFranchise, hideDataExport, isComoUser);

    if (dataReportsLevel === 'None' || (essentialsLevel === 'Full' && lookerForEssentialsLevel !== 'Full')) {
      sideNavBarItems = sideNavBarItems.filter((f) => f.id !== 'databi.looker');
    }
    if (viewDataLevel === 'None') {
      sideNavBarItems = sideNavBarItems.filter((f) => f.id !== 'databi' && f.id !== 'databi.analytics');
    }
    if (filterLevel === 'None') {
      sideNavBarItems = sideNavBarItems.filter((f) => f.id !== 'databi.filterMembers');
    }
    if (essentialsLevel === 'Full') {
      sideNavBarItems = sideNavBarItems.filter((f) => f.id !== 'databi.lookerDashboards');
    }

    return (
      <div className={className}>
        <Helmet>
          {formatMessage({ id: translationKey })}
          <title>{formatMessage({ id: translationKey })}</title>
          <meta name="description" content={'Description of Filter Members'} />
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        <div id="filter-members-all">
          {!noTitle && (
            <Header className={headClassName}>
              {formatMessage({ id: translationKey })}
            </Header>
          )}
          {children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFranchise: selectors.getIsUserFranchise(state),
  features: appSelectors.getFeatures(state),
  isComoUser: appSelectors.getIsComoUser(state),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect, withAuthorization, injectIntl)(DataAndBiContainer);
